<template>
  <a-select :popupClassName="className" :class="className" :dropdownMatchSelectWidth="false" v-bind="props">
    <slot default></slot>
    <template #suffixIcon>
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-jiantou-xia-cu"></use>
      </svg>
    </template>
    <template #tagRender="{value, label, closable, onClose, option}" v-if="$slots.tagRender">
      <slot name="tagRender" :value="value" :label="label" :option="option" :onClose="onClose" :closable="closable"></slot>
    </template>
    <template #option="{ value, label }" v-if="$slots.option">
      <slot name="option" :value="value" :label="label"></slot>
    </template>
  </a-select>
</template>
<script setup scoped>
  import { computed } from 'vue';

  const defaultSize = 'middle'; // large small

  const props = defineProps(['size','getPopupContainer']);

  const className = computed(() => {
    let inputClass = 'artmisads-select'
    if (props.size) {
      inputClass += ` ${props.size}`;
    } else {
      inputClass += ` ${defaultSize}`;
    }
    return inputClass;
  })

</script>
<style lang="less">
  .ant-select-arrow{
    width: 14px;
    color: var(--dark-4) !important;
    svg {
      width: 16px;
      height: 16px;
      fill: var(--dark-4);
    }
  }
  .ant-select.artmisads-select {
    .ant-select-selector {
      border: 1px solid var(--border-1);
      box-shadow: var(--section-box-shadow-2);
    }
    &.ant-select-focused {
      .ant-select-selector {
        border-color: var(--border-1) !important;
        background-color: var(--color-white);
        box-shadow: var(--section-box-shadow-2) !important;
        .ant-select-selection-item {
          font-weight: 500;
          border-radius: 4px;
          color: var(--dark-2);
        }
      }
    }
    .ant-select-selection-item {
      font-weight: 500;
      border-radius: 4px;
      align-items: center;
      color: var(--dark-2);
      display: inline-flex;
    }
    &:hover {
      .ant-select-selector {
        border-color: var(--bg-gray-7) !important;
        background-color: var(--bg-gray-1);
        .ant-select-selection-item {
          font-weight: 500;
          border-radius: 4px;
          color: var(--dark-1);
        }
      }
    }
    &.ant-select-open {
      background-color: var(--color-white);
      color: var(--dark-1);
      .ant-select-selector {
        border: 1px solid var(--primary-border) !important;
        box-shadow: var(--section-box-shadow-4) !important;
        .ant-select-selection-item {
          font-weight: 500;
          border-radius: 4px;
          color: var(--dark-1);
        }
      }
    }
  }
  .with-sort-icon {
    .ant-select-selection-item:before {
      font-size: 20px;
      content: '\e73e';
      margin-right: 8px;
      font-family: 'iconfont';
    }
  }
  .artmisads-select.ant-select-multiple.ant-select-lg.large {
    .ant-select-selector {
      padding: 1px 8px;
    }
    .ant-select-selection-item { 
        padding-right: 0;
        height: 22px;
        line-height: 22px;
        font-size: 14px;
        font-weight: 500;
        background-color: var(--bg-gray-8);
        border: none;
      }
    .ant-select-selection-item-remove {
      user-select: none;
      width: 22px;
      height: 22px;
      // background-color: #50408010;
      border-radius: 0 4px 4px 0;
      font-size: 12px;
      font-weight: bold;
      &:hover {
        background-color: #50408010;
      }
    }
  }
  .ant-tag {
    border-radius: 4px;
    background-color: var(--bg-gray-8);
    border: none;
  }
  .ant-select-dropdown.artmisads-select {
    padding: 6px 8px 8px 8px;
    box-shadow: var(--section-box-shadow);
    .ant-select-item {
      margin-top: 2px;
      color: var(--dark-1);
      font-weight: 400;
      .ant-select-item-option-state {
        display: none;
      }
      &:hover {
        background: var(--bg-gray-6);
      }
      &:active {
        background: var(--bg-gray-4);
      }
      &.ant-select-item-option.ant-select-item-option-selected {
        background-color: var(--select-bg-1);
        position: relative;
        &::after {
          top: 8px;
          right: 8px;
          width: 16px;
          height: 16px;
          content: '';
          position: absolute;
          background: url(../assets/images/svg/select_gou.png);
          background-size: 100%;
        }
      }
    }
  }

</style>
