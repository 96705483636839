<template>
  <div class="creator" ref="sellerRef">
    <div class="creator-content" >
    <h3>Publisher/Creator Application</h3>
    <a-form>
      <a-form-item 
        v-bind="validateInfos.promotionCountryCodes"
      >
        <div class="input">
          <span>Target countries for promotion</span>
          <artmisads-select size="large" v-model:value="modelRef.promotionCountryCodes" mode="multiple" :options="countries">
            <template #option="{ value: val, label }">
              <span role="img" :aria-label="val" style="font-size: 15px;"><svg-icon :name="`guoqi-${val.toLowerCase()}`" /></span>
              &nbsp;&nbsp;{{ label }}
              </template>
              <template #tagRender="{ value: val, label, closable, onClose, option }">
              <a-tag :closable="closable" style="margin-right: 3px;padding-right: 0;display: flex;align-items: center;justify-content: center;" @close="onClose">
                <span role="img" :aria-label="val" style="font-size: 15px;"><svg-icon :name="`guoqi-${val.toLowerCase()}`" /></span>
                &nbsp;&nbsp;{{ label }}
                <template #closeIcon>
                    <span class="ant-select-selection-item-remove" unselectable="on" aria-hidden="true" style="user-select: none;"><span role="img" aria-label="close" class="anticon anticon-close"><svg focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" fill-rule="evenodd" viewBox="64 64 896 896"><path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path></svg><!----></span></span>
                </template>
              </a-tag>
            </template>
          </artmisads-select>
        </div>
      </a-form-item>
      <a-form-item
        v-bind="validateInfos.promotionProductCategoryIds"
      >
        <div class="input" style="margin-top: 24px;">
          <span>Mostly promoted product categories</span>
          <artmisads-select size="large" v-model:value="modelRef.promotionProductCategoryIds" mode="multiple" :options="categories"/>
        </div>
      </a-form-item>
      <a-form-item
        v-bind="validateInfos.promotionMethodIds"
      >
        <div class="input" style="margin-top: 24px;">
          <span>Primary promotion methods</span>
          <a-checkbox-group v-model:value="modelRef.promotionMethodIds" style="width: 100%">
              <a-row>
                  <a-col :span="12" v-for="(item, index) in methods" style="margin-bottom: 9px;">
                      <artemisads-checkbox :value="item.value">
                        <span class="label-checkobox">
                          {{ item.label }}
                        </span>
                      </artemisads-checkbox>
                  </a-col>
              </a-row>
          </a-checkbox-group>
        </div>
      </a-form-item>
      <a-form-item style="margin-top: 24px;">
          <artemisads-divid/>
      </a-form-item>
    </a-form> 
    <div style="display: flex; justify-content: space-between;margin-top: 24px;padding-bottom: 80px;">
          <artmisads-button style="border-radius: 20px;" @click="onJump(1)">
              <svg-icon name="jiantou-zuo-cu" style="vertical-align: 0.01em;"/>Back
          </artmisads-button>
          <artmisads-button type="primary" style="border-radius: 20px;" class="custom-btn" @click="onJump(3)">Next
              <svg-icon name="jiantou-you-cu" style="vertical-align: 0.01em;"/>
          </artmisads-button>
    </div>
  </div>
  </div>
</template>
<script setup>
  import { ref, onMounted, reactive, toRaw } from 'vue';
  import { useRoute } from 'vue-router';
  import { Form } from 'ant-design-vue';

  import profileApi from '~/server/register'

  import SvgIcon from '~/components/SvgIcon.vue';


  const sellerRef = ref();
  const useForm = Form.useForm;
  const props = defineProps(['goTo']);
  const route = useRoute();

  // list
  const categories = ref([]);
  const countries = ref([]);
  const methods = ref([]);

  const modelRef = reactive({
    promotionCountryCodes: [],
    promotionProductCategoryIds: [],
    promotionMethodIds: [],
  });

  const rulesRef = reactive({
    promotionMethodIds: [{
      required: true,
      type: 'array',
      message: 'Please select at least one method',
    }],
    promotionCountryCodes: [{
      required: true,
      type: 'array',
      message: 'Please select at least one country.',
    }],
    promotionProductCategoryIds: [{
      required: true,
      type: 'array',
      message: 'Please select at least one category.',
    }],
  });

  const { validate, validateInfos } = useForm(modelRef, rulesRef);

  const onJump = (step) => {
      if (step == 3) {
        validate().then(res => {
          if (!res.errorFields) {
            localStorage.setItem('profile-step-3', JSON.stringify(toRaw(modelRef)));
            props.goTo(step)
          }
        })
      } else {
        localStorage.setItem('profile-step-3', JSON.stringify(toRaw(modelRef)));
        props.goTo(step)
      }
  }

  async function init() {
      const p1 = await profileApi.getPromotionProductCategories().then(res => {
          categories.value = res.data.map(item => {
              return {
                  label: item.name,
                  value: item.id
              }
          })
      });
      const p2 = await profileApi.getPromotionCountries().then(res => {
          countries.value = res.data.map(item => {
              return {
                  label: item.name,
                  value: item.code
              }
          })
      });
      const p3 = await profileApi.getPromotionMethods().then(res => {
          methods.value = res.data.map(item => {
              return {
                  label: item.name,
                  value: item.id
              }
          })
      });
      Promise.all([p1, p2, p3])
  }

  onMounted(() => {
    const step3 = localStorage.getItem('profile-step-3') && JSON.parse(localStorage.getItem('profile-step-3'));
    console.log(step3);
    Object.assign(modelRef, step3);
  })

  init();

</script>
<style lang="less" scoped>
    .creator{
      width: 100%;
      height: calc(100vh - 184px);
      padding-bottom: 50px;
      overflow-y: scroll;
      scrollbar-width: none;
      display: flex;
      justify-content: center;
      .creator-content {
        width: 500px;
        display: flex;
        flex-direction: column;
      }
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari 和 Edge */
    }
    // .label-checkobox {
    //   opacity: 0.7;
    //   font-family: Sora, sans-serif;
    //   font-weight: 400;
    //   font-size: 14px;
    //   color: #0F0629;
    //   letter-spacing: 0.4px;
    //   line-height: 19px;
    //   margin-left: 2px;
    // }
    > h3 {
      height: 36px;
      font-family: Sora, sans-serif;
      font-weight: 500;
      font-size: 28px;
      color: #0F0629;
      letter-spacing: 0.5px;
      line-height: 36px;
    }
    .input {
      display: flex;
      flex-direction: column;
      margin-top: 24px;
      padding: 1px;
      // width: 480px;
      > span {
        height: 16px;
        opacity: 0.7;
        font-family: Sora, sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #0F0629;
        letter-spacing: 0.4px;
        line-height: 16px;
        margin-bottom: 8px;
      }
      > input {
        height: 40px;
      }
    }
    .link-card {
      margin-top: 32px;
      border: 1px solid #5436E9;
      border-radius: 10px;
      padding: 20px;
      .input {
          width: 100%;
      }
    }
    :deep(.ant-form) {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
    :deep(.ant-select-item-option-content) {
      display: flex;
      > img {
        width: 22px;
        height: 16px;
        align-self: center;
        margin-right: 12px;
      }
    }
  }
  
</style>