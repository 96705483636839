<template>
  <div class="filterBarWrap" ref="marketplaceRef">
    <div class="filterBar" ref="marketplaceRef">
      <div class="filterBar-content">
        <div class="item">
          <span>Marketplace</span>
          <artmisads-select
            style="width: 232px"
            v-model:value="props.params.marketplace"
            :getPopupContainer="() => marketplaceRef"
            :options="COUNTRY_BASE_OPTIONS"
            @change="handleChangeMarketplace"
          />
        </div>
        <div class="item">
          <span>Publisher</span>
          <artmisads-select
            show-search
            v-model:value="props.params.publisherId"
            style="width: 232px"
            :options="publisherOptions"
            @search="searchPublisher"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            placeholder="All"
            allowClear
          />
        </div>
        <div class="item">
          <span>AA Username</span>
          <artmisads-select
            show-search
            v-model:value="props.params.sellerId"
            style="width: 232px"
            :options="sellerOptions"
            @search="searchSeller"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            @change="handleChangeSeller"
            placeholder="All"
            allowClear
          />
        </div>
        <div class="item">
          <span>Brand</span>
          <artmisads-select
            :show-search="!props.params.sellerId"
            v-model:value="props.params.brandId"
            style="width: 232px"
            :options="brandOptions"
            @search="searchBrand"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            placeholder="All"
            allowClear
          />
        </div>
      </div>
      <div class="filterBar-content">
        <div class="item">
          <span>Status</span>
          <artmisads-select
            style="width: 232px"
            v-model:value="props.params.status"
            :getPopupContainer="() => marketplaceRef"
            mode="multiple"
            :options="COMMISSION_STATUS"
            placeholder="All"
          />
        </div>
        <div class="item">
          <span>Product Status</span>
          <artmisads-select
            v-model:value="props.params.productStatus"
            :getPopupContainer="() => marketplaceRef"
            :options="PRODUCT_STATUS"
            placeholder="All"
            style="width: 232px"
          />
        </div>
        <div class="item">
          <span>ASIN</span>
          <artmisads-select
            show-search
            v-model:value="props.params.asin"
            style="width: 232px"
            :options="asinOptions"
            @search="searchAsin"
            :show-arrow="false"
            :filter-option="false"
            :not-found-content="null"
            placeholder="All"
            allowClear
          />
        </div>
        <div class="item">
          <span>Sales Time</span>
          <artemisads-range-picker
            v-model:value="dateParams"
            allowClear
            @openChange="openChange"
            :presets="RANGE_PRESETS_ADMIN_COMMISSIONS"
            style="width: 232px"
            :disabled-date="disabledDate"
          />
        </div>
      </div>
      <div class="btn">
        <artmisads-button @click="onSubmitSearch" :isSquare="true" type="primary" size="middle">Search</artmisads-button>
        <artmisads-button @click="onReset" type="text" :isSquare="true" size="middle">Reset</artmisads-button>
        <a-dropdown :getPopupContainer="() => marketplaceRef">
          <template #overlay>
            <artmisads-dropdown-menu @click="handleExportClick">
              <a-menu-item key="all">Export All Data</a-menu-item>
              <a-menu-item key="filter">Export with Filters</a-menu-item>
            </artmisads-dropdown-menu>
          </template>
          <artmisads-button-special>
            <svg class="icon daochu" aria-hidden="true">
              <use xlink:href="#icon-daochu"></use>
            </svg>
            <span class="btn-text">Export</span>
            <svg class="icon artmisads-select-down-icon" aria-hidden="true">
              <use xlink:href="#icon-jiantou-xia-cu"></use>
            </svg>
          </artmisads-button-special>
        </a-dropdown>
      </div>
    </div>
  </div>
</template>
<script setup>
  import moment from 'moment';
  
  import { ref, watch } from 'vue';

  import { COUNTRY_BASE } from '~/contants/common';
  import { COMMISSION_STATUS, PRODUCT_STATUS } from '~/contants/operator';
  import { publisherAdminApi } from '~/server/index'

  
  import { RANGE_PRESETS_ADMIN_COMMISSIONS } from '~/contants/reports';
  import Icon from '~/components/Icon.vue';
  import { message } from 'ant-design-vue';

  const COUNTRY_BASE_OPTIONS = COUNTRY_BASE.map(item => {
    item.value = item.host;
    item.label = item.host;
    return item;
  })

  const dateParams = ref([
    moment().subtract(180, 'day'),
    moment()
  ])
  const publisherOptions = ref([]);
  const asinOptions = ref([]);
  const sellerOptions = ref([]);
  const brandOptions = ref([]);
  const hasChange = ref(false);
  const marketplaceRef = ref(null);
  const props = defineProps(['params', 'setParams', 'getList', 'getCommissionReportsAgg']);

  watch(dateParams, () => {
    hasChange.value = true;
  })

  const onReset = () => {
    props.setParams();
    dateParams.value = [
    moment().subtract(180, 'day'),
    moment()
    ]
  }

  const disabledDate = current => {
    if (!dateParams.value || dateParams.value.length === 0) {
      return false;
    }
    const tooLate = dateParams.value[0] && current.diff(dateParams.value[0], 'days') > 365;
    const tooEarly = dateParams.value[1] && dateParams.value[1].diff(current, 'days') > 365;
    return tooEarly || tooLate;
  };

  const onSubmitSearch = () => {
    if (!dateParams.value) {
      return message.error('请选择日期范围');
    }
    props.getList();
    props.getCommissionReportsAgg();
  }

  const searchPublisher = async keyword => {
    const params = {
      keyword,
      marketplace: props.params.marketplace
    };
    let data = await publisherAdminApi.searchPublisher(params);
    if (data && data.length) {
      data = data.map(item => {
        item.value = item.id;
        item.label = item.creatorName;
        return item;
      })
      publisherOptions.value = data;
    }
  }

  const searchSeller = async keyword => {
    const params = {
      keyword,
      marketplace: props.params.marketplace
    };
    let data = await publisherAdminApi.searchSeller(params);
    if (data && data.length) {
      data = data.map(item => {
        item.value = item.id;
        item.label = item.sellerName;
        item.sellerBrands = item.sellerBrands.map(i => {
          i.value = i.id;
          i.label = i.amazonBrandName;
          return i;
        })
        return item;
      })
      sellerOptions.value = data;
    }
  }

  const searchAsin = async asin => {
    const params = { asin };
    let data = await publisherAdminApi.searchAsin(params);
    if (data && data.length) {
      data = data.map(item => {
        let newItem = {
          value: item,
          label: item,
        };
        return newItem;
      })
      asinOptions.value = data;
    }
  }

  const searchBrand = async keyword => {
    const params = {
      keyword,
      marketplace: props.params.marketplace
    };
    let data = await publisherAdminApi.searchBrand(params);
    if (data && data.length) {
      data = data.map(item => {
        item.value = item.id;
        item.label = item.amazonBrandName;
        return item;
      })
      brandOptions.value = data;
    }
  }

  const openChange = status => {
    if (!status && hasChange.value && dateParams.value) {
      const dateTime = {
        startReportDatePstTimestamp: dateParams.value[0].valueOf(),
        endReportDatePstTimestamp: dateParams.value[1].valueOf(),
      };
      const newParams = { ...props.params, ...dateTime };
      props.setParams(newParams);
    }
  }

  const handleChangeMarketplace = () => {
    const timer = setTimeout(() => {
      props.getList()
      props.getCommissionReportsAgg();
      clearTimeout(timer);
    }, 300)
  }

  const handleChangeSeller = (id, record) => {
    const { sellerBrands = [] } = record;
    if (sellerBrands.length) {
      brandOptions.value = sellerBrands;
      const newParams = { ...props.params, brandId: '' };
      props.setParams(newParams);
    }
  }

  const fetchPublisher = () => {}

  const handleChangePublisher = () => {}

  const handleExportClick = async ({ key }) => {
    let exportParams = {
      endReportDatePstTimestamp: moment().valueOf(),
      marketplace: 'amazon.com',
      startReportDatePstTimestamp:   moment().subtract(180, 'day').valueOf(),
    }, newExportParams = {};
    if (key === 'filter') {
      exportParams = {...props.params };
    }
    for(let item in exportParams) {
      if (exportParams[item]) {
        newExportParams[item] = exportParams[item];
      }
    }
    const finalParams = {
      qs: newExportParams,
      fileName: `Commissions-${moment().format('YYYY-MM-DD')}.csv`,
      platform:'operator'
    }
    publisherAdminApi.commissionReportsExport(finalParams);
  }
</script>
<style lang="less" scoped>
  .filterBarWrap {
    padding: 16px;
  }
  .filterBar {
    padding: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #F8F7FC;
    border-radius: 8px;
    .filterBar-content {
      width: 100%;
      display: flex;
      margin-bottom: 15px;
    }
  }
  .btn {
    display: flex;
    justify-content: right;
    button {
      margin-left: 15px;
    }
    > button {
      cursor: pointer;
      display: flex;
      margin-left: 16px;
      .shangchuan2 {
        width: 20px;
        height: 20px;
        fill: var(--dark-1);
        margin-right: 8px;
      }
      .jiantou {
        width: 16px;
        height: 16px;
        fill: var(--dark-3);
        margin-left: 8px;
      }
      .daochu {
        width: 20px;
        height: 20px;
        margin-right: 8px;
        fill: var(--dark-1);
      }
      > svg {
        align-self: center;
      }
      > span {
        align-self: center;
      }
      .btn-text {
        align-self: center;
        font-weight: 500;
        line-height: 20px;
        margin-right: 8px;
      }
    }
  }
  .item {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    > span {
      color: var(--dark-2);
      font-weight: 500;
      font-size: 13px;
      letter-spacing: 0.3px;
      line-height: 18px;
      margin-bottom: 4px;
    }
  }
  .marketplace {
    display: flex;
    align-content: center;
    &:deep{
      .ant-select-selection-item,.ant-select-item-option-content{
        display: flex;
        align-items: center;
        .iconfont{
          margin-right: 4px;
          font-size: 18px;
        }
      }
    }
  }
  
</style>
