<template>
  <div class="operation-bar" ref="operationRef">
    <artmisads-modal
      v-model:open="showDeactivateModal"
      :title="`Are you sure you want to Deactivate these Products?`"
      :width="500"
      :onCancel="onCancel"
      :destroyOnClose="true"
      :maskClosable="false"
    >
      <div class="inactive-content">
        <p v-if="productListStatus?.activeProductVariationCount">Deactivating Products will archive the active Publisher/Creator
          links of the product and their related variations, and also make them
          unavailable to Publishers and Creators.</p>
        <p v-else>Deactivating Products will archive all active Publisher/Creator links. and make this product unavailable to publishers and creators.</p>
      </div>
      <template #footer>
        <artmisads-button @click="() => setShowDeactivateModal(false)">Cancel</artmisads-button>
        <artmisads-button @click="confirmInactive" type="primary">Confirm</artmisads-button>
      </template>
    </artmisads-modal>
    <artmisads-modal v-model:open="showActiveModal" :width="500" title="Are you sure you want to Activate these Products?"
      okText="Confirm" @ok="confirmActive" @cancel="setShowActiveModal(false)">
      <div class="active-product">
        <div class="title-ext">
          Activate the products, and both the products themselves and their related variations will be activated.
        </div>
      </div>
    </artmisads-modal>
    <div class="actions">
      <a-dropdown :getPopupContainer="() => $refs.operationRef" trigger="click" @openChange="getProductVariations">
        <template #overlay>

          <artmisads-dropdown-menu @click="handleMenuClick" >
            <a-spin :spinning="loading">
            <a-menu-item key="activateProducts" :disabled="(!selectedRowKeys.length || productListStatus?.inactiveProductCount) ? false : true">
              <div class="actions-item">
                <span>Activate Products</span>
                <span v-if="productListStatus?.inactiveProductCount && selectedRowKeys.length" class="action-num">
                  <span class="num">{{productListStatus?.inactiveProductCount || ''}}</span>
                  <Icon name="jiahao-cu" class="minus" v-show="productListStatus?.inactiveProductVariationCount"></Icon>
                  <span class="variation-num" v-show="productListStatus?.inactiveProductVariationCount">{{ productListStatus?.inactiveProductVariationCount }} </span>
                  <a-tooltip placement="bottom" :getPopupContainer="() => $refs.operationRef" v-if="productListStatus?.inactiveProductVariationCount">
                    <template #title>
                      <div class="pop-title">The total number of variations included in the selected products.</div>
                    </template>
                    <Icon name="wenhao-tianchong" class="question-icon"></Icon>
                  </a-tooltip>
                </span>
                </div>
            </a-menu-item>
            <a-menu-item key="setPublicCommission">
              <div class="actions-item">
                <span>Set Public Commission</span>
                <span class="action-num">
                <span v-if="selectedRowKeys.length" class="num">{{selectedRowKeys.length}}</span>
                </span>
              </div>
            </a-menu-item>
            <a-menu-item key="deactivateProducts" :disabled="(!selectedRowKeys.length || productListStatus?.activeProductCount) ? false : true">
              <div class="actions-item">
                <span>Deactivate Products</span>
                <span v-if="productListStatus?.activeProductCount && selectedRowKeys.length" class="action-num">
                  <span class="num">{{productListStatus?.activeProductCount || ''}}</span>
                  <Icon name="jiahao-cu" class="minus" v-show="productListStatus?.activeProductVariationCount"></Icon>
                  <span class="variation-num" v-show="productListStatus?.activeProductVariationCount">{{ productListStatus?.activeProductVariationCount }} </span>
                  <a-tooltip placement="bottom" :getPopupContainer="() => $refs.operationRef" v-if="productListStatus?.activeProductVariationCount">
                    <template #title>
                      <div class="pop-title">The total number of variations included in the selected products.</div>
                    </template>
                    <Icon name="wenhao-tianchong" class="question-icon"></Icon>
                  </a-tooltip>
                </span>
              </div>
            </a-menu-item>
            <a-menu-item key="clearSelection">Clear Selection</a-menu-item>
            </a-spin>
          </artmisads-dropdown-menu>
          
        </template>
        <artmisads-select-button showDownIcon="true">Actions</artmisads-select-button>
      </a-dropdown>
      <div class="button-groups">
        <buttonGroups
          :pagination="{
            total: props.listTotal,
            current: props.params.page,
            size: props.params.size,
          }"
          :tableData="productList"
          @pageChange="onPageChange"
        />
      </div>
    </div>
    <div class="content-right">
      <div class="search">
        <a-input-search
          v-model:value="params.title"
          placeholder="search..."
          @search="getProductList"
          allowClear="true"
        />
      </div>
      <searchFilter
        :params="params"
        :pagination="{
          total: props.listTotal,
          current: props.params.page,
          pageSize: props.params.size,
        }"
        :setParams="setParams"
        :getProductList="getProductList"
        :setSelectedRowKeys="setSelectedRowKeys"
        :brandswithAvailableProducts="brandswithAvailableProducts"
        :amazonNameList="amazonNameList"
      />
      <div class="sellers">
        <artmisads-select
          v-model:value="params.sortType"
          style="width: 180px"
          class="with-sort-icon"
          :getPopupContainer="() => $refs.operationRef"
        >
          <a-select-option :value="1">Best Sellers</a-select-option>
          <a-select-option :value="2">Newly Added</a-select-option>
          <a-select-option :value="3">Commission</a-select-option>
          <a-select-option :value="4">Price</a-select-option>
        </artmisads-select>
      </div>
    </div>
  </div>
</template>
<script setup>
  import ButtonGroups from '../Brands/components/buttonGroups.vue';
  import { computed, reactive, ref } from 'vue';
  import searchFilter from './searchFilter.vue';
  import { message } from 'ant-design-vue';
  import Icon from "@/components/Icon.vue";
  import { productsApi } from '@/server/index';

  const loading=ref(false);
  const showDeactivateModal = ref(false);
  const showActiveModal = ref(false);
  const operationRef = ref(null);
  const productListStatus=reactive({});
  const props = defineProps([
    'setParams', 'params', 'setShowSetCommission', 'listTotal', 'brandswithAvailableProducts','amazonNameList',
    'selectedRowKeys', 'onChangeActiveItems', 'setSelectedRowKeys', 'productList', 'getProductList'
  ]);
  const confirmInactive = async () => {
    showDeactivateModal.value = false;
    props.onChangeActiveItems('inActive', props.selectedRowKeys);
  }
  const confirmActive = async () => {
    showActiveModal.value = false;
    props.onChangeActiveItems('active', props.selectedRowKeys);
  }

  const setShowDeactivateModal = status => {
    showDeactivateModal.value = status;
  }
  const setShowActiveModal = status => {
    showActiveModal.value = status;
  }

  // const productListStatus = computed(() => {
  //   let activeItems = [], inActiveItems = [];
  //   props.selectedRowKeys.forEach(element => {
  //     const item = props.productList.find(i => i.id === element);
  //     if (item) {
  //         if (item.status === 'active') {
  //         activeItems = [...activeItems, item];
  //       } else {
  //         inActiveItems = [...inActiveItems, item];
  //       }
  //     }
      
  //   });
  //   return {
  //     activeItems,
  //     inActiveItems
  //   }
  // })

  const onPageChange = (current) => {
    let newParams = { ...props.params };
    newParams.page =  current;
    props.setParams(newParams);
    props.getProductList();
  }

  const handleMenuClick = async ({ key }) => {
    if (!props.selectedRowKeys.length && key !== 'clearSelection') {
      return message.warning('Please select the product items.');
    };
    if (key === 'activateProducts') {
      if(productListStatus?.inactiveProductVariationCount){
        setShowActiveModal(true);
      }else{
        props.onChangeActiveItems('active', props.selectedRowKeys);
      }
      
      
    } else if (key === 'deactivateProducts') {
      setShowDeactivateModal(true);
    } else if (key === 'clearSelection') {
      props.setSelectedRowKeys([]);
    } else if (key === 'setPublicCommission') {
      props.setShowSetCommission(true);
    }
  }
  const getProductVariations = async (status) => {
    if(!status || !props.selectedRowKeys.length){
      return
    }
    loading.value = true;
    let res = await productsApi.getProductVariations(props.selectedRowKeys);
    productListStatus.activeProductCount=res.data.activeProductCount;
    productListStatus.activeProductVariationCount=res.data.activeProductVariationCount;
    productListStatus.inactiveProductCount=res.data.inactiveProductCount;
    productListStatus.inactiveProductVariationCount=res.data.inactiveProductVariationCount;
    loading.value = false;
  }

</script>
<style lang="less" scoped>
  .operation-bar:deep{
    // position: relative;
    padding-bottom: 15px;
    display: flex;
    justify-content: space-between;
    .actions {
      display: flex;
      > button {
        margin-right: 8px;
      }
    }
    .content-right {
      display: flex;
      justify-content: right;
      height: 32px;
      flex: 1;
      :deep(.ant-input-search) input {
        &::placeholder {
          text-transform: capitalize;
        }
      }
      > div {
        margin-left: 8px;
      }
    }
    .action-num{
      display: flex;
      align-items: center;
    }
    .minus{
      font-size: 10px;
      align-self: center;
      margin-inline: 4px;
      // font-weight: 600;
      line-height: 18px;
      height: 18px;
      color: var(--dark-1);
    }
    .variation-num{
      align-self: center;
      margin-right: 2px;
      height: 18px;
      min-width: 20px;
      padding-inline: 4px;
      background: rgba(255, 211, 88, 0.2);
      border-radius: 4px;
      font-weight: 500;
      font-size: var(--font-size-mormal);
      color: var(--dark-1);
      text-align: center;
      line-height: 16px;
      border: 1px solid rgba(255, 211, 88, 0.4);
    }
    .ant-tooltip-inner {
      width: 290px;
    }
    .question-icon{
      color: var(--dark-4);
    }
    .pop-title{
      font-size: 14px;
      line-height: 18px;
    }
  }
</style>
