<template>
  <div>
    <a-upload 
    v-model:file-list="filesList"
    :list-type="configs?.listType"
    method="put"
    :show-upload-list="false"
    :customRequest="uploadImg"
    :before-upload="beforeUpload"
    @change="handleChange"
    :max-count="configs?.maxCount"
    >
    <a-spin :spinning="spinning" v-if="configs?.spin">
      <slot name="content"></slot>
    </a-spin>
    <div v-else>
      <slot name="content"></slot>
    </div>
     
    </a-upload>
  </div>
</template>

<script setup >
import {ref,reactive} from 'vue';
import adminApi from '../server/seller/admin';
import { message } from 'ant-design-vue';
const {configs}=defineProps({
  configs:{
    type:Object,
    defalt:()=>{}
  }
})
const emit =defineEmits(['upload-file']);
const filesList = ref();
const uploadImageUrl=ref();
const spinning = ref(false);
function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}
const handleChange = (info) => {
  if (info.file.status === 'uploading') {
    spinning.value=true;
    return
  }
  if (info.file.status === 'done') {
    getBase64(info.file.originFileObj, (base64Url) => {
      uploadImageUrl.value=base64Url;
    })
  }
  if (info.file.status === 'error') {
    spinning.value=false;
    message.error('upload error')
    return
  }
}

const uploadImg = (e) => {
  const data = {};
  if (e.file) {
    data.file = e.file
  } else {
    data.url = encodeURIComponent(uploadImageUrl.value)
  }
  adminApi.uploadImg(data).then((res) => {
    spinning.value=false;
    emit('upload-file',res)
  }).catch(e => {
    console.log(e)
  })
}
const beforeUpload =async (file)=>{  
  const result = await readFileAsync(file);
  const image = await loadImageAsync(result);
  if(configs) {
    if(configs.maxSize && (configs.maxSize * 1024 * 1024) < file.size){
      message.error(`The image size cannot exceed${configs.maxSize}M, please upload again!`,2);
      return false;
    }
    if((configs.minWidth && image.width < configs.minWidth)
        && (configs.minHeight && image.height <  configs.minHeight)) {
      message.error(`The image size does not meet the requirements. The minimum size is${configs.minWidth}*${configs.minHeight}, please upload again!`, 2);
      return false;
    } 

    return true
  } else {
    return true;
  }
  
  
}

const readFileAsync = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => resolve(e.target.result);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
};

const loadImageAsync = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = (error) => reject(error);
    img.src = src;
    
  });
};
</script>
<style lang="less" scoped>
</style>