<template>
    <div>
        <div v-if="editable" class="edit-area">
            <a-input class="a-input" :ref="editableInput" v-model:value="inputValue" v-focus :allow-clear="allowClear"
                @keyup.enter="onSave" />
            <div class="edit-opt">
                <a-button class="btn" type="primary" @click.prevent="onSave">Save</a-button>
                <a-button class="btn cancel" @click.prevent="() => editable = false">Cancel</a-button>
            </div>
        </div>
        <slot v-else :onClick="onShow">
            <a href="javascript:;" @click.prevent="() => editable = true">
                <span class="default">
                    <PlusOutlined />
                </span>
            </a>
        </slot>
    </div>
</template>

<script setup>
import { ref, watch, watchEffect } from 'vue';
import { PlusOutlined } from '@ant-design/icons-vue';

const { allowClear = false } = defineProps({
    allowClear: Boolean
})

//此双向绑定外层设置为true时，插件恢复初始状态
const close = defineModel('close')

watch(close, (close) => {
    if (close) {
        editable.value = false;
        inputValue.value = '';
    }
})

const emit = defineEmits(['callback'])

const inputValue = ref('');
const editableInput = ref();

const onShow = () => {
    inputValue.value = '';
    editable.value = true;
}

const onSave = () => {
    if (inputValue.value.trim()) {
        emit('callback', inputValue.value)
        editable.value = false;
    }
}

const editable = ref(false)

const vFocus = {
    mounted: el => {
        el.getElementsByTagName('input')[0].focus()
    }
}
</script>

<style scoped lang="less">
.a-input {
    box-shadow: var(--section-box-shadow-2);
    :deep(&.ant-input-affix-wrapper-focused) {
        outline: 1px solid rgba(131, 92, 242, 0.8) !important;
        border-color: rgba(131, 92, 242, 0.8) !important;
    }
}

.edit-area {
    position: relative;
    width: 260px;
    margin: 0 auto;

    .edit-opt {
        // position: absolute;
        // margin: 4px 8px;
        margin: 8px 0;
        display: flex;
        gap: 4px;

        .btn {
            width: 72px;
            border-radius: 12px;
            box-shadow: none;
            height: 24px;
            line-height: 24px;
            padding: 0;
            border: none;
            font-weight: 500;
            font-size: 13px;
            // box-shadow: 0 0 2px 0 #0000000f, 0 8px 24px 0 #0000001a;

            :deep(&.ant-btn-primary) {
                border: none
            }

            &.cancel {
                background-color: var(--bg-gray-4);
                &:hover {
                    color: inherit;
                    background-color: var(--bg-gray-5);
                }
            }
        }
    }

}

.default {
    cursor: pointer;
    display: block;
    width: 200px;
    background-color: var(--bg-gray-1);
    height: 32px;
    line-height: 32px;
    text-align: center;
    border: 1px dashed var(--border-1);

    svg {
        font-size: 16px;
    }
}
</style>