import client from '../client';
import { uploadImg } from '../../utils'

export default {
  agreeToNotice: data => {
    return client.post('seller/agree-to-notice', data).then(res => res.data);
  },
  getSellerBrands: data => {
    return client.get('seller/brands/all', data).then(res => res.data);
  },
  activateBrands: data => {
    return client.post('seller/brands/activate', data).then(res => res.data);
  },
  uploadImg: async data => uploadImg(data),
  getCommissionConfig:data=>{//获取平台佣金配置比例
    return client.get('commission/config', data).then(res => res.data);
  },
  switchCountry:data=>{
    return client.post('seller/switch-country', data).then(res => res.data);
  },
  reauthorize:data=>{
    return client.post('seller/reauthorize', data).then(res => res.data);
  },
}