<!--
 * @Author: Hankill(hao.chen)
 * @Date: 2024-12-26 10:42:36
 * @LastEditors: Hankill(hao.chen)
 * @LastEditTime: 2024-12-26 14:56:03
 * @FilePath: /web_artemisads/src/artemisadsComponents/artemisadsSegmented.vue
 * @Description: 
-->
<template>
    <a-segmented v-bind="props" class="artemisads-segmented" />
</template>


<style lang="less" scoped>
.ant-segmented.artemisads-segmented{
    font-weight: 500;
    background: #EAE8F0;
        :deep(.ant-segmented-item-label) {
            padding: 0 8px;
            font-size: 13px;
            font-weight: 500;
            border-radius: 6px;
            color: var(--dark-2);
            &:hover {
                background: #5040801a;
            }
        }
        // :deep(.ant-segmented-item) {
        //     margin: 0 2px;
        //     &:first-child {
        //         margin-left: 1px;
        //     }
        //     &:last-child {
        //         margin-right: 1px;
        //     }
        // }
        :deep(.ant-segmented-item-selected) {
            box-shadow: 0 1px 0 0 #0f06290d;
            color: var(--dark-1);
            .ant-segmented-item-label {
                color: var(--dark-1);
                background: #fff;
                &:hover {
                    background: #fff;
                }
            }
        }
}
</style>