<template>
  <div class="commission-history-modal" ref="commissionHistiryRef">
    <artmisads-modal
      v-model:open="props.showCommissionHistory"
      title="Commission History"
      :width="960"
      :onCancel="() => props.setShowCommissionHistory(false)"
      :getContainer="() => commissionHistiryRef"
      :destroyOnClose="true"
      :maskClosable="false"
      :footer="null"
    >
      <div class="commission-history">
        <div class="chart" v-if="commissionHistoryList.length">
          <commissionHistoryChart :commissionHistoryList="commissionHistoryList"/>
        </div>
        <div class="export-btn">
          <artmisads-button :isSquare="true" size="middle" v-if="commissionHistoryList.length" type="primary" @click="onExport">Export</artmisads-button>
          <artmisads-button :isSquare="true" size="middle" v-else disabled @click="onExport">Export</artmisads-button>
        </div>
        <div class="table">
          <artmisads-table
            :columns="columnsHistory"
            row-key="id"
            :pagination="false"
            rowClassName="table-item"
            :data-source="commissionHistoryList"
          />
        </div>
        <p v-if="commissionHistoryList.length">
          {{COUNTRY_COMMISSION_TXT[store.userInfo.countryCode]}}
        </p>
      </div>
    </artmisads-modal>
  </div>
</template>

<script setup>
  import moment from 'moment';
  import { message } from 'ant-design-vue';
  import { ref, onBeforeMount, watch } from 'vue';
  import { productsApi } from '../../../../server/index'
  import commissionHistoryChart from './commissionHistoryChart';
  import { useUserInfoStore } from '~/storeData/index';
  import { COUNTRY_COMMISSION_TXT } from '~/contants/common';

  const props = defineProps(['showCommissionHistory', 'setShowCommissionHistory', 'productId']);
  const commissionHistiryRef = ref(null);

  const commissionHistoryList = ref([]);
  const store = useUserInfoStore();

  const getCommissionHistory = async () => {
    const res = await productsApi.getCommissionHistory(props.productId);
    const { success, data, error } = res;
    if (success) {
      commissionHistoryList.value = data;
    }
  }
  
  const columnsHistory = [
    {
      title: 'Date',
      dataIndex: 'startDate',
      customRender: ({ text, record }) => moment(text).format('YYYY/MM/DD')
    },
    {
      title: 'Commission',
      dataIndex: 'commission',
      customRender: ({ text, record }) => `${Number(text).toFixed(1)}%`
    },
    {
      title: 'Difference',
      dataIndex: 'difference',
    },
  ];

  const onExport =  () => {
    
    const exportParams = {
      id: props.productId
    }
    const finalParams = {
      qs: exportParams,
      fileName: 'commissionHistory.csv'
    }
    productsApi.exportCommissionHistory(finalParams);
  }

  watch(() => props.showCommissionHistory, newValue => {
    if (newValue) {
      getCommissionHistory();
    }
  })

</script>

<style lang="less" scoped>
  .commission-history-modal {
    .commission-history {
      .export-btn {
        display: flex;
        justify-content: right;
      }
      .table {
        margin-top: 15px;
      }
      > p {
        margin-top: 5px;
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 22px;
        color: var(--dark-3);
      }
    }
  }
</style>