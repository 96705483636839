<template>
    <div class="creator" ref="sellerRef">
      <div class="creator-content">
      <h3>Publisher/Creator Application</h3>
      <p v-if="!reviewStatus" class="subtitle">Thank you for applying to ArtemisAds! This quick application process for publishers, aﬃliates, inﬂuencers, and content creators takes just a few minutes. After submitting your application, you can explore the platform while our team review and approve your account.</p>
      <a-alert v-if="reviewStatus" message="" description="Thank you for applying to ArtemisAds! Unfortunately, your application was not approved this time. But you can modify the application details and resubmit. Make sure to check the validity of the Links you provided to boost approval odds." type="warning" show-icon>
      </a-alert>
      <a-form>
        <a-form-item 
          v-bind="validateInfos.creatorName"
        >
          <div class="input">
            <span>Publisher/Creator Name</span>
            <artmisads-input @blur="() => validate('creatorName')" v-model:value="modelRef.creatorName" />
          </div>
        </a-form-item>
        <a-form-item
          v-bind="validateInfos.creatorEmail"
        >
          <div class="input">
            <span>Publisher/Creator Email</span>
            <artmisads-input @blur="() => validate('creatorEmail')" type="email" v-model:value="modelRef.creatorEmail"/>
          </div>
        </a-form-item>
        <!-- <a-form-item style="margin-top: 40px;">
          <artemisads-divid dashed="dashed"/>
        </a-form-item> -->
        <a-form-item
               v-bind="validateInfos.links"
            >
        <div class="link-card">
            <h3 class="title">Your Links</h3>
            <p class="subtitle">You must provide at least one link, but not for every ﬁeld. Brands use these URLs to assess your audience and content. Optimize your proﬁle for approval by including relevant links and excluding any unrelated social media URLs.</p>
            <a-form-item
              :validateStatus="modelRef.links[modelRef.links.findIndex(item => item.id == 3)].isUrl ? '' : 'error'"
            >
            <div class="input" style="margin-top: 24px;">
                <span>Website / Blog</span>
                <artmisads-input @blur="(v) => onUrlChange(v, modelRef.links.findIndex(item => item.id == 3))" v-model:value="modelRef.links.filter(item => item.id == 3)[0].url"/>
                <div v-if="!modelRef.links[modelRef.links.findIndex(item => item.id == 3)].isUrl" class="ant-form-item-explain-error" style="">Please input the accurate URL</div>
            </div>
            </a-form-item>
            <a-form-item
            >
            <div class="input" style="margin-top: 24px;">
                <span>Social</span>
                <template v-for="(item, index) in modelRef.links">
                <a-form-item
                     :key="index"
                     v-if="item.id != 2 && item.id != 3"
                     style="margin-bottom: 12px;"
                     :validateStatus="item.isUrl ? '' : 'error'"
                    >
                    <artmisads-input atype="addonBefore" @blur="(v) => onUrlChange(v, index)" :placeholder="`Url（Type：${item.name} Account URL）`" v-model:value="item.url">
                      <template #addonBefore>
                        <div class="social-addon">
                          <svg-icon class="icon-app" :name="item.name.toLowerCase()" />
                          <span class="main-text" style="margin-left: 12px;"> {{ item.name }}</span>
                        </div>
                      </template>
                    </artmisads-input>
                    <div v-if="!item.isUrl" class="ant-form-item-explain-error" style="">Please input the accurate URL</div>
                </a-form-item>
                </template>
                <a-form-item style="margin-top: 24px;" v-if="modelRef.extraSocial.length>0">
                    <artmisads-select size="large" style="width: 100%;" value="+ Select to Add Other Platform" placeholder="+ Select to Add Other Platform" :options="modelRef.extraSocial" @change="onAddSocial">
                      <template #option="{ value: val, label }">
                        <span role="img" :aria-label="val" style="font-size: 18px;vertical-align: -0.1em;"><svg-icon style="border-radius: 4px; overflow: hidden;" :name="label.toLowerCase()" /></span>
                        &nbsp;&nbsp;{{ label }}
                      </template>
                    </artmisads-select>
                </a-form-item>
            </div>
            </a-form-item>
            <a-form-item
              :validateStatus="modelRef.links[modelRef.links.findIndex(item => item.id == 2)].isUrl ? '' : 'error'"
            >
                <div class="input" style="margin-top: 24px;">
                    <span>Other</span>
                    <artmisads-input @blur="(v) => onUrlChange(v, modelRef.links.findIndex(item => item.id == 2))" v-model:value="modelRef.links.filter(item => item.id == 2)[0].url"/>
                    <div v-if="!modelRef.links[modelRef.links.findIndex(item => item.id == 2)].isUrl" class="ant-form-item-explain-error" style="">Please input the accurate URL</div>
                </div>
            </a-form-item>
        </div>
        </a-form-item>
        <a-form-item style="margin-top: 24px;">
          <artemisads-divid/>
        </a-form-item>
      </a-form> 
      <div style="display: flex; justify-content: right;margin-top: 24px;padding-bottom: 80px;">
            <artmisads-button type="primary" style="border-radius: 20px;" class="custom-btn" @click="onJump(2)">Next
              <svg-icon name="jiantou-you-cu" style="vertical-align: 0.01em;"/>
            </artmisads-button>
      </div>
    </div>
    </div>
  </template>
  <script setup>
    import { ref, onMounted, reactive, toRaw } from 'vue';
    import { useRoute } from 'vue-router';
    import { Form, message } from 'ant-design-vue';

    import SvgIcon from '~/components/SvgIcon.vue';
  
    const useForm = Form.useForm;
    const props = defineProps(['goTo']);
    const route = useRoute();

    const reviewStatus = ref(false);
  
    const modelRef = reactive({
      creatorName: '',
      creatorEmail: '',
      links: [{
        name: 'Other',
        id: 2,
        url: '',
        isUrl: true
      }, {
        name: 'Website/Blog',
        id: 3,
        url: '',
        isUrl: true
      }, {
        name: 'Facebook',
        url: '',
        id: 6,
        isUrl: true
      }, {
        name: 'Instagram',
        url: '',
        id: 5,
        isUrl: true
      }, {
        name: 'YouTube',
        url: '',
        id: 7,
        isUrl: true
      }, {
        name: 'Tiktok',
        url: '',
        id: 4,
        isUrl: true
      }, {
        name: 'X',
        url: '',
        id: 13,
        isUrl: true
      }],
      extraSocial: [{
          label: 'Red',
          value: 9
      }, {
          label: 'WeChat',
          value: 10
      }, {
          label: 'Weibo',
          value: 11
      }, {
          label: 'Pinterest',
          value: 8
      }, {
          label: 'LinkedIn',
          value: 12
      }],
    });

  
    const rulesRef = reactive({
      creatorName: [{
        required: true,
        trigger: ['blur'],
        message: 'Publisher/Creator Name cannot be empty',
      }],
      creatorEmail: [{
        required: true,
        message: 'Email cannot be empty',
        trigger: ['change']
      }, {
        type: 'email',
        message: 'Invalid email',
        trigger: ['blur']
      }],
      links: [{
        validator: (rule, value, callback) => {
          const count = value.filter(item=> {
              return item.url !== ''
          }).length;
          if (!count) {
            callback('Please provide at least one link.');
          }
          const isUrl = value.filter(item=> {
              return !item.isUrl
          }).length;
          if (isUrl > 0) {
            callback('');
          }
          callback()
        },
      }],
    });
  
    const { validate, validateInfos } = useForm(modelRef, rulesRef);

    const onUrlChange = (v, index) => {
        if (v.target.value) {
          const regex = /^(https?:\/\/)([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
          modelRef.links[index].isUrl = regex.test(v.target.value);
        } else {
          modelRef.links[index].isUrl = true;
        }
    }

    const onJump = (step) => {
        validate().then(res => {
          if (!res.errorFields) {
          localStorage.setItem('profile-step-2', JSON.stringify(toRaw(modelRef)));
          props.goTo(step)
        }
      })
    }

    const onAddSocial = (v) => {
        const name = modelRef.extraSocial.filter(item => {
            return item.value === v
        })[0].label;
        const index = modelRef.extraSocial.findIndex(item => {
            return item.value === v
        })
        modelRef.links.push({
            name: name,
            id: v,
            url: '',
            isUrl: true
        })
        modelRef.extraSocial.splice(index, 1);
    }

    onMounted(() => {
      const localStoragePrefix = location.hostname;
      const userInfo = localStorage.getItem(`${localStoragePrefix}_artmisAdsUserInfo`);
      if(userInfo && JSON.parse(userInfo).userType === "publisher" && JSON.parse(userInfo).reviewStatus === 'Rejected') {
        reviewStatus.value = true;
      }
      const step2 = localStorage.getItem('profile-step-2') && JSON.parse(localStorage.getItem('profile-step-2'));
      console.log(step2);
      Object.assign(modelRef, step2);
    })
  
  </script>
  <style lang="less" scoped>
    .creator{
      width: 100%;
      height: calc(100vh - 184px);
      padding-bottom: 50px;
      overflow-y: scroll;
      scrollbar-width: none;
      display: flex;
      justify-content: center;
      .creator-content {
        width: 500px;
        display: flex;
        flex-direction: column;
      }
        &::-webkit-scrollbar {
        display: none; /* Chrome, Safari 和 Edge */
        }
      .main-text {
        opacity: 0.7;
        font-family: Sora, sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #0F0629;
        letter-spacing: 0.4px;
        line-height: 20px;
      }
      .subtitle {
        color: var(--dark-3);
        font-size: 14px;
        font-family: Sora, sans-serif;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0.4px;
        text-align: left;
        margin-top: 8px;
      }
      .icon-app {
        font-size: 24px;
        border-radius: 4px;
        overflow: hidden;
      }
      > h3 {
        height: 36px;
        font-family: Sora, sans-serif;
        font-weight: 500;
        font-size: 28px;
        color: #0F0629;
        letter-spacing: 0.5px;
        line-height: 36px;
      }
      .input {
        display: flex;
        flex-direction: column;
        margin-top: 24px;
        width: 100%;
        padding: 1px;
        > span {
          height: 16px;
          opacity: 0.7;
          font-family: Sora, sans-serif;
          font-weight: 500;
          font-size: 14px;
          color: #0F0629;
          letter-spacing: 0.4px;
          line-height: 16px;
          margin-bottom: 8px;
        }
        > input {
          height: 40px;
        }
      }
      .social-addon {
        font-size: 14px;
        justify-content: left;
        align-items: center;
        width: 113px;
        text-align: left;
        display: flex;
        box-sizing: border-box;
      }
      .link-card {
        margin-top: 24px;
        .title {
          font-family: Sora, sans-serif;
          font-weight: 500;
          font-size: 20px;
          color: #0F0629;
          letter-spacing: 0.5px;
          line-height: 28px;
          margin-bottom: 6px;
        }
        .subtitle {
          font-family: Sora, sans-serif;
          font-weight: 400;
          font-size: 13px;
          color: var(--dark-3);
          letter-spacing: 0.3px;
          line-height: 16px;
        }
        .input {
            width: 100%;
        }
      }
      :deep(.ant-form) {
        .ant-form-item {
          margin-bottom: 0;
        }
      }
      :deep(.ant-select-item-option-content) {
        display: flex;
        > img {
          width: 22px;
          height: 16px;
          align-self: center;
          margin-right: 12px;
        }
      }
    }

    :deep(.ant-alert) {
      margin-top: 20px;
      background: #FEF4E5;
      border: 1px solid #F6AC2D;
      border-radius: 8px;
      box-shadow: none;
      width: 100%;
      padding: 8px;
      line-height: 1.35;
      // color: var(--dark-3);
      .anticon {
        color: #F59B00;
        margin-right: 8px;
        margin-top: 2px;
      }
      .ant-alert-description {
        color: #0F0629;
      }
    }
    
  </style>