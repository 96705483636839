<template>
  <a-range-picker :class="className" v-bind="props"/>
</template>
<script setup scoped>
import { computed } from "vue";

const defaultSize = "middle"; // large small

const props = defineProps(["size"]);

const className = computed(() => {
  let inputClass = "artmisads-range-picker";
  if (props.size) {
    inputClass += ` ${props.size}`;
  } else {
    inputClass += ` ${defaultSize}`;
  }
  return inputClass;
});
</script>
<style lang="less">
.ant-picker-range.artmisads-range-picker {
  border: 1px solid var(--border-1);
  box-shadow: var(--section-box-shadow-2);
  .ant-picker-input {
    input {
      color: var(--dark-2);
    }
  }
  &:hover {
    border-color: var(--bg-gray-7) !important;
    background-color: var(--bg-gray-1);
  }
  &.ant-picker-focused {
    border: 1px solid var(--primary-border) !important;
    box-shadow: var(--section-box-shadow-4) !important;
    .ant-picker-input {
      input {
        color: var(--dark-1);
      }
    }
  }
}
</style>
