<template>
  <div class="connection">
    <breadcrumbs :breadcrumbsData="breadcrumbsData" #icon>
      <svg class="icon" aria-hidden="true" style="fill: #fff">
        <use xlink:href="#icon-a-shezhi2"></use>
      </svg>
    </breadcrumbs>
    <pulic-report-notice type="normal" isWrap="true">
        <template #title> Your account information is safe with us.</template>
        <template #describe>We keep your account details conﬁdential and DO NOT share any of them with Amazon or third parties.</template>
    </pulic-report-notice>
   
    <div class="table">
      <div class="card-header">
        <div class="action">
          <a id="LoginWithAmazon" @click.prevent="openCountry">
            <Icon name="disanfang-amazon" class="amazon-icon"></Icon>
            <Icon name="jiahao-cu" class="amazon-icon-plus"></Icon>
            <span> Add Amazon Connection</span>
          </a>
          <!-- <div class="showCountry">
            <Icon :name="currCountry?.icon" class="country-icon"></Icon>
            <span>{{ currCountry?.countryCode }}</span>
          </div> -->
        </div>
      </div>
      <div class="table-content">
        <artmisads-table :pagination="false" :columns="columns" :data-source="dataSource" :loading="load">
          <template #bodyCell="{ column, record }">
            <template v-if="column.key === 'amazonUser'">
              <div class="avator" :style="record.status == 'disconnection' ? { 'opacity': 0.5 } : ''">
                <div class="user">
                  <div class="user-name">
                    {{ record.amazonName }}
                  </div>
                  <div class="user-email">{{ record.amazonEmail }}</div>
                  <div class="user-id">{{ record.amazonUserId }}</div>
                </div>
              </div>
            </template>
            <template v-if="column.key === 'status'">
              <div v-if="record.status == 'disconnection'" class="show-status" ref="status">
                <a-tooltip placement="bottom" :getPopupContainer="() => $refs.status">
                  <template #title>
                    It has been detected that this Amazon Seller Account has been de-authorized on the Amazon
                    Attribution platform. You can continue to manage the Brands and Products under this account only
                    after re-authorizing it.
                  </template>
                  <Icon name="a-warring-tianchong3" class="warningIcon"></Icon>
                </a-tooltip>
                <span :style="{ 'opacity': 0.5 }">Disconnection</span>
              </div>
              <showStatus v-else :isActive="record.status == 'active'" :noBorder="true"
                :text="['Active', 'Inactive']" />

            </template>
            <template v-if="column.key === 'createdAt'">
              <div :style="record.status == 'disconnection' ? { 'opacity': 0.5 } : ''">
                <span v-if="record.updatedAt">{{
                  moment(parseInt(record.updatedAt)).format("YYYY-MM-DD HH:mm:ss")
                  }}</span>
                <span v-else>{{
                  moment(parseInt(record.createdAt)).format("YYYY-MM-DD HH:mm:ss")
                  }}</span>
              </div>

            </template>
            <template v-if="column.key === 'inactiveProductCount'">
              <a class="table-font" @click="goProductPage(record, 'inactive')">{{ record.inactiveProductCount }}</a>
            </template>
            <template v-if="column.key === 'action'">
              <a-space direction="vertical" v-if="record.status == 'active'">
                <artmisads-select-button size="small"  @click="openBrandPage(record)">
                  Activate Brands
                </artmisads-select-button>
                <artmisads-select-button size="small" @click="openModal(record, 'deactivate')">
                  Deactivate
                </artmisads-select-button>
              </a-space>
              <artmisads-button size="small" :isSquare="true" type="primary" @click="activateConnection(record)"
                v-else-if="record.status == 'inactive'">
                Activate
              </artmisads-button>
              <a-button class="delete-action" size="small" @click="openModal(record, 'remove')" v-else>
                Delete
              </a-button>
            </template>
          </template>
        </artmisads-table>
      </div>
    </div>
    <artmisads-modal title="Are you sure you want to delete this connection?" v-model:open="removeModel" @ok="onRemove"
      @cancel="() => {
        removeModel = false;
      }" style="width: 600px" okText="Confirm" cancelText="Cancel">
      <div class="deactive">
        After deletion, you can add it again. All the previously archived Brand and Products data will not be lost.
      </div>
    </artmisads-modal>
    <artmisads-modal title="Are You Sure You Want to Deactivate this Connection?" v-model:open="deactiveModel"
      @ok="deactivateConnection" @cancel="() => {
        deactiveModel = false;
      }" style="width: 650px" okText="Confirm" cancelText="Cancel">
      <div class="deactive">
        By removing this connection, you will lose the ability to manage the brands and products linked to this account
        on
        Artemis Ads. Furthermore, these brands and products will no longer be visible to Publishers or Creators.
      </div>
    </artmisads-modal>
    <artmisads-modal 
      v-model:open="showCountryModal" 
      style="width: 280px" 
      okText="Confirm" 
      cancelText="Cancel"
      centered
      :footer="null">
      <div class="country-modal">
        <div class="country-title">Select an Amazon site.</div>
        <template v-for="option in COUNTRY_BASE" :key="option.countryCode">
          <div class="country-item">
            <artmisads-select-button size="large" class="country-select-item" :value="option.countryCode"
              @click.prevent="onChangeCountry(option.countryCode)">
              <Icon :name="option.icon" class="icon"></Icon>
              <span>{{ option.host }}</span>
            </artmisads-select-button>
          </div>

        </template>
      </div>

    </artmisads-modal>
    <activate-brand :brandData="brandData" :userId="currItem?.amazonUserId" :country="amazonCountryCode"
      :showActivateBrand="showActivateBrand" :setShowActivateBrand="setShowActivateBrand">
    </activate-brand>
    <div id="amazon-root"></div>
    <connection-modal :configs="modalSetting"></connection-modal>
    <contextHolder />
  </div>
</template>
<script setup>
import { ref, onMounted, reactive, watch, toRaw, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { message, notification } from "ant-design-vue";
import jwt from 'jsonwebtoken';
import moment from "moment";
import breadcrumbs from "../breadcrumbs.vue";
import { useUserInfoStore } from "@/storeData";
import { useSellerBrandStore } from '@/storeData/sellerbrand';
import { brandApi, adminApi } from "@/server/index";
import { connectionApi } from "@/server/seller/index";
import activateBrand from "./components/activateBrand.vue";
import connectionModal from "./components/connectionModal.vue";
import showStatus from "./components/showStatus.vue";
import Icon from "@/components/Icon.vue";
import PulicReportNotice from "@/components/PulicReportNotice.vue";
import { COUNTRY_BASE } from "@/contants/common";
const breadcrumbsData = {
  title: "Connections",
  des: "View and manage your Amazon Connections",
  color: "#776D91",
  links: [
    {
      name: "Home",
      path: "/seller/welcome",
    },
    {
      name: "Settings",
      path: null,
    },
    {
      name: "Connections",
      path: "/seller/connection",
    },
  ],
};
const columns = [
  {
    title: "Amazon Seller Account ",
    key: "amazonUser",
    dataIndex: "amazonUser",
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
  },
  {
    title: "Connection time",
    key: "createdAt",
    dataIndex: "createdAt",
  },
  {
    title: "Actions",
    key: "action",
    dataIndex: "action",
  },
];
const modalSetting = reactive({
  waitingOpen: false,
  failedOpen: false,
  errorCode: ''
})
const [api, contextHolder] = notification.useNotification();
const openNotification = description => {
  api.info({
    message: `Notice`,
    description: description,
    placement: 'topRight',
    duration: 10
  });
};
const user = useUserInfoStore();
const brandStore = useSellerBrandStore();
const status = ref();
const load = ref(true);
const amazonCountryCode = ref();
const route = useRoute();
const router = useRouter();
const dataSource = ref([]);
const pagination = reactive({
  total: 30,
  defaultPageSize: 10,
  size: 10,
  current: 1,
  showSizeChanger: true,
  pageSize: 10,
});
let currItem;
let brandData = [];
let removeModel = ref(false);
let deactiveModel = ref(false);
const showCountryModal = ref(false)
const showActivateBrand = ref(false);
const setShowActivateBrand = (status) => {
  showActivateBrand.value = status;
};
const currCountry = computed(() => {
  return COUNTRY_BASE.find((v) => v.countryCode == user.userInfo?.countryCode);
});
onMounted(() => {
  amazonLoginInitialize();
  getData();
  amazonCountryCode.value = user.userInfo.countryCode;
  const amazonAuthorizationCode = route?.query?.code;
  if (amazonAuthorizationCode) {
    onBind(amazonAuthorizationCode);
  }
});
function amazonLoginInitialize() {
  const CLIENT_ID =
    "amzn1.application-oa2-client.d1775637c50f46d4b039895ab42f2dda";
  window.onAmazonLoginReady = function () {
    amazon.Login.setClientId(CLIENT_ID);
    // amazon.Login.logout();
  };
  (function (d) {
    var a = d.createElement("script");
    a.type = "text/javascript";
    a.async = true;
    a.id = "amazon-login-sdk";
    a.src = "https://assets.loginwithamazon.com/sdk/na/login1.js";
    d.getElementById("amazon-root").appendChild(a);
  })(document);
}
function onSubmit() {
  const options = {};
  options.scope = "advertising::campaign_management profile";
  options.response_type = "code";
  // options.popup = false;
  amazon.Login.authorize(options, `${location.origin}/seller/connection`);
  return false;
}
const onChangeCountry = (e) => {
  adminApi.switchCountry({
    newCountryCode: e
  }).then(res => {
    console.log(res);

    if (res.success) {
      const userToken = res.data.token;
      const artmisAdsUserData = jwt.decode(userToken);
      user.setUserInfo(artmisAdsUserData, userToken);
      showCountryModal.value = false;
      onSubmit();
      // if(route.name !== 'sl-connection'){//切换站点更新当前页数据
      //   router.go(0);
      // }

    } else {
      openNotification(res.error)
    }
  })
}
const openCountry = () => {
  showCountryModal.value = true;
}

const getData = () => {
  load.value = true;
  connectionApi.getAmazonConnections().then((res) => {
    load.value = false;
    if (res.success) {
      res.data.data.map((v) => {
        v.userName = user.userInfo.userName;
        v.userEmail = user.userInfo.email;
      });
      dataSource.value = res.data.data;
      pagination.total = res.data.total;
    }
  });
};

const openBrandPage = (item) => {
  brandStore.setParams({
    amazonUserId: item.amazonUserId
  })
  router.push({ name: 'brandList' })

};

const activateConnection = item => {
  connectionApi.activateAmazonConnections({ connectionId: item.id }).then(res => {
    getData();

  })
}
const deactivateConnection = item => {
  deactiveModel.value = false;
  connectionApi.deactivateAmazonConnections({ connectionId: currItem.id }).then(res => {
    if (res.success) {
      getData();
      message.success("Success!", 1);
    }

  })
}
const openModal = (record, type) => {
  currItem = record;
  switch (type) {
    case "remove":
      removeModel.value = true;
      break;
    case "deactivate":
      deactiveModel.value = true;
      break;
  }
};
const onRemove = () => {
  removeModel.value = false;
  connectionApi
    .unbindAmazonConnections({ connectionId: currItem.id })
    .then((res) => {
      if (res.success) {
        getData();
        message.success("Success!", 1);
      }
    });
};
const getSellerBrands = async (amazonUserId) => {
  const res = await brandApi.getSellerBrandsByUser({ amazonUserId: amazonUserId });
  const { success, data } = res;
  if (success) {
    console.log(data);
    if (data?.length) {
      showActivateBrand.value = true;
      brandData = data;
    } else {
      message.success('Success!', 1);
    }
    // brandList.value = data;
    // checkedList.value = data.filter(item => item.status === 'active').map(item => item.id);
  }
}
const onBind = (code) => {
  router.replace({ name: route.name });
  modalSetting.waitingOpen = true;
  let country = toRaw(amazonCountryCode.value);
  let param = {
    amazonAuthorizationCode: code,
    amazonCountryCode: country,
  };
  connectionApi.bindAmazonConnections(param).then((res) => {
    modalSetting.waitingOpen = false;
    if (res.success) {
      getData();
      getSellerBrands(res.data);
    } else {
      modalSetting.failedOpen = true;
      if (res.code == '2010') {
        modalSetting.errorCode = res.code;
      }
      // openNotification(res.error);
    }
  });
};
</script>
<style lang="less" scoped>
.table-font {
  font-family: Sora-Regular;
  font-weight: 400;
  font-size: 14px;
  color: var(--dark-1);
  letter-spacing: 0.4px;
  line-height: 19px;
  cursor: pointer;
  text-decoration: underline;
}

.action-font {
  display: inline-block;
  cursor: pointer;
  border: 1px solid var(--border-1);
  height: 24px;
  border-radius: 8px;
  padding: 0 8px;
  opacity: 0.7;
  font-family: Sora-Regular;
  font-weight: 400;
  font-size: 13px;
  color: var(--dark-1);
  letter-spacing: 0.3px;
  line-height: 24px;
}

.connection {
  padding: 16px;
  padding-top: unset;
  :deep(.ant-tooltip-inner) {
    font-size: 13px;
    padding: 6px 12px !important;
    line-height: 18px;
    min-width: 300px !important;
  }
}
.country-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 8px;
  margin-left: -4px;
  .country-title{
    font-weight: 500;
    font-size: 16px;
    color:var(--dark-1);
    letter-spacing: 0.4px;
    line-height: 27px;
  }
  .country-item {
    margin-block: 6px;

    .country-select-item {
      width: 232px;
      text-align: left;
      display: flex;
      align-items: center;
      >span{
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0.4px;
        line-height: 18px;
      }

      .icon {
        font-size: 18px;
        margin-right: 8px;
        vertical-align: middle;
        margin-left: -8px;
      }
    }
  }

}
.divide {
  margin-bottom: 16px;
}

.delete-action {
  border: 1px solid var(--red-border);
  color: var(--red-text);

  &:hover {
    border: 1px solid var(--red-border);
    color: var(--red-text);
  }
}

.table {
  padding: 0 16px;
  padding-bottom: 32px;
  background-color: var(--color-white);
  border-radius: 8px;
  box-shadow: var(--section-box-shadow-6);


  .card-header {
    height: 64px;
    display: flex;
    justify-content: flex-end;

    .title {
      height: 56px;
      font-size: 20px;
      color: var(--dark-1);
      font-weight: 500;
      align-content: center;
    }

    .action {
      display: flex;
      align-items: center;

      >a {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 4px;
        width: 234px;
        height: 32px;
        background-image: linear-gradient(180deg, #ffe8aa 0%, #f5c645 100%);
        border: 1px solid #b38b22;
        box-shadow: 0 1px 0 0 #1a16250d;
        border-radius: 2px;
        font-family: PingFangSC-Medium;
        font-weight: 500;
        font-size: 14px;
        color: var(--dark-1);
        letter-spacing: 0.4px;
        line-height: 20px;

        .amazon-icon {
          font-size: 20px;
        }

        .amazon-icon-plus {
          margin-inline: 4px;
          font-size: 14px;
        }
      }

      .showCountry {
        width: 81px;
        height: 32px;
        border: 1px solid var(--border-1);
        box-shadow: 0 1px 0 0 var(--box-shadow-1);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 12px;

        .country-icon {
          font-size: 20px;
        }

        >span:last-of-type {
          margin-left: 8px;
          color: var(--dark-1);
          letter-spacing: 0.4px;
          line-height: 20px;
        }
      }

      :deep(.ant-select-selector) {
        height: 40px;

        .ant-select-selection-item {
          display: flex;
          align-items: center;

          .icon {
            font-size: 24px;
            margin-right: 8px;
          }

          >span {
            font-family: Sora;
            font-weight: 400;
            font-size: var(--font-size-large);
            line-height: 36px;
          }
        }
      }
    }
  }
}

.table-head {
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;

  .filters {
    display: flex;
    justify-content: space-between;

    .statusFilter {
      position: relative;
      z-index: 1;
      margin-left: 18px;
      // opacity: 0.7;
      font-family: Sora-Regular;
      font-weight: 400;
      font-size: 14px;
      color: var(--dark-2);
      letter-spacing: 0.4px;
      line-height: 20px;
    }
  }
}

.table-content {
  .show-status:deep {
    display: flex;
    align-items: center;

    .warningIcon {
      color: var(--yellow-4);
      margin-right: 4px;
    }

    >span:last-of-type {
      color: #878394;
    }

    // .ant-tooltip-arrow{
    //   &::before{
    //     // background-color: #b38b22;
    //     height: 16px;
    //     transform: translateY(12px) rotate(45deg);
    //     border-radius: 2px;
    //   }
    // }
  }

  .user {
    flex: 1;

    .user-name {
      height: 20px;
      color: var(--dark-1);
      font-weight: 500;
      font-size: 16px;
      letter-spacing: 0.4px;
      line-height: 20px;
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 1;
      // overflow: hidden;
    }

    .user-email {
      font-weight: 500;
      font-size: 12px;
      color: var(--dark-1);
      letter-spacing: 0.3px;
      line-height: 16px;
    }

    .user-id {
      height: 16px;
      // font-family: PingFangSC-Medium;
      font-weight: 500;
      font-size: 12px;
      color: var(--dark-3);
      letter-spacing: 0.3px;
      line-height: 16px;
    }
  }
}
</style>
