<template>
  <div class="breadcrumbs">
    <div class="link">
      <a :key="item.path" v-for="(item, index) in props.breadcrumbsData.links" :href="item.path">
        <span class="item">
          <span class="font" :style="{'background-color':props.breadcrumbsData.color}" v-if="index===1 && props.breadcrumbsData.hasTitleIcon==undefined" >
            <slot name="icon"></slot>
          </span>{{ item.name }}
        </span>
        <span>
          <svg class="icon iconBread" aria-hidden="true" v-show="index < props.breadcrumbsData.links.length - 1">
            <use xlink:href="#icon-jiantou-you-cu"></use>
          </svg>
        </span>
      </a>
    </div>
    <div class="preface">
      <div class="title">
        <div class="font" :style="{'background-color':props.breadcrumbsData.color}" v-show="props.breadcrumbsData.hasTitleIcon"><slot name="icon"></slot></div>
        <div>{{props.breadcrumbsData.title}}</div>
      </div>
      <div class="des">{{props.breadcrumbsData.des}}</div>
    </div>
    
  </div>
</template>
<script setup>
  import {ref} from 'vue';
  const props = defineProps(['breadcrumbsData']);
</script>
<style lang="less" scoped>
  .breadcrumbs {
    padding: 14px 0 20px 0;
    width: 100%;
    height: 96px;
    background: #F3F2F7;
    border-radius: 0 0 8px 8px;
    margin-top: 1px;
    .link {
      padding-bottom: 8px;
      a {
        font-weight: 400;
        font-size: 12px;
        color:var(--dark-3);
        letter-spacing: 0.3px;
        line-height: 16px;
        text-decoration: none;
        &:first-of-type{
          margin-left: -3px;
        }
        &:last-child {
          
          >span{
            color: var(--dark-2);
          }
          
        }
        .item{
          border-radius: 4px;
          padding:2px 4px;
          &:hover{
            background-color: var(--color-white);
            // font-weight: 500;
            color: var(--dark-1);
          }
          &:active{
            background-color: var(--bg-gray-4);
            opacity: 1;
            font-weight: 500;
          }
        }
      }
      .font{
        border-radius: 4px;
        padding: 0 2px;
        margin-right: 4px;
        color: var(--dark-1);
      }
    }
    .iconBread{
      font-size: 11px;
      // margin: 0 2px;
    }
    .title {
      display: flex;
      font-weight: 600;
      font-size: 24px;
      color: #0F0629;
      letter-spacing: 0.5px;
      line-height: 32px;
      height: 32px;
      margin-right: 16px;
      .font{
        width: 32px;
        height: 32px;
        font-size: 24px;
        margin-right: 8px;
        border-radius: 8px;
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .des {
      font-weight: 400;
      font-size: 14px;
      color:var(--dark-2);
      letter-spacing: 0.4px;
      line-height: 28px;
      height: 32px;
      display: flex;
      align-items: flex-end;
    }
  }
  .preface{
    display: flex;
    justify-content: flex-start;
  }
</style>