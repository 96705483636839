<template>
  <div class="filter-tag" ref="filterTagRef">
    <div
      v-for="item in tagData"
      :key="item.key"
      class="filter-tag-item-wrap"
      @click="() => changeSelect(item.key)"
    >
      <div class="filter-tag-item" :class="[item.className, activeKey === item.key ? 'select': '']">
        <div class="filter-tag-item-cont">
          <span class="name">
            {{item.label}}
            <a-popover
              v-if="item.key === 'discountedSalesAmount'"
              :getPopupContainer="() => filterTagRef"
            >
              <template #content>
                <p class="tooltip-text">Sales for Commission: Depends on product deals setting on ArtemisAds, this is finally used for calculating commissions.</p>
              </template>
              <svg class="wenhao-icon icon" aria-hidden="true">
                <use xlink:href="#icon-wenhao-tianchong"></use>
              </svg>
            </a-popover>
          </span>
          <span class="tag-icon">
            <Icon :name="item.icon"></Icon>
          </span>
        </div>
        <span class="tag-num">{{props.tagNumParams[item.dataKey] ? getCurrency(item.key) : ''}}{{props.tagNumParams[item.dataKey]}}</span>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { AMAZON_CURRENCY_MAPPER } from '~/contants/common';
  import { computed, onBeforeMount, ref } from 'vue';
  import Icon from '../Icon.vue';
  import { RESPORTS_TAG_ARR } from '~/contants/reports';

  const filterTagRef = ref();
  const props = defineProps(['noNeedList', 'setParams', 'tagNumParams', 'marketplace']);
  const tagData = ref([]);
  const activeKey = ref('clicks')

  const getCurrency = key => {
    let data = '';
    if (key === 'commissionAndFeeAmount' || key === 'bonusAmount' || key === 'discountedSalesAmount' || key === 'salesAmount') {
      data = AMAZON_CURRENCY_MAPPER[props.marketplace]
    }
    return data;
  }

  const changeSelect = metricName => {
    activeKey.value = metricName;
    props.setParams({ metricName });
  }

  onBeforeMount(() => {
    let needList = [...RESPORTS_TAG_ARR];
    if (props.noNeedList) {
      needList = needList.filter(item => props.noNeedList.find(i => i !== item.key));
    }
    tagData.value = needList;
  })

</script>
<style lang="less" scoped>
  .filter-tag {
    display: flex;
    flex-wrap: wrap;
    :deep(.ant-popover) {
      width: 400px;
      .tooltip-text {
        padding: 16px;
      }
    }
  }
  .filter-tag-item-wrap {
    padding: 4px;
  }
  .filter-tag-item {
    position: relative;
    min-height: 61.5px;
    user-select: none;
    box-sizing: border-box;
    width: 272px;
    background-color: var(--bg-gray-8);
    border-radius: 8px;
    padding: 8px 7px 7px 12px;
    cursor: pointer;
    transition: all .3s;
    &:hover {
      background-color: var(--bg-gray-5);
    }
    &.select {
      .filter-tag-item-cont {
        .name{
          color: var(--color-white);
        }
      }
      .tag-num {
        color: var(--color-white);
      }
    }
    &.clicks-tag {
      &.select {
        background-color: var(--tag-bg-1);
        .tag-icon {
          background-color: var(--tag-bg-1);
        }
      }
      .tag-icon {
        background-color: var(--tag-icon-bg-1);
      }
    }
    &.detailPageViews-tag {
      &.select {
        background-color: var(--tag-bg-2);
        .tag-icon {
          background-color:var(--tag-bg-2);
        }
      }
      .tag-icon {
        background-color: var(--tag-icon-bg-2);
      }
    }
    &.addToCart-tag {
      &.select {
        background-color: var(--tag-bg-3);
        .tag-icon {
          background-color:var(--tag-bg-3);
        }
      }
      .tag-icon {
        background-color: var(--tag-icon-bg-3);
      }
    }
    &.conversions-tag {
      &.select {
        background-color: var(--tag-bg-4);
        .tag-icon {
          background-color:var(--tag-bg-4);
        }
      }
      .tag-icon {
        background-color: var(--tag-icon-bg-4);
      }
    }
    &.sales-tag {
      &.select {
        background-color: var(--tag-bg-5);
        .tag-icon {
          background-color:var(--tag-bg-5);
        }
      }
      .tag-icon {
        
        background-color: var(--tag-icon-bg-5);
      }
    }
    &.commssion-tag {
      &.select {
        background-color: var(--tag-bg-6);
        .tag-icon {
          background-color:var(--tag-bg-6);
        }
      }
      .tag-icon {
        background-color: var(--tag-icon-bg-6);
      }
    }
    &.bonus-tag {
      &.select {
        background-color: var(--tag-bg-7);
        .tag-icon {
          background-color:var(--tag-bg-7);
        }
      }
      .tag-icon {
        background-color: var(--tag-icon-bg-7);
      }
    }
    &.sales-commission-tag {
      .wenhao-icon {
        width: 16px;
        height: 16px;
        fill: var(--bg-gray-7);
      }
      &.select {
        background-color: var(--tag-bg-8);
        .wenhao-icon {
          fill: var(--color-white);
        }
        .tag-icon {
          background-color:var(--tag-bg-8);
        }
      }
      .tag-icon {
        background-color: var(--tag-icon-bg-8);
      }
    }
    .filter-tag-item-cont {
      display: flex;
      justify-content: space-between;
      .name {
        color: var(--dark-2);
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.3px;
      }
      .tag-icon {
        width: 22px;
        height: 22px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        svg {
          align-self: center;
          width: 16px;
          height: 16px;
          fill: var(--color-white);
        }
      }
    }
    .tag-num {
      color: var(--dark-2);
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      margin-top: 4px;
    }
  }
</style>
