<template>
  <a-button :class="className" v-bind="props">
    <slot v-if="!loading"></slot>
    <template v-else>loading...</template>
  </a-button>
</template>
<script setup>
  import { computed } from 'vue';

  const defaultType = 'default'; // primary text secondary
  const defaultSize = 'large'; // middle small

  const circleButton = true;

  // antd 没有secondary 类型的按钮, 我们的secondary样式是基于antd 的dashed样式做的修改
  const buttonClassMap = {
    'primary' :'artmisads-primary', // 主要按钮
    'default' :'artmisads-default', // 默认按钮
    'text' :'artmisads-text', // 文字按钮
    'dashed' :'artmisads-dashed', // 消极按钮
  }

  const props = defineProps(['type', 'loading', 'size', 'loading', 'borderRadius', 'isSquare']);

  const className = computed(() => {
    const buttonType = props.type || defaultType;
    let buttonClass = buttonClassMap[buttonType] || '';
    if (!buttonClass) {
      buttonClass = buttonClassMap[defaultType];
    }
    if (props.size) {
      buttonClass += ` ${props.size}`;
    } else {
      buttonClass += ` ${defaultSize}`;
    }
    if (props.loading) {
      buttonClass += ' artmisads-loading'
    }
    if (props.isSquare) {
      buttonClass += ' artmisads-square'
    }
    buttonClass += ' artmisads-button'
    return buttonClass;
  })

</script>
<style lang="less" scoped>
  .artmisads-button {
    font-weight: 500;
    text-align: center;
    line-height: 22px;
    letter-spacing: 0.4px;
    height: var(--input-height-large);
    font-size: var(--font-size-large);
    padding: 8px 20px 10px 20px;
    min-width: 120px;
    border-radius: 20px;
    &.artmisads-loading {
      opacity: 1;
      :deep(.ant-btn-loading-icon) {
        display: none;
      }
    }
    &.middle {
      height: var(--input-height-middle);
      border-radius: 14px;
      line-height: 22px;
      // min-width: auto;
      padding: 4px 12px 6px 12px;
      min-width: 88px;
    }
    &.small {
      height: var(--input-height-small);
      border-radius: 11px;
      line-height: 12px;
      min-width: auto;
      font-size: var(--font-size-mormal);
      padding: 4px 20px 6px 20px;
    }
    &.artmisads-primary {
      background-color: var(--primary);
      box-shadow: 0 1px 0 0 var(--box-shadow-1);
      color: var(--color-white);
      &:hover {
        background-color: var(--primary-hover);
        box-shadow: 0 2px 0 0 var(--box-shadow-1);
      }
      &:active {
        background-color: var(--primary-active);
        box-shadow: 0 2px 0 0 var(--box-shadow-1);
      }
      &:disabled{
        background-color: var(--primary-disable) !important;
        color: var(--dark-3);
        border: var(--border);
      }
      &.artmisads-loading  {
        background-color: var(--primary-loading);
      }
    }

    &.artmisads-default {
      background-color: var(--color-white);
      border: 2px solid var(--border-1);
      color: var(--dark-2);
      &:hover {
        border-color: var(--border-2);
        background-color: var(--bg-gray-1);
      }
      &:active {
        background-color: var(--bg-gray-2);
      }
      &:disabled{
        background-color: var(--primary-disable) !important;
        color: var(--dark-3); 
        border: var(--border);
      }
      &.artmisads-loading  {
        background-color: var(--color-white);
        border-color: var(--border-1);
        color: var(--dark-3);
      }
    }

    &.artmisads-text {
      background-color: transparent;
      color: var(--primary-text);
      &:hover {
        background-color: var(--bg-gray-4);
      }
      &:active {
        background-color: var(--bg-gray-5);
      }
      &:disabled{
        color: var(--border-2);
      }
      &.artmisads-loading  {
        color: var(--dark-3);
      }
    }

    &.artmisads-dashed {
      box-shadow: none;
      background-color: var(--bg-gray-6);
      color: var(--dark-2);
      border: none;
      &:hover {
        background-color: var(--bg-gray-4);
      }
      &:active {
        color: var(--dark-1);
        background-color: var(--bg-gray-5);
      }
      &:disabled{
        background-color: var(--bg-gray-6);
        color: var(--dark-4);
      }
      &.artmisads-loading  {
        background-color: var(--bg-gray-6);
        color: var(--dark-3);
      }
    }
  }
  .artmisads-button.artmisads-square {
    border-radius: 8px;
    &.middle {
      border-radius: 8px;
    }
    &.small {
      border-radius: 6px;
    }
  }
</style>
