<template>
  <div class="artmisads-number">
    <a-input-number @change="onChange" @pressEnter="pressEnter" @blur="blur" v-bind="props">
      <template #upIcon>
        <svg class="icon" aria-hidden="true" font-size="12px">
          <use xlink:href="#icon-jiantou-shang-cu"></use>
        </svg>
      </template>
      <template #downIcon>
        <svg class="icon" aria-hidden="true" font-size="12px">
          <use xlink:href="#icon-jiantou-xia-cu"></use>
        </svg>
      </template>
    </a-input-number>
    <div :class="className" v-if="props.suffix">{{ props.suffix }}</div>
  </div>
</template>
<script setup>
import { computed } from 'vue'
const props = defineProps([
  'value',
  'placeholder',
  'suffix',
  'max',
  'min',
  'width',
  'style',
  'size',
  'formatter',
  'parser',
  'step',
])
const emit = defineEmits(['blur', 'focus', 'change', 'pressEnter'])
const className = computed(() => {
  let inputClass = 'commission-suffix'
  if (props.size) {
    inputClass += ` ${props.size}`
  }

  return inputClass
})
const dealNum=num=>{
  if(typeof num !== 'number'){
    return
  }
  let numString= num && num.toString();
  let arr=num && numString.split('.');
  let middleNum='0';
  if(arr && arr.length>1){
    middleNum = Number(arr[1][0]);
    // if(arr[1][1]==5){
    //   if(arr[1][0]%2 !== 0){
    //     middleNum += 1
    //   }
    // }else if(arr[1][1] > 5){
    //   middleNum += 1
    // }
  }
  let endNum=arr && parseFloat(arr[0]+'.'+middleNum); 
  return endNum;
}
const onChange = (e) => {
  let newNum=dealNum(e);
  emit('change', newNum)
}
const pressEnter = (e) => {
  let newNum=dealNum(e);
  emit('pressEnter', newNum)
}
const blur = (e) => {
  emit('blur', e)
}
</script>
<style lang="less" scoped>
.artmisads-number:deep{
  position: relative;
  .commission-suffix {
    top: 0;
    z-index: 2;
    right: 35px;
    width: 22px;
    height: 32px;
    font-size: 14px;
    font-weight: 500;
    line-height: 32px;
    position: absolute;
    color: var(--dark-2);
    letter-spacing: 0.4px;
  }
  .ant-input-number{
    width: 100%;
    color: var(--dark-4);
    border: 1px solid var(--border-1);
    box-shadow: var(--section-box-shadow-2);
    &:hover {
      border-color: var(--bg-gray-7);
      background-color: var(--bg-gray-1);
    }
    &-focused {
      color: var(--dark-1) !important;
      background-color: var(--color-white) !important;
      border: 1px solid var(--primary-border) !important;
      box-shadow: var(--section-box-shadow-4) !important;
    }
    &-input {
      font-weight: 500;
      color: var(--dark-2);
      font-family: Sora, PingFang SC, Helvetica Neue, Helvetica, Microsoft YaHei,
        Arial, sans-serif;
      &::placeholder {
        font-weight: normal;
        color: var(--dark-4) !important;
      }
    }
    &-handler {
      border-color: var(--border-4);
    }
    &-handler-wrap {
      width: 30px;
      opacity: 1 !important;
    }
    &-handler-up-inner,
    &-handler-down-inner {
      color: var(--dark-4);
      &:hover {
        color: var(--dark-2);
      }
    }
    &-handler-down {
      &-disabled {
        .ant-input-number-handler-down-inner {
          &:hover {
            color: var(--dark-4);
          }
        }
        background-color: var(--bg-gray-1);
      }
    }
    &-handler-up {
      &-disabled {
        .ant-input-number-handler-up-inner {
          &:hover {
            color: var(--dark-4);
          }
        }
        background-color: var(--bg-gray-1);
      }
    }
  }
}
.commission-suffix {
  position: absolute;
  top: 0;
  right: 38px;
  width: 22px;
  font-weight: 500;
  font-size: 16px;
  color: var(--dark-1);
  letter-spacing: 0.4px;
  height: 32px;
  line-height: 32px;
  z-index: 2;
}
.small {
  height: 24px;
  line-height: 24px;
}
.large {
  right: 32px;
  height: 40px;
  line-height: 40px;
}
</style>