<!--
 * @Author: Hankill(hao.chen)
 * @Date: 2024-12-10 10:46:17
 * @LastEditors: Hankill(hao.chen)
 * @LastEditTime: 2024-12-13 10:41:52
 * @FilePath: /web_artemisads/src/artemisadsComponents/artemisadsTextarea.vue
 * @Description: 
-->
<template>
    <a-textarea v-bind="props" :class="className"/>
  </template>
  <script setup>
    import { computed } from 'vue';
  
    const defaultSize = 'middle'; // large small
  
    const props = defineProps(['size', 'atype']);
  
    const className = computed(() => {
      let inputClass = 'artmisads-textarea'
      if (props.size) {
        inputClass += ` ${props.size}`;
      } else {
        inputClass += ` ${defaultSize}`;
      }
      if(props.class){
        inputClass += ` ${props.class}`;
      }
      return inputClass;
    })
  
  </script>
  <style lang="less" scoped>
    .artmisads-textarea {
      border: var(--border);
      box-shadow: 0 1px 0 0 var(--box-shadow-2);
      border-radius: 8px;
      font-size: var(--font-size-large);
      background-color: var(--color-white);
      padding: 9px 12px;
      letter-spacing: 0.4px;
      line-height: 1.35;
      &.ant-input-status-error {
        border: 2px solid var(--red-border) !important;
        box-shadow: 0 1px 0 0 var(--box-shadow-2) !important;
        &:hover {
          border-color: var(--red-border) !important;
        }
      }
      &::-webkit-input-placeholder {
        color: var(--dark-4);
        letter-spacing: 0.4px;
      }
      &:hover {
        border: 1px solid var(--bg-gray-7);
        background-color: var(--bg-gray-1);
      }
      &:focus {
        color: var(--dark-1);
        border: 1px solid var(--primary-border-2);
        box-shadow: var(--section-box-shadow-4);
        background-color: var(--color-white);
      }
    }
  </style>
  