<template>
    <div class="deal-wrap">
        <a-popover placement="rightTop" :color="'#333'">
            <template #content>
                <div style="padding: 10px;color:#fff">
                    <p style="color: #fff;">SystemTime: {{moment().local().format('MM/DD/YYYY, h:mm A')}}</p>
                    <p style="color: #fff;">{{ getTimeZoneStr(user.userInfo.countryCode) }} Time: {{ moment().tz(timezone).format('MM/DD/YYYY, h:mm A') }}</p>
                    <p style="color: #fff;">Deal Start ({{ getTimeZoneStr(user.userInfo.countryCode) }}):  {{ moment(record.beginTime).tz(timezone).format('MM/DD/YYYY, h:mm A') }}</p>
                    <p style="color: #fff;">Deal End ({{ getTimeZoneStr(user.userInfo.countryCode) }}): {{ moment(record.endTime).tz(timezone).format('MM/DD/YYYY, h:mm A') }}</p>
                </div>
            </template>
            <div class="deal-type">
                <div v-if="record.type === 'Deal'">
                    <span class="type-wrapper">
                        <artemisads-icon name="deal"/>
                        <em>Deal</em>
                    </span>
                </div>
                <div v-if="record.type === 'Clippable Coupon'">
                    <span class="type-wrapper">
                        <artemisads-icon name="a-ClippableCoupon"/>
                        <em>Clippable Coupon</em>
                    </span>
                </div>
                <div v-if="record.type === 'Promo Code'">
                    <span class="type-wrapper">
                        <artemisads-icon name="a-PromoCode"/>
                        <em>Promo Code</em>
                    </span>
                </div>
                <div v-if="record.status === 'Active'">
                    <div class="coupon-status">
                        <artemisads-icon name="duigou-jiacu" style="color: #42B577;" ></artemisads-icon> 
                        <span>Active {{ moment(record.beginTime).tz(timezone).format('MM/DD') }} ~ {{ moment(record.endTime).tz(timezone).format('MM/DD') }}</span>
                    </div>
                </div>
                <div v-else-if="record.status === 'Upcoming'">
                    <div class="coupon-status">
                        <artemisads-icon name="jindu-cu" style="color: #E68303;" ></artemisads-icon> 
                        <span>Upcoming {{ moment(record.beginTime).tz(timezone).format('MM/DD') }} ~ {{ moment(record.endTime).tz(timezone).format('MM/DD') }}</span>
                    </div>
                </div>
            </div>
        </a-popover>
        <div class="promo-code" v-if="record.type === 'Promo Code'" @click="() => copyToClipboard(record.promoCode)">
            <div class="promo-code-title">
                <p>Promo Code:</p>
            </div>
            <div class="promo-code-brand">
                <p>{{ record.promoCode }}</p>
                <artemisads-icon style="font-size: 16px;" name="copy2" />
            </div>
        </div>
    </div>
</template>

<script setup>
import moment from 'moment'
import { ref, watch } from 'vue'
import { COUNTRY_TIME_TZ } from '~/contants/common.js'
import { copyToClipboard, getTimeZoneStr } from '~/utils';
import { useUserInfoStore } from "@/storeData";

const props = defineProps(['record']);
const user = useUserInfoStore();
const timezone = COUNTRY_TIME_TZ[user.userInfo.countryCode || 'US']
const record = ref(props.record);

watch(
  () => props.record,
  (newValue) => {
    record.value = newValue;
  }
)
</script>

<style lang="less">
.deal-wrap {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    margin-bottom: 5px;
    &:last-of-type {
        margin-bottom: 0;
    }
}
.deal-type {
    cursor: default;
    padding: 4px 6px;
    overflow: hidden;
    border-radius: 8px;
    color: var(--dark-1);
    background: var(--bg-gray-8);
    &:hover {
        background: var(--bg-gray-5);
    }
    .type-wrapper {
        display: flex;
        font-size: 12px;
        font-weight: 600;
        align-items: center;
        .anticon {
            font-size: 16px;
        }
        > em {
            font-style: normal;
        }
    }
    .anticon {
        font-size: 14px;
        font-weight: 500;
        margin-right: 4px;
        vertical-align: -0.2em;
    }
}
.coupon-status {
    display: flex;
    font-size: 12px;
    align-items: center;
    .anticon {
        margin-left: 2px;
    }
}
.promo-code {
    align-items: center;
    border: 1px solid var(--border-1);
    border-radius: 6px;
    cursor: pointer;
    padding: 2px;
    margin-left: 8px;
    min-width: 80px;
    background-color: var(--color-white);
    .promo-code-brand {
        font-size: 12px;
        font-weight: 600;
        margin-right: 2px;
        display: flex;
        align-items: center;
        margin-left: 4px;
        text-align: left;
        .icon {
            color: var(--dark-4);
            margin-left: 4px;
        }
    }
    &:hover .promo-code-brand .icon {
        color: var(--dark-2);
    }
    .promo-code-title{
        color: var(--dark-3);
        p {
            font-size: 12px;
            font-weight: 500;
            padding-left: 4px;
            color: var(--dark-3);
            letter-spacing: 0.3px;
            transform: scale(0.9);
            transform-origin: left;
        }
    }
}
.coupon-price {
    display: flex;
    justify-content: center;
    align-items: center;
    .full-price {
        color: var(--dark-4);
        margin-right: 16px;
        text-decoration: line-through;
    }
    .final-price {
        color: var(--dark-1);
        margin-right: 0;
    }
    .price-off {
        background: rgba(242, 85, 68, 0.1);
        border: 1px solid rgba(242, 85, 68, 0.4);
        border-radius: 4px;
        padding: 0 6px;
        color: rgba(235, 78, 61, 1);
        font-weight: 500;
    }
}

</style>