<template>
  <div class="activate-brand" ref="activateBrand">
      <artmisads-modal
        v-model:open="props.showActivateBrand"
        title="Connection Successful! Activate Your Brands Now"
        :closable="false"
        :width="600"
        :getContainer="() => activateBrand"
        :destroyOnClose="true"
        :maskClosable="false"
      >
      <a-spin :spinning="spinning">
        <p class="text-a">Activating your brands allows you to showcase them and their products to Publishers and Creators.</p>
        <div class="select-brand">
        <div class="checkbox">
          <a-checkbox
            :disabled="item.active"
            :checked="checkedList.find(i => i === item.value)"
            :key="item.id"
            v-for="item in brandOptions"
            @change="e => changeBrand(e, item.value)"
          >{{item.label}}</a-checkbox>
        </div>
        </div>
        <p class="text-b">By activating these brands, I acknowledge that my seller account will be enabled on Artemis Ads in the {{ country }}. Once activated, my account will be active in the following country: {{ country }}, and 1 of my available country slots will be used</p>
      </a-spin>
        <template #footer>
          <artmisads-button @click="onCancel">Cancel</artmisads-button>
          <artmisads-button :disabled="spinning || isAllActive" @click="handleOk" type="primary">Confirm</artmisads-button>
        </template>
      </artmisads-modal>
  </div>
</template>

<script setup>

  import { ref, computed, onBeforeMount, watch } from 'vue';
  import { COUNTRY_FULL_NAME } from "@/contants/layout";

  import { brandApi } from '@/server/index'

  const spinning = ref(false);
  const props = defineProps(['brandData','userId','country','setShowActivateBrand', 'showActivateBrand']);
  const activateBrand = ref(null);
  const checkedList = ref([]);
  const brandList = ref([]);
  const brandOptions = computed(() => {
    let brandOptionsData = [...brandList.value];
    if (brandOptionsData && brandOptionsData.length) {
      brandOptionsData = brandOptionsData.map(item =>( {
        label: item.amazonBrandName,
        value: item.id,
        active: item.status === 'active'
      }))
    }
    return brandOptionsData;
  });
  const country = computed(() => {
  return COUNTRY_FULL_NAME[props.country];
  });
  const isAllActive = ref(true);
  // const isAllActive = computed(() => {
  //   let active = !brandList.value.find(item => item.status === 'inactive');
  //   return active;
  // })

  const onCancel = () => {
    props.setShowActivateBrand(false);
  }
  watch(() => props.showActivateBrand, (newValue) => {
    if (newValue) {
      brandList.value=props.brandData;
      // getSellerBrands();
    }
  })
  // const getSellerBrands = async () => {
  //   spinning.value = true;
  //   const res = await brandApi.getSellerBrandsByUser({amazonUserId:props.userId});
  //   const { success, data } = res;
  //   if (success) {
  //     brandList.value = data;
  //     checkedList.value = data.filter(item => item.status === 'active').map(item => item.id);
  //   }
  //   spinning.value = false;
  // }

  const changeBrand = (e, value) => {
    isAllActive.value=false;
    const checked = e.target.checked;
    if (checked) {
      checkedList.value = [...checkedList.value, value];
    } else {
      checkedList.value = checkedList.value.filter(item => item !== value);
    }
  }

  const handleOk = async e => {
    // if (!checkedList.value.length) return;
    spinning.value = true;
    const params = checkedList.value.map(item => Number(item));
    const res = await brandApi.activateBrands(params);
    const { success } = res;
    if (success) {
      props.setShowActivateBrand(false);
    }
    spinning.value = false;
  };

</script>

<style lang="less" scoped>
  .activate-brand {
    .text-a {
      color: var(--dark-3);
      line-height: 20px;
    }
    .select-brand {
      margin-top: 24px;
      margin-bottom: 24px;
      .checkbox {
        display: flex;
        flex-wrap: wrap;
        :deep(.ant-checkbox-wrapper) {
          padding-left: 10px;
          display: flex;
          width: 176px;
          height: 40px;
          background: #FFFFFF;
          border: 1px solid #ada1cc;
          box-shadow: 0 1px 0 0 #1a16250d;
          border-radius: 8px;
          margin-inline: 6px;
          > span {
            align-self: center;
          }
          &:nth-child(n+4) {
            margin-top: 10px;
            margin-inline-start: unset;
          }
          &:first-of-type{
            margin-inline-start: unset;
          }
          &:nth-child(3n){
            margin-inline-end: unset;
          }
        }
      }
    }
    .text-b {
      color: var(--dark-2);
      line-height: 20px;
    }
  }
</style>