<template>
  <div :class="className">
    <a-table v-bind="props" @change="props.change">
      <template #bodyCell="props">
        <slot name="bodyCell" v-bind="props"></slot>
      </template>
      <template #customFilterDropdown="props">
        <slot name="customFilterDropdown" v-bind="props"></slot>
      </template>
      <template #customFilterIcon="props">
        <slot name="customFilterIcon" v-bind="props"></slot>
      </template>
      <template #summary="props">
        <slot name="summary" v-bind="props"></slot>
      </template>
      <template #headerCell="props">
        <slot name="headerCell" v-bind="props"></slot>
      </template>
      <template #emptyText v-if="props.noData">
        <slot name="emptyText" v-bind="props"></slot>
      </template> 
    </a-table>
    <div
      v-if="pagination"
      class="pagination"
      :style="
        props.align
          ? { 'justify-content': props.align }
          : { 'justify-content': 'flex-end' }
      "
    >
      <a-pagination
        v-model:current="props.pagination.current"
        v-bind="props.pagination"
        @change="handlePageChange"
      >
        <template #itemRender="{ type, originalElement }">
          <a v-if="type === 'prev'">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-jiantou-zuo-cu"></use>
            </svg>
          </a>
          <a v-else-if="type === 'next'">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-jiantou-you-cu"></use>
            </svg>
          </a>
          <component :is="originalElement" v-else></component>
        </template>
        <template #buildOptionText="props">
          <span>{{ props.value }} per page</span>
        </template>
      </a-pagination>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, computed } from "vue";
const props = defineProps([
  'tableLayout',
  "columns",
  "data-source",
  "pagination",
  "row-key",
  "row-selection",
  "rowClassName",
  "loading",
  "class",
  "scroll",
  'bordered',
  'change',
  "noData"
]);
const className = computed(() => {
  let tableClass = "artmisads-table";
  if (props.class) {
    tableClass += ` ${props.class}`;
  }
  return tableClass;
});
const handleTableChange = (pagination, filters, sorter) => {
  props.change(pagination, filters, sorter);
};
const handlePageChange = (page, pageSize) => {
  let pagination = { current: page, pageSize };
  props.change(pagination);
};
const handSizeChange = (current, pageSize) => {
  let pagination = { current, pageSize };
  props.change(pagination);
};
</script>
<style lang="less" scoped>
@import '../assets/less/pagination.less';

.artmisads-table:deep {
  padding-bottom: 16px;
  border-radius: 8px;
  .ant-table {
    width: 100%;
    color: var(--dark-1);
    .ant-table-thead {
      > tr {
        background: var(--table-head-bg);
        // box-shadow: inset 0 -1px 0 0 #CCC7D9;
        border-radius: 8px 8px 0 0;
        height: 40px;
        > th {
          height: 40px;
          background: var(--table-head-bg);
          border-bottom: 1px solid var(--border-1);
          font-weight: 500;
          color: var(--dark-2);
          letter-spacing: 0.4px;
          line-height: 40px;
          padding-top: unset;
          padding-bottom: unset;
        }
      }
    }
    tfoot.ant-table-summary {
      border-radius: 0 0 8px 8px;
      > tr {
        background: var(--table-head-bg);
        border-radius: 0 0 8px 8px;
        height: 40px;
      }
      > tr > td {
        height: 40px;
        background: var(--table-head-bg);
        border-top: 2px solid var(--border-1);
        font-weight: 600;
        color: var(--dark-2);
        letter-spacing: 0.4px;
        line-height: 40px;
        padding-top: unset;
        padding-bottom: unset;
        &:first-child {
          border-bottom-left-radius: 8px;
        }
        &:last-child {
          border-bottom-right-radius: 8px;
        }
      }
    }
    .ant-table-tbody {
      .ant-table-cell {
        color: var(--dark-1);
      }
      tr > td {
        border-top: unset;
        // border-top: 1px solid #E8E5ED !important;
        border-bottom: 1px solid var(--border-4);
      }
      > tr.ant-table-row:hover > td {
        background: var(--table-hover);
      }
    }
    .ant-table-row-selected {
      tr > td {
        background-color: rgba(114, 64, 255, 0.08);
        // &:hover{
        //   background: rgba(114, 64, 255, 0.14) !important;
        // }
      }
      // tr:hover{
      //   background: rgba(114, 64, 255, 0.14) !important;
      // }
      .ant-table-cell-row-hover {
        background:#EAE6FE !important;
        // background: rgba(114, 64, 255, 0.14) !important;
      }
    }
    .ant-checkbox-inner {
      border: 1px solid var(--border-1);
      box-shadow: 0 1px 0 0 rgba(15, 6, 41, 0.05);
    }
    .ant-checkbox-wrapper {
      &:hover,&:active {
        .ant-checkbox-inner {
          border: 1px solid var(--primary-border-2);
          box-shadow: var(--section-box-shadow-4);
        }
      }
    }
    .ant-checkbox-indeterminate{
      .ant-checkbox-inner{
        background-color:var(--primary);
        border: unset !important;
        box-shadow: unset !important;
        &::after{
          height: 2px;
          background-color: #fff;
        }
      }
      &:hover{
        .ant-checkbox-inner{
            background-color:var(--primary-hover);
        }
      }
      &:active{
        .ant-checkbox-inner{
            background-color:var(--primary-active);
        }
      }

    }
    .ant-checkbox-checked {
      
      .ant-checkbox-inner {
        border: unset !important;;
        // box-shadow: var(--section-box-shadow-4);
        background-color: var(--primary);
        &::after{
          transform: rotate(45deg) scale(1) translate(-45%, -55%);
        }
      }
      &:hover {
        .ant-checkbox-inner {
          border: unset !important;;
          box-shadow: unset !important;
          background-color: var(--primary);
        // background-color: var(--primary-border-2);
        }
      }
      &:after{
        display: none;
      }
    }
  }
  .ant-table-pagination {
    display: none;
  }
}

</style>
