<template>
  <div class="top-bar" ref="topBarRef">
    <div class="extra-info">
      <slot name="title"></slot>
      <slot name="status"></slot>
    </div>
    <div class="user-info">
      <div class="switch-country" v-if="userInfo.userType==='seller'" ref="countryRef">
        <artmisads-select
          class="country"
          v-model:value="amazonCountryCode"
          @change="onChangeCountry"
          :getPopupContainer="()=>$refs.countryRef"
        >
          <template v-for="option in COUNTRY_BASE" :key="option.countryCode"> 
            <a-select-option
            class="country-select-item"
            :value="option.countryCode" >
              <Icon :name="option.icon" class="icon"></Icon>
              <span>{{ option.host }}</span>
            </a-select-option>
          </template>
        </artmisads-select>
      </div>
      <a-dropdown :getPopupContainer="() => topBarRef">
        <svg class="icon" aria-hidden="true" style="fill: rgba(15, 6, 41, .5)">
          <use xlink:href="#icon-a-wode1"></use>
        </svg>
        <template #overlay>
          <div class="content">
            <div class="title-wrap">
              <a class="title" :href="linkUrl">
                <h3>
                  {{ store.userInfo?.userName || store.userInfo?.name || "" }}
                  <svg class="icon" aria-hidden="true">
                    <use xlink:href="#icon-jiantou-you-cu"></use>
                  </svg>
                </h3>
                <p>{{ store.userInfo?.email || "" }}</p>
              </a>
            </div>
            <artmisads-dropdown-menu>
              <a-menu-item key="1" @click="onSignOut">
                <div class="select-item">
                  <div class="sign-out">
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-tuichu"></use>
                    </svg>
                    <span>Sign Out</span>
                  </div>
                </div>
              </a-menu-item>
            </artmisads-dropdown-menu>
          </div>
        </template>
      </a-dropdown>
    </div>
  </div>
  
</template>

<script setup>
  import { computed, ref,watch } from 'vue';
  import { useRoute,useRouter } from 'vue-router';
  import jwt from 'jsonwebtoken';
  import { useUserInfoStore } from '../storeData/index';
  import { COUNTRY_BASE } from '../contants/common';
  import { signApi, publisherAdminApi,adminApi } from '../server/index'
  import Icon from './Icon.vue';
  import { message } from 'ant-design-vue';

  const route = useRoute();
  const router=useRouter();
  const store = useUserInfoStore();
  const topBarRef = ref();
  const countryRef=ref();
  const amazonCountryCode=ref();
  const userInfo = store.userInfo;
  const linkUrl = computed(() => {
    let link = '/seller/welcome';
    if (userInfo) {
      if (userInfo.status === 'PENDING_CHOOSE_TYPE') {
        link = '/profile';
      }
      if (userInfo.userType === 'publisher') {
        link = '/publisher/profile';
        if (userInfo.reviewStatus === 'Rejected') {
          link = '/profile';
        }
      }
      if (userInfo.userType === 'admin') {
        link = '/operator/users';
      }
    }
    return link;
  })
  watch(()=>userInfo.countryCode,()=>{
    amazonCountryCode.value=userInfo.countryCode;
  },{immediate:true})
  const onChangeCountry = async (e) => {
    store.setUserLoading(true);
    let res;
    try {
      res = await adminApi.switchCountry({
      newCountryCode:e
    });
    } catch (error) {
      store.setUserLoading(false);
    }
    
    if(res.success){
      const userToken = res.data.token;
      const artmisAdsUserData = jwt.decode(userToken);
      store.setUserInfo(artmisAdsUserData, userToken);
      // if(route.name !== 'sl-connection'){//切换站点更新当前页数据
        router.go(0);
      // }

    }else{
      message.error('please try again',1)
    }
    store.setUserLoading(false);
  }
  const onSignOut = async () => {
    let res;
    if (userInfo.userType === 'admin') {
      res = await publisherAdminApi.signOut();
    } else {
      res = await signApi.signOut();
    }
    const { success, error, code } = res;
    if (success || code === '3008' || code === '3009' || code === '3010') {
      store.setUserInfo('');
      if (userInfo.userType === 'admin') {
        location.href = '/operator/login';
      } else {
        location.href = '/sign-in';
      }
    } else {
      console.log(error);
    }
  }

</script>
<style lang="less" scoped>
@import '../assets/less/country-select.less';
  // @media screen and (min-width: 960px) {
    .top-bar {
      z-index: 3;
      width: 100%;
      height: 48px;
      min-height: 48px;
      background: var(--color-white);
      box-shadow: var(--section-box-shadow-5);
      display: flex;
      justify-content: space-between;
      padding-left: 27px;
      padding-right: 16px;
      position: relative;
      .content {
        box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),0 3px 6px -4px rgba(0, 0, 0, 0.12),0 9px 28px 8px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
        background: var(--color-white);
      }
      :deep(.ant-dropdown-menu) {
        box-shadow: none;
        padding: 8px;
        .ant-dropdown-menu-item {
          padding: 8px;
        }
      }
      > svg {
        width: 24px;
        height: 24px;
        align-self: center;
      }
      .user-info {
        flex: 1;
        display: flex;
        justify-content: right;
        > svg {
          width: 24px;
          height: 24px;
          align-self: center;
          cursor: pointer;
        }
      }
      .switch-country{
        align-content: center;
        margin-right: 18px;
        // .ant-select-selection-item,.ant-select-item-option-content{
        //   display: flex;
        //   align-items: center;
        //   .iconfont{
        //     margin-right: 4px;
        //     font-size: 18px;
        //   }
        // }
      }
      .title-wrap {
        padding: 8px;
        border-bottom: 1px solid #E8E5ED;
      }
      .title {
        display: block;
        width: 256px;
        padding: 8px 14px 4px 14px;
        
        border-radius: 8px;
        &:hover {
          background-color: var(--bg-gray-6);
        }
        > h3 {
          font-weight: 700;
          font-size: 14px;
          letter-spacing: 0.4px;
          line-height: 19px;
          color: var(--dark-2);
          svg {
            width: 13px;
            height: 13px;
            margin-left: 4px;
            fill: var(--dark-2);
            vertical-align: -1px;
          }
        }
        > p {
          margin-top: 2px;
          opacity: 0.5;
          font-weight: 400;
          font-size: 13px;
          color: #0E0528;
          letter-spacing: 0.4px;
          line-height: 18px;
        }
      }
      .sign-out {
        cursor: pointer;
        display: flex;
        > svg {
          width: 20px;
          height: 20px;
          margin-right: 12px;
          margin-left: 8px;
          align-self: center;
          fill: var(--dark-1);
        }
        > span {
          color: var(--dark-1);
          font-family: Sora, sans-serif;
          letter-spacing: 0.4px;
          line-height: 19px;
        }
      }
    }
.extra-info {
  display: flex;
  justify-content: left;
  align-items: center;
}

// @media screen and (max-width: 479px) {
//   .top-bar {
//     width: 100%;
//     height: 48px;
//     background: #ffffff;
//     box-shadow: 0 1px 0 0 #ece6f2;
//     display: flex;
//     justify-content: space-between;
//     padding-left: 27px;
//     padding-right: 24px;
//     .welcome {
//       display: none;
//     }
//     > svg {
//       width: 24px;
//       height: 24px;
//       align-self: center;
//     }
//     .user-info {
//       flex: 1;
//       display: flex;
//       justify-content: right;
//       > svg {
//         width: 29px;
//         height: 29px;
//         align-self: center;
//         cursor: pointer;
//       }
//     }
//     .title {
//       width: 256px;
//       padding: 16px 0 12px 23px;
//       > h3 {
//         opacity: 0.5;
//         font-family: Sora-Bold;
//         font-weight: 700;
//         font-size: 14px;
//         color: #0f0629;
//         letter-spacing: 0.4px;
//         line-height: 19px;
//       }
//       > p {
//         margin-top: 2px;
//         opacity: 0.5;
//         font-family: Sora, sans-serif;
//         font-weight: 400;
//         font-size: 13px;
//         color: #0e0528;
//         letter-spacing: 0.4px;
//         line-height: 18px;
//       }
//     }
//   }
// }
</style>
