import { defineStore } from 'pinia'
import { ref, computed, watch, reactive } from 'vue'

export const useSellerBrandStore = defineStore(
  'sellerBrand',
  () => {
    const params = ref({});
    const setParams= (obj={})=>{
      params.value=obj;
    }
    return {
      params,
      setParams
    }
  }
)
