<template>
  <div class="page-wrapper">
    <div class="header">
      <headerComponent pageType="home"/>
    </div>
    <main class="main-wrapper">
      <section data-w-id="35c9a473-353f-f6ef-d501-4ff7e6d6f055" class="section-hero">
        <div class="hero-visible-height">
          <div class="hero-visible-area">
            <div class="hero-blur-background"></div>
            <div class="hero-blur-background is-second"></div>
          </div>
        </div>
        <div class="padding-global">
          <div class="base-container">
            <div class="hero-main-split">
              <div class="hero-left">
                <div class="margin-bottom margin-xsmall">
                  <!-- <h1 
                    data-w-id="bfcd5c31-c18d-9a02-ce13-408abd8cfc94"
                    style="opacity:0;-webkit-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)"
                    class="hero-title">Your <span class="hero-text-span">Growth </span> <br/> Our <span class="hero-text-span">Expertise </span>
                  </h1> -->
                  <h1 
                    data-w-id="bfcd5c31-c18d-9a02-ce13-408abd8cfc94"
                    class="hero-title">Your <span class="hero-text-span">Growth </span> <br/> Our <span class="hero-text-span">Expertise </span>
                  </h1>
                </div>
                <div class="margin-bottom margin-xlarge">
                  <!-- <p data-w-id="bfcd5c31-c18d-9a02-ce13-408abd8cfcb5" style="opacity:0;-webkit-transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" class="hero-info">From clicks to sales, we’ve got you covered. ArtemisAds’ stellar multi-channel marketing services help elevate your brands, and boost your sales!</p> -->
                  <p data-w-id="bfcd5c31-c18d-9a02-ce13-408abd8cfcb5" class="hero-info">From clicks to sales, we’ve got you covered. ArtemisAds’ stellar multi-channel marketing services help elevate your brands, and boost your sales!</p>
                </div>
                <!-- <div data-w-id="b52593f0-1f46-1a91-266d-600527a5234d" style="opacity:0;-webkit-transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 20px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" class="button-wrapper">
                  <a data-w-id="ac7ac361-65a3-49a5-cc50-02cdd7ace0ef" href="#contact" class="button-primary w-button" style="padding: 18px 76px;">contact us</a>
                </div> -->
                <div data-w-id="b52593f0-1f46-1a91-266d-600527a5234d" class="button-wrapper">
                  <a data-w-id="ac7ac361-65a3-49a5-cc50-02cdd7ace0ef" href="#contact" class="button-primary w-button" style="padding: 18px 76px;">contact us</a>
                </div>
                <img src="../../assets/images/img/amazon@2x.png" loading="lazy" width="302" data-w-id="b52593f0-1f46-1a91-266d-600527a5234d" alt="amazon" class="amazon-logo-img">
              </div>
              <div class="hero-right">
                <div class="hero-right-images"><img src="../../assets/images/img/bg-1@2x.png" data-w-id="b52593f0-1f46-1a91-266d-600527a5234d" loading="lazy"  alt="Hero Main Image" class="hero-main-image"></div>
              </div>
            </div>
            <div class="spacer-huge"></div>
          </div>
        </div>
      </section>
      <section class="section-project" id="services">
        <div class="padding-global padding-section-regular">
          <div class="base-container">
            <div class="background-large-text">Project</div>
            <div class="margin-bottom margin-huge">
              <!-- <div data-w-id="2a0ef7eb-8a94-4b7e-b146-3d2198ab199a" style="-webkit-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0" class="product-header">
                <div class="product-header-left">
                  <h2 class="text-color-white opacity-90">Marketing Services <span class="title-span is-project">We Provide</span></h2>
                </div>
              </div> -->
              <div data-w-id="2a0ef7eb-8a94-4b7e-b146-3d2198ab199a"  class="product-header">
                <div class="product-header-left">
                  <h2 class="text-color-white opacity-90">Marketing Services <span class="title-span is-project">We Provide</span></h2>
                </div>
              </div>
            </div>
            <div class="w-dyn-list">
              <div role="list" class="project-wrap w-dyn-items">
                <!-- <div data-w-id="f7dc37e2-d7e7-b5cb-7ac1-bc10e3a092f6" style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1; transform-style: preserve-3d;" role="listitem" class="single-project w-dyn-item"> -->
                <div data-w-id="f7dc37e2-d7e7-b5cb-7ac1-bc10e3a092f6" role="listitem" class="single-project w-dyn-item">
                  <div class="project-content-wrapper">
                    <span class="text-color-white w-inline-block">
                      <h3 class="single-project-title">Create A Brand Funnel That Works</h3>
                    </span>
                    <p class="single-project-info">At ArtemisAds, we diagnose each brand we work with, provides brand leaders with actionable insights on where customers need help to transition from one stage to the next, ultimately moving as many customers as possible towards brand advocacy</p>
                  </div>
                    <a data-w-id="f0311e8e-6156-1357-8dd3-0aa81231192e" href="javascript:void();" class="project-thumb-wrapper w-inline-block">
                    <!-- <img
                      src="../../assets/images/img/bg-2@2x.png"
                      loading="lazy"
                      style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                      alt="Project Thumbnail" class="project-thumbnail"> -->
                    <img
                      src="../../assets/images/img/bg-2@2x.png"
                      loading="lazy"
                      alt="Project Thumbnail" class="project-thumbnail">
                    <div class="center-position">
                      <!-- <div
                        style="display: none; opacity: 0; will-change: transform; transform: translate3d(0vw, 0vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                        class="view-button-wrapper">
                        <p class="view-button-text"></p>
                      </div> -->
                      <div
                        class="view-button-wrapper">
                        <p class="view-button-text"></p>
                      </div>
                    </div>
                  </a>
                </div>
                <!-- <div data-w-id="f7dc37e2-d7e7-b5cb-7ac1-bc10e3a092f6"
                  style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1; transform-style: preserve-3d;"
                  role="listitem" class="single-project w-dyn-item"> -->
                <div data-w-id="f7dc37e2-d7e7-b5cb-7ac1-bc10e3a092f6"
                  role="listitem" class="single-project w-dyn-item">
                  <div class="project-content-wrapper">
                    <span class="text-color-white w-inline-block">
                      <h3 class="single-project-title">Amplify Your Brand’s Voice Cost Effectively </h3>
                    </span>
                    <p class="single-project-info">Social media provides an unparalleled opportunity for our clients to reach a large and diverse audience. ArtemisAds leverage its homegrown social media management tool to turn social media followers into brands’ loyal customers
                      <img src="../../assets/images/img/icon-1@2x.png" loading="lazy" class="project-thumbnail-icon"/>
                    </p>
                  </div>
                    <a data-w-id="f0311e8e-6156-1357-8dd3-0aa81231192e" href="javascript:void();"
                      class="project-thumb-wrapper w-inline-block">
                      <!-- <img
                        src="../../assets/images/img/bg-8@2x.png"
                        loading="lazy"
                        style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                        alt="Project Thumbnail" class="project-thumbnail"> -->
                        <img
                          src="../../assets/images/img/bg-8@2x.png"
                          loading="lazy"
                          alt="Project Thumbnail" class="project-thumbnail">
                        <div class="center-position">
                        <!-- <div
                          style="display: none; opacity: 0; will-change: transform; transform: translate3d(-0.00504vw, -0.00756vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                          class="view-button-wrapper">
                          <p class="view-button-text"></p>
                        </div> -->
                        <div
                          class="view-button-wrapper">
                          <p class="view-button-text"></p>
                        </div>
                      </div>
                    </a>
                  </div>
                <!-- <div data-w-id="f7dc37e2-d7e7-b5cb-7ac1-bc10e3a092f6"
                  style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1; transform-style: preserve-3d;"
                  role="listitem" class="single-project w-dyn-item"> -->
                <div data-w-id="f7dc37e2-d7e7-b5cb-7ac1-bc10e3a092f6"
                  role="listitem" class="single-project w-dyn-item">
                  <div class="project-content-wrapper">
                    <span class="text-color-white w-inline-block">
                      <h3 class="single-project-title">Make Affiliate Partnerships Your Competitive Edge</h3>
                    </span>
                    <p class="single-project-info">Over 8 years experience in Affiliate Marketing, can greatly help attract the best publishers, affiliates, and creators to promote your brands, Amazon listings and more
                      <img src="../../assets/images/img/icon-2.png" loading="lazy" class="project-thumbnail-icon"/>
                    </p>
                    
                  </div>
                    <a data-w-id="f0311e8e-6156-1357-8dd3-0aa81231192e" href="javascript:void();"
                    class="project-thumb-wrapper w-inline-block">
                      <!-- <img
                      src="../../assets/images/img/bg-4@2x.png"
                      loading="lazy"
                      style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                      alt="Project Thumbnail" class="project-thumbnail"> -->
                      <img
                        src="../../assets/images/img/bg-4@2x.png"
                        loading="lazy"
                        alt="Project Thumbnail" class="project-thumbnail">
                    <div class="center-position">
                      <!-- <div
                        style="display: none; opacity: 0; will-change: transform; transform: translate3d(0vw, 0vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                        class="view-button-wrapper">
                        <p class="view-button-text"></p>
                      </div> -->
                      <div
                        class="view-button-wrapper">
                        <p class="view-button-text"></p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            </div>
        </div>
      </section>
      <section class="section-cta">
        <div class="container-fluid">
          <div class="contact-cta-wrapper is-variation-one">
            <img src="../../assets/images/home/OBJECTS-1.png" loading="lazy" alt="CTA Shape One" class="cta-shape-one">
            <img src="../../assets/images/home/OBJECTS-2.png" loading="lazy" sizes="(max-width: 1279px) 100vw, (max-width: 1919px) 20vw, 552px" srcset="../../assets/images/home/OBJECTS-2-p-500.png 500w, images/OBJECTS-2.png 552w" alt="CTA Shape One" class="cta-shape-two">
          </div>
        </div>
        <div class="padding-global padding-section-regular">
          <div class="base-container">
            <div data-w-id="20bf4a6a-138f-19ea-5abf-046a10e5d2db" class="cta-wrapper">
              <h2 class="cta-large-text">Take Your Brands To The <span class="title-span is-cta">Next Level</span></h2>
              <a data-w-id="20bf4a6a-138f-19ea-5abf-046a10e5d2e0" href="#contact" class="talk-button w-inline-block">
                <div class="talk-button-background"></div>
                <div class="talk-button-content"><img src="../../assets/images/img/right-up.svg" loading="lazy" alt="Arrow Image">
                  <p class="talk-button-text">Let’s Talk</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section class="section-project" style="background-color: #fff;">
        <div class="padding-global padding-section-regular">
          <div class="base-container">
            <div class="w-dyn-list">
              <div role="list" class="project-wrap w-dyn-items">
                <!-- <div data-w-id="f7dc37e2-d7e7-b5cb-7ac1-bc10e3a092f6"
                style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1; transform-style: preserve-3d;"
                role="listitem" class="single-project w-dyn-item"> -->
                <div data-w-id="f7dc37e2-d7e7-b5cb-7ac1-bc10e3a092f6"
                role="listitem" class="single-project w-dyn-item">
                <div class="project-content-wrapper">
                  <span class="text-color-black w-inline-block">
                    <h3 class="single-project-title">Foster Genuine Connections between Your Brands and Consumers</h3>
                  </span>
                  <p class="text-color-black single-project-info">Influencer marketing is one of the best ways for ecommerce brands to get on their target audience's radar. ArtemisAds enables you to effectively invite, track and manage your partnership with millions of influencers.
                    <img src="../../assets/images/img/icon-3@2x.png" loading="lazy" class="project-thumbnail-icon"/>
                  </p>
                  
                </div><a 
                  data-w-id="f0311e8e-6156-1357-8dd3-0aa81231192e" 
                  href="javascript:void();"
                  class="project-thumb-wrapper w-inline-block">
                  <!-- <img
                    src="../../assets/images/img/bg-7@2x.png"
                    loading="lazy"
                    style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                    alt="Project Thumbnail" class="project-thumbnail"> -->
                  <img
                    src="../../assets/images/img/bg-7@2x.png"
                    loading="lazy"
                    alt="Project Thumbnail" class="project-thumbnail">
                  <div class="center-position">
                    <!-- <div
                      style="display: none; opacity: 0; will-change: transform; transform: translate3d(0vw, 0vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                      class="view-button-wrapper">
                      <p class="view-button-text"></p>
                    </div> -->
                    <div
                      class="view-button-wrapper">
                      <p class="view-button-text"></p>
                    </div>
                  </div>
                </a>
              </div>
                <!-- <div data-w-id="f7dc37e2-d7e7-b5cb-7ac1-bc10e3a092f6"
                style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1; transform-style: preserve-3d;"
                role="listitem" class="single-project w-dyn-item"> -->
                <div data-w-id="f7dc37e2-d7e7-b5cb-7ac1-bc10e3a092f6"
                role="listitem" class="single-project w-dyn-item">
                <div class="project-content-wrapper">
                  <span class="text-color-black w-inline-block">
                    <h3 class="single-project-title">Paid Search and Organic Search Work in Harmony</h3>
                  </span>
                  <p class="text-color-black single-project-info">ArtemisAds makes SEM and SEO work in harmony, which creates a powerful synergy, ensuring consistent brand presence, enhancing overall click- through rates, and maximizing your ROI across both channels.
                    <img src="../../assets/images/img/icon-4.png" loading="lazy" class="project-thumbnail-icon"/>
                  </p>
                  
                </div><a data-w-id="f0311e8e-6156-1357-8dd3-0aa81231192e" href="javascript:void();"
                  class="project-thumb-wrapper w-inline-block">
                  <!-- <img
                    src="../../assets/images/img/bg-5@2x.png"
                    loading="lazy"
                    style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                    alt="Project Thumbnail" class="project-thumbnail"> -->
                  <img
                    src="../../assets/images/img/bg-5@2x.png"
                    loading="lazy"
                    alt="Project Thumbnail" class="project-thumbnail">
                  <div class="center-position">
                    <!-- <div
                      style="display: none; opacity: 0; will-change: transform; transform: translate3d(0vw, 0vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                      class="view-button-wrapper">
                      <p class="view-button-text"></p>
                    </div> -->
                    <div
                      class="view-button-wrapper">
                      <p class="view-button-text"></p>
                    </div>
                  </div>
                </a>
              </div>
                <!-- <div data-w-id="f7dc37e2-d7e7-b5cb-7ac1-bc10e3a092f6"
                  style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); opacity: 1; transform-style: preserve-3d;"
                  role="listitem" class="single-project w-dyn-item"> -->
                <div data-w-id="f7dc37e2-d7e7-b5cb-7ac1-bc10e3a092f6"
                  role="listitem" class="single-project w-dyn-item">
                  <div class="project-content-wrapper">
                    <span class="text-color-black w-inline-block">
                      <h3 class="single-project-title">Direct Communication with Your Own Audience</h3>
                    </span>
                    <p class="text-color-black single-project-info">ArtemisAds works with almost all Email Service platforms in the market, communicating directly with your audience, delivering personalized messages right to their inbox, and consistently delivers one of the highest ROI
                      <img src="../../assets/images/img/icon-5.png" loading="lazy" class="project-thumbnail-icon"/>
                    </p>
                    
                  </div><a data-w-id="f0311e8e-6156-1357-8dd3-0aa81231192e" href="javascript:void();"
                    class="project-thumb-wrapper w-inline-block">
                    <!-- <img
                      src="../../assets/images/img/bg-6@2x.png"
                      loading="lazy"
                      style="transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                      alt="Project Thumbnail" class="project-thumbnail"> -->
                    <img
                      src="../../assets/images/img/bg-6@2x.png"
                      loading="lazy"
                      alt="Project Thumbnail" class="project-thumbnail">
                    <div class="center-position">
                      <!-- <div
                        style="display: none; opacity: 0; will-change: transform; transform: translate3d(0vw, 0vh, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg); transform-style: preserve-3d;"
                        class="view-button-wrapper">
                        <p class="view-button-text"></p>
                      </div> -->
                      <div
                        class="view-button-wrapper">
                        <p class="view-button-text"></p>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            </div>
        </div>
      </section>
      <section class="section-testimonial" style="background-color: #0f0629;">
        <div class="padding-global padding-section-regular">
          <div class="base-container">
            <div class="flex-and-center margin-bottom margin-huge">
              <div class="margin-bottom margin-xsmall">
                <h2 data-w-id="41e9edf7-fd82-03d2-a906-faa1b68aa53f" class="text-color-white text-align-center">The Invaluable Tool to Boost <br/><span class="title-span text-weight-semibold">Your Business</span></h2>
              </div>
              <p data-w-id="41e9edf7-fd82-03d2-a906-faa1b68aa543" class="testimonial-header-info text-color-white">ArtemisAds implement and manage analytics, and enable clients to gain greater insights in their business and enhance decision-making capabilities</p>
            </div>
            <div data-w-id="41e9edf7-fd82-03d2-a906-faa1b68aa545" class="client-testimonial-slider-wrap">
              <div data-delay="2000" data-animation="outin" class="client-testimonial-slider w-slider" data-autoplay="true" data-easing="ease" data-hide-arrows="false" data-disable-swipe="false" data-autoplay-limit="0" data-nav-spacing="3" data-duration="500" data-infinite="true">
                <div class="client-testimonial-mask w-slider-mask">
                  <div class="client-testimonial-slide w-slide">
                    <div class="client-testimonial-item">
                      <div class="testimonial-item-left" style="width: 100%;">
                        <div class="testimonial-client-thumb-wrapper">
                          <img src="../../assets/images/img/bg-3@2x.png" loading="lazy" alt="Testimonial Shape" class="testimonial-client-thumb-img"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section-customer" id="clients">
        <div class="base-container">
          <!-- <div data-w-id="6b1fe4bc-bb6d-b0f8-1a63-0060151a0ba3" style="-webkit-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0" class="customer-content-wrapper padding-section-regular section-work-process"> -->
          <div data-w-id="6b1fe4bc-bb6d-b0f8-1a63-0060151a0ba3"  class="customer-content-wrapper padding-section-regular section-work-process">
            <div class="padding-global">
              <div class="margin-bottom margin-huge">
                <h2 class="section-customer-title">Trusted by <span class="title-span text-weight-semibold">Hundreds </span><span class="text-color-primary text-weight-semibold"></span> of Brands like you</h2>
              </div>
            </div>
            <div class="customoer-logos">
              <!-- <div data-w-id="a1d79c27-c4ac-04ba-6b1b-842ea5e1af9a" style="-webkit-transform:translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" class="customer-logo-contain margin-bottom margin-medium"> -->
              <div data-w-id="a1d79c27-c4ac-04ba-6b1b-842ea5e1af9a" class="customer-logo-contain margin-bottom margin-medium">
                <div class="customer-logo-wrapper"><img src="../../assets/images/img/1@2x.png" loading="lazy" alt="Client Logo" class="customer-logo"></div>
                <div class="customer-logo-wrapper"><img src="../../assets/images/img/2@2x.png" loading="lazy" alt="Client Logo" class="customer-logo"></div>
                <div class="customer-logo-wrapper"><img src="../../assets/images/img/3@2x.png" loading="lazy" alt="Client Logo" class="customer-logo"></div>
                <div class="customer-logo-wrapper"><img src="../../assets/images/img/4@2x.png" loading="lazy" alt="Client Logo" class="customer-logo"></div>
                <div class="customer-logo-wrapper"><img src="../../assets/images/img/5@2x.png" loading="lazy" alt="Client Logo" class="customer-logo"></div>
                <div class="customer-logo-wrapper"><img src="../../assets/images/img/6@2x.png" loading="lazy" alt="Client Logo" class="customer-logo"></div>
                <div class="customer-logo-wrapper"><img src="../../assets/images/img/7@2x.png" loading="lazy" alt="Client Logo" class="customer-logo"></div>
                <div class="customer-logo-wrapper"><img src="../../assets/images/img/8@2x.png" loading="lazy" alt="Client Logo" class="customer-logo"></div>
              </div>
              <!-- <div data-w-id="fd6b8b2d-1970-2dcd-e13d-9e18a3b4635b" style="-webkit-transform:translate3d(-100%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(-100%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(-100%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(-100%, 0, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0)" class="customer-logo-contain"> -->
              <div data-w-id="fd6b8b2d-1970-2dcd-e13d-9e18a3b4635b" class="customer-logo-contain">
                <div class="customer-logo-wrapper"><img src="../../assets/images/img/9@2x.png" loading="lazy" alt="Client Logo" class="customer-logo"></div>
                <div class="customer-logo-wrapper"><img src="../../assets/images/img/10@2x.png" loading="lazy" alt="Client Logo" class="customer-logo"></div>
                <div class="customer-logo-wrapper"><img src="../../assets/images/img/11@2x.png" loading="lazy" alt="Client Logo" class="customer-logo"></div>
                <div class="customer-logo-wrapper"><img src="../../assets/images/img/12@2x.png" loading="lazy" alt="Client Logo" class="customer-logo"></div>
                <div class="customer-logo-wrapper"><img src="../../assets/images/img/13@2x.png" loading="lazy" alt="Client Logo" class="customer-logo"></div>
                <div class="customer-logo-wrapper"><img src="../../assets/images/img/14@2x.png" loading="lazy" alt="Client Logo" class="customer-logo"></div>
                <div class="customer-logo-wrapper"><img src="../../assets/images/img/15@2x.png" loading="lazy" alt="Client Logo" class="customer-logo"></div>
                <div class="customer-logo-wrapper"><img src="../../assets/images/img/16@2x.png" loading="lazy" alt="Client Logo" class="customer-logo"></div>
              </div>
              <div class="customer-logo-overlay"></div>
            </div>
          </div>
        </div>
      </section>
      <section class="story" id="about">
        <div class="padding-global padding-section-regular">
        <div class="section-wrap wrap slide-in" data-slide-in="float-down-up" style="--slide-step: 0">
          <h3 data-w-id="6b1fe4bc-bb6d-b0f8-1a63-0060151a0ba3"><span class="title-span is-contact-cta">About</span> Us</h3>
          <div class="content text-color-white" data-w-id="6b1fe4bc-bb6d-b0f8-1a63-0060151a0ba3">
            <p>Founded in 2016, ArtemisAds’ mission is to boost your brand’s visibility and sales in the U.S. market, using data-driven strategies and a personalized approach. We’re passionate about helping our clients succeed, and we pride ourselves on being a trusted partner in their growth journey. </p>
            <p>We leverage deep market insights and cutting-edge marketing technologies to drive brand success, focusing on strategies that deliver measurable results. Whether you’re a major brand or an Amazon seller, our expertise in Brand Strategy, Social Media Marketing, Affiliate marketing, Influencer marketing, SEO, SEM, Email marketing, Analytics, and more ensures your brand reaches its full potential. </p>
            <p>Let us help you connect with your customers and achieve your business goals with proven marketing strategies that work. Contact us today!</p>
          </div>
        </div>
        </div>
      </section>
      <section class="section-contact-cta" id="contact">
        <div class="container-fluid">
          <div class="contact-cta-wrapper" style="padding-bottom: 90px;">
            <img src="../../assets/images/home/OBJECTS-1.png" loading="lazy" alt="CTA Shape One" class="cta-shape-one">
            <img src="../../assets/images/home/OBJECTS-2.png" loading="lazy" sizes="(max-width: 1279px) 100vw, (max-width: 1919px) 20vw, 552px" srcset="../../assets/images/home/OBJECTS-2-p-500.png 500w, ../../assets/images/home/OBJECTS-2.png 552w" alt="CTA Shape One" class="cta-shape-two">
            <div class="flex-and-center">
              <div class="margin-bottom margin-xsmall">
                <h2 data-w-id="6b1fe4bc-bb6d-b0f8-1a63-0060151a0ba3" style="-webkit-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:1" class="heading-style-h3 text-color-white"><span class="title-span is-contact-cta">Contact</span> Us </h2>
              </div>
            </div>
            <!-- <div data-w-id="6b1fe4bc-bb6d-b0f8-1a63-0060151a0ba3" style="-webkit-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-moz-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);-ms-transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);transform:translate3d(0, 30px, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0) rotateZ(0) skew(0, 0);opacity:0" class="contact-us-form"> -->
            <div data-w-id="6b1fe4bc-bb6d-b0f8-1a63-0060151a0ba3" class="contact-us-form">
              <div class="form-block w-form">
                <div id="email-form" name="email-form" novalidate data-name="Email Form" class="form" data-wf-page-id="66d56698539c62c4c39f2589" data-wf-element-id="355ee6f0-3374-10ea-6954-0fc2798e7f29">
                  <div class="contact-form-input">
                    <label for="" class="input-field">Your name</label>
                    <input ref="nameRef"  class="text-field w-input" maxlength="256" name="name" data-name="Name" placeholder="Your Name" type="text" id="name">
                  </div>
                  <div class="contact-form-input">
                    <label for="email" class="input-field required">Your email</label>
                    <input ref="mailRef" class="text-field w-input" maxlength="256" name="email" data-name="Email" placeholder="Your Email" type="email" id="email" required>
                  </div>
                  <div id="w-node-_355ee6f0-3374-10ea-6954-0fc2798e7f36-c39f2589" class="contact-form-input">
                    <label for="Message" class="input-field required">How can we help you?</label>
                    <textarea ref="messageRef" placeholder="Write Massage" maxlength="5000" id="message" name="content" data-name="Message" class="text-message w-input" required></textarea>
                  </div>
                  <button ref="sendBtnRef" @click="sendMail" data-wait="Please wait..." id="w-node-_355ee6f0-3374-10ea-6954-0fc2798e7f3a-c39f2589" class="button-secondary w-button">Submit Now</button>
                </div>
                <div class="success-message w-form-done">
                  <div class="text-color-white">Thank you! Your submission has been received!</div>
                </div>
                <div class="w-form-fail">
                  <div>Oops! Something went wrong while submitting the form.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footerComponent pageType="home"/>
    </main>
    <a @click="scrollToEle('#app')" class="scrolll-top w-inline-block">
      <div></div>
    </a>
  </div>
</template>
<script setup>
  import { onMounted, ref } from 'vue';
  import footerComponent from './footerComponent.vue';
  import headerComponent from './headerComponent.vue';
  import { useRoute } from 'vue-router';
  import { homeApi } from '../../server/index'

  const nameRef = ref();
  const messageRef = ref();
  const mailRef = ref();
  const sendBtnRef = ref();
  const route = useRoute();


  function scrollToEle (ele) {
    document.querySelector(ele).scrollIntoView({
      behavior: 'smooth'
    })
  }

  const sendMail = async () => {
    const name = nameRef.value.value;
    const content = messageRef.value.value;
    const email = mailRef.value.value;
    if (name && content && email) {
      sendBtnRef.value.textContent = 'Please wait...';
      const params = {
        name, content, email,
        // sendToEmail: 'lijing.li@mail.dealmoon.com'
      };
      const res = await homeApi.contactUs(params);
      if (res.success) {
        sendBtnRef.value.textContent = 'Successfully';
      }

      const timer = setTimeout(() => {
        sendBtnRef.value.textContent = 'Submit Now';
        clearTimeout(timer);
      }, 2000)

    }
  }

  onMounted(() => {
    const point = route.query.point;
    if (point) {
      scrollToEle(`#${point}`)
    }
  })

</script>
<style lang="css" scoped>
  @import './index.css';
</style>
<style lang="less" scoped>
  .page-wrapper {
    min-width: 1440px !important;
    overflow: scroll;
    color: var(--dark--dark-3);
    font-family: Sora, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
  }
  .header {
    position: absolute;
    left: 0;
    right: 0;
    top: 10px;
    z-index: 1;
  }
</style>