<template>
  <div
    class="deactive-brand"
    ref="mod"
    v-show="configs.modalType == 'deactive'"
  >
    <template>
      <artmisads-modal
        title="Are you sure you want to Deactivate this Brand?"
        v-model:open="configs.open"
        @ok="onOk"
        @cancel="onModalCancel"
        style="width: 600px"
        :getContainer="() => $refs.mod"
        okText="Confirm"
      >
        <div class="deactive">
          Deactivating a brand on the Artemis Ads Platform will deactivate all
          associated products, archive active publisher/creator links, and make
          the brand and its products unavailable to publishers and creators.
          Please note that this action does NOT deactivate the brand on Amazon.
        </div>
      </artmisads-modal>
    </template>
  </div>
  <div ref="act" v-show="configs.modalType == 'active'">
    <template>
      <artmisads-modal
        v-model:open="configs.open"
        @ok="onOk"
        @cancel="onModalCancel"
        :getContainer="() => $refs.act"
        style="width: 600px"
        title="Are you sure you want to Activate this Brand?"
        okText="Confirm"
      >
        <div class="active-brand">
          <div class="title-ext">
            Activating the brand will allow you to enable products under this
            brand and list them in the Publisher/Creator Marketplace.
          </div>
          <div class="brand-name-row">
            <div class="brand-name">
              {{ item.amazonBrandName }}
            </div>
          </div>
          <div class="content">
            I acknowledge that activating this brand will enable my seller
            account in <span class="login-country">{{ country }}.</span> Once
            activated, I will be activate in the following countries:
            {{ country }} and will have used 1 of my available 1 countries
          </div>
          <p class="tip">
            <i class="iconfont icon-a-warring-tianchong3" />IMPORTANT:
            Activating a brand does not equal activating products within this
            brand. Products must be activated to be shown to publishers. You can
            activate certain products or all products as you wish on the
            products management page.
          </p>
        </div>
      </artmisads-modal>
    </template>
  </div>
  <div
    class="update-brand-profile"
    ref="profile"
    v-show="configs.modalType == 'updateProfile'"
  >
    <template>
      <artmisads-modal
        v-model:open="configs.open"
        :getContainer="() => $refs.profile"
        @ok="onOk"
        title="Update Brand Profile"
        style="width: 600px"
      >
        <template #footer>
          <div class="footer-btn">
            <artmisads-button
              key="back"
              type="primary"
              @click="onOk"
              :disabled="isDisabled"
              >Save</artmisads-button
            >
          </div>
        </template>
        <div class="content">
          <a-form
            :label-col="{ style: { width: '60px' } }"
            :wrapper-col="{ span: 20 }"
            layout="horizontal"
            style="color: aquamarine"
          >
            <a-form-item label="Image">
              <UploadImage
                :configs="{
                  maxCount: 1,
                  maxSize: 2,
                  minWidth: 600,
                  minHeight: 600,
                  spin: true,
                }"
                @upload-file="uploadImg"
                #content
              >
                <div class="upload_part">
                  <div class="upload">
                    <div v-if="brandProfile.logoImageUrl" class="upload-img">
                      <img :src="imgCache(brandProfile.logoImageUrl)" alt="" />
                    </div>
                    <div v-else class="upload-icon">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-shangchuan-xi"></use>
                      </svg>
                    </div>
                  </div>
                  <artmisads-select-button
                    size="small"
                    v-if="brandProfile.logoImageUrl"
                    >Replace Image</artmisads-select-button
                  >
                </div>
              </UploadImage>
              <div class="suggested">Suggested image size 600px * 600px</div>
            </a-form-item>
            <a-form-item label="Bio">
              <a-textarea
                :rows="4"
                maxLength="1000"
                placeholder="Please enter..."
                v-model:value="brandProfile.biography"
                @change="onBios"
                @blur="onValiadBios"
              />
              <span v-show="biosValid" style="color: #eb4e3d"
                >Maximum word count 1000</span
              >
            </a-form-item>
          </a-form>
        </div>
      </artmisads-modal>
    </template>
  </div>
</template>
<script setup name="tableModal">
import { ref, reactive, toRefs, computed, watch, watchEffect } from 'vue'
import { message } from 'ant-design-vue'
import { imgCache } from '~/utils'
import {
  UpOutlined,
  DownOutlined,
  PlusOutlined,
  PauseOutlined,
  CloudUploadOutlined,
} from '@ant-design/icons-vue'
import { useUserInfoStore } from '@/storeData'
import { COUNTRY_FULL_NAME } from '@/contants/layout'
import { useSystemStore } from '@/storeData/system'
import UploadImage from '@/components/uploadImage.vue'

const { configs, item } = defineProps({
  configs: {
    type: Object,
    default: () => {},
  },
  item: {
    type: Object,
    default: () => {},
  },
})
const emit = defineEmits(['cancel', 'ok'])
const user = useUserInfoStore()
const mod = ref()
const profile = ref()
const act = ref()
let nameChecked = ref()
const brandProfile = reactive({})
const isDisabled = ref(true) //按钮禁用
let biosValid = ref(false)
const country = computed(() => {
  return COUNTRY_FULL_NAME[user.userInfo.countryCode]
})
watch([() => configs.modalType], (newVal) => {
  if (newVal == 'updateProfile') {
    Object.assign(brandProfile, item)
  }
})
const onModalCancel = (e) => {
  emit('cancel')
}
const uploadImg = (res) => {
  try {
    if (res.data.url !== item.logoImageUrl) {
      isDisabled.value = false
    }
    brandProfile.logoImageUrl = res.data.url
  } catch (error) {
    console.log('upload-false')
  }
}
const onBios = (e) => {
  biosValid.value = false
  if (e.target.value !== item.biography) {
    isDisabled.value = false
  }
}
const onValiadBios = (e) => {
  // if (item.biography.length >= 1000) {
  if (brandProfile.biography && brandProfile.biography.length >= 1000) {
    biosValid.value = true
  }
}
const onOk = (e) => {
  let res = { ...item }
  // if (configs.modalType == "setCommission") {
  //   res.commissionRate = setCommission.commission;
  //   if (!res.commissionRate) {
  //     message.error("please input commission!", 1);
  //     return;
  //   }
  // }
  if (configs.modalType == 'updateProfile') {
    isDisabled.value = true
    if (biosValid.value) {
      return
    }
    res = { ...res, ...brandProfile }
  }
  emit('ok', res)
}
</script>
<style lang="less" scoped>
@space: 16px;
@second-font: {
  opacity: 0.5;
  font-family: Sora, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--dark-1);
  letter-spacing: 0.4px;
  line-height: 22px;
};
.title-ext {
  @second-font();
  margin-bottom: @space;
}
.modal-title {
  border-radius: 8px 8px 0 0;
  font-family: Sora, sans-serif;
  font-weight: 500;
  font-size: 20px;
  color: var(--dark-1);
  letter-spacing: 0.5px;
  line-height: 27px;
  margin-right: 16px;
}

.footer {
  display: flex;
  justify-content: flex-start;
}
.deactive-brand {
  .deactive {
    font-weight: 400;
    font-size: 14px;
    color: var(--dark-1);
    letter-spacing: 0.4px;
    line-height: 22px;
    // padding: 24px 17px 24px 24px;
  }
}

.active-brand {
  // padding: 24px 17px 24px 24px;
  .brand-name-row {
    margin-bottom: 8px;
    display: flex;
    flex-wrap: wrap;
  }
  .brand-name {
    font-family: Sora, sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: var(--dark-1);
    letter-spacing: 0.4px;
    line-height: 21px;
  }
  .content {
    font-family: Sora, sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: var(--dark-1);
    letter-spacing: 0.4px;
    line-height: 22px;
  }
  // .login-country{
  //   color: #999;
  // }
}
.update-brand-profile {
  :deep(label) {
    @second-font();
    opacity: 0.7;
    font-weight: 500;
    line-height: 20px;
  }
  .upload_part {
    display: flex;
    align-items: flex-end;
  }
  .upload {
    width: 120px;
    height: 120px;
    background: var(--big-bg-1);
    border-radius: 8px;
    margin-right: 8px;
    .upload-icon {
      border: 2px dashed var(--border-1);
      border-radius: 8px;
      @second-font();
      width: inherit;
      height: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 36px;
      &:hover {
        background-color: var(--bg-gray-2);
        border-color: var(--bg-gray-7);
      }
    }
    .upload-img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
  .suggested {
    @second-font();
    letter-spacing: 0.3px;
    line-height: 18px;
    margin-top: 4px;
  }
}
.tip {
  padding: 8px;
  display: flex;
  margin-top: 16px;
  border-radius: 8px;
  color: var(--dark-2);
  background: var(--yellow-3);
  border: 1px solid var(--yellow-8);
  > .iconfont {
    flex: 1;
    height: 16px;
    font-size: 16px;
    min-width: 16px;
    margin-top: 2px;
    margin-right: 8px;
    color: var(--yellow-7);
    &:before {
      display: block;
      margin-top: -4px;
    }
  }
}
</style>
