<template>
  <div ref="chartRef"></div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";

import { RESPORTS_TYPE_MAP } from '~/contants/reports';
import * as echarts from "echarts/core";
import { LineChart, BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";

import moment from "moment";

const props = defineProps({
  dataList: { default: [] },
  width: { default: 840 },
  height: { default: 400 },
  isPercent: { default: false },
  showDate: { default: true },
  showYName: { default: 'commission' },
  showXName: { default: 'startDate' },
  lineColor: { default: '#835CF2'},
  lineBgOffsetZero: { default: 'rgb(131, 92, 242, 0)'},
  lineBgOffsetTop: { default: 'rgb(131, 92, 242, .3)'},
  chartType: { default: 'line'},
  showCurrent: { default: true },
  startTimestamp: { default: moment().subtract(15, 'day').valueOf() },
  onClickBarChart: { default: null },
  endTimestamp: { default: moment().valueOf() },
  metricName: { default: '' },
  selectDaysNum: { default: 'days15' } //days15, days15to30, days31to90, days91to365
});

const chartRef = ref();
let commissionHistoryChart = null;

onMounted(() => {
  echarts.use([
    BarChart,
    LineChart,
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    CanvasRenderer,
  ]);
});

const setChart = () => {
  let xAxis, yAxis, chartOptions, showYName = props.showYName, showXName = props.showXName;

  // 柱状图需要交换xy轴的数据
  if (props.chartType === 'bar') {
    const tempName = showXName;
    showXName = showYName;
    showYName = tempName;
  }

  let scale = 0, xData = [], yData = [];
  const intervalDays = moment(props.endTimestamp).diff(moment(props.startTimestamp), 'days');
  // 15天以内刻度1、16-30天，刻度2,31-90天，刻度5，91-365,刻度15
  if (intervalDays <= 15 ) {
    scale = 0
  } else if (intervalDays <= 31) {
    scale = 1
  } else if (intervalDays <= 90) {
    scale = 4;
  } else if (intervalDays <= 365) {
    scale = 14;
  }
  if (props.showDate) {
    xData = [moment(props.startTimestamp).format("MMM DD")];
    let tempTime = props.startTimestamp;
    for (let i = 0; i < intervalDays; i++) {
      tempTime = moment(tempTime).add(1, 'days').valueOf();
      if (tempTime > props.endTimestamp) {
        tempTime = props.endTimestamp;
      }
      const itemDate = moment(tempTime).format("MMM DD");
      xData = [...xData, itemDate];
    }
    if (props.dataList.length) {
      const originalData =  props.dataList.map(item => {
        item.timeDate =  moment(item.reportDateTimestamp).format("MMM DD");
        return item;
      })
      xData.forEach(item => {
        const hasData = originalData.find(j => j.timeDate === item);
        if (hasData) {
          yData = [...yData, hasData[showYName]];
        } else {
          yData = [...yData, null]
        }
      })
    }

  } else {
    if (props.dataList.length) {
      xData = props.dataList.map(item => {
        let itemData = '';
        if (moment(item[showXName]).isValid()) {
          itemData = moment(item[showXName]).format("MMM DD")
        } else {
          itemData = item[showXName];
        }
        return itemData;
      }).reverse();

      yData = props.dataList.map((item) => item[showYName]).reverse();
      yData.push(props.dataList[0][showYName]);

    }
  }

  xAxis = {
    type: "category",
    data: xData,
    axisLine: {
      lineStyle: {
        color: "rgba(15, 6, 41, .5)",
      },
    },
    axisLabel: {
      fontSize: 10,
      interval: props.chartType === 'bar' ? 0 :scale,
    },
  };

  yAxis = {
    type: "value",
    axisLabel: {
      formatter: props.isPercent ? "{value}%" : '{value}',
    },
    axisLine: {
      lineStyle: {
        color: "rgba(15, 6, 41, .7)",
      },
    },
  };

  // 柱状图需要交换xy轴的数据
  if (props.chartType === 'bar') {
    const tempAxis = xAxis;
    xAxis = yAxis;
    yAxis = tempAxis;
  }

  chartOptions = {
    grid: {
      left: "1%",
      right:  props.chartType === 'bar' ? "2%" : "1%",
      bottom: "5%",
      top: "5%",
      containLabel: true,
    },
    tooltip: {
      trigger: "axis",
      backgroundColor: "#fff",
      borderColor: "#fff",
      extraCssText: "box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);",
      formatter: (params) => {
        const { name, value } = params[0];
        const toolTipName = RESPORTS_TYPE_MAP[props.metricName].name;
        const res = `<div class="chart-tag">
            <span class="head-text">${name}</span>
            <div class="bottom-cont">
              <span class="tag-name">
                <span class="circle ${props.metricName}"></span>
                ${toolTipName}
              </span>
              <span class="value">${value || '--'}</span>
            </div>
          </div>`
        return res;
      }
    },
    xAxis: xAxis,
    yAxis: yAxis,
    series: [
      {
        connectNulls: true,
        data: yData,
        type: props.chartType,
        color: props.lineColor,
        barWidth: '16px',
        label: {
          show: props.chartType === 'bar',
          position: 'right'
        },
        lineStyle: {
          width: 2,
        },
        itemStyle: {
          borderRadius: [0, 4, 4, 0]
        },
        areaStyle: {
          color: {
            type: "linear",
            x: 0,
            y: 0,
            x2: 0,
            y2: 1,
            colorStops: [
              {
                offset: 0,
                color: props.lineBgOffsetTop,// 100% 处的颜色
              },
              {
                offset: 1,
                color: props.lineBgOffsetZero ,  // 0% 处的颜色 
              },
            ],
            global: false, // 缺省为 false
          },
        },
      },
    ],
  };

  commissionHistoryChart.setOption(chartOptions);
  if (props.onClickBarChart) {
    commissionHistoryChart.off().on("click", params => {
      props.onClickBarChart(params.name);
  });
  }
  
};

watch([props, chartRef], async () => {
  if (chartRef) {
    if (!commissionHistoryChart) {
      commissionHistoryChart = echarts.init(chartRef.value, null, {
        width: props.width < 1123 ? 1123 : props.width,
        height: props.height
      });
    }
    setChart();
  }
});

watch(() => props.width, async () => {
  if (chartRef && commissionHistoryChart) {
    commissionHistoryChart.resize({
      width: props.width < 1123 ? 1123 : props.width,
      height: props.height
    });
  }
});
</script>

<style lang="less">
.chart-tag {
  .head-text {
    font-weight: 400;
    font-size: 12px;
    color: var(--dark-3);
    letter-spacing: 0.3px;
    line-height: 16px;
  }
  .bottom-cont {
    display: flex;
    color: var(--dark-1);
    font-size: 13px;
    margin-top: 6px;
    .tag-name {
      display: flex;
      font-weight: 400;
      .circle {
        width: 10px;
        height: 10px;
        border-radius: 5px;
        margin-right: 4px;
        align-self: center;
        &.clicks {
          background: var(--tag-bg-1);
        }
        &.detailPageViews{
        background: var(--tag-bg-2);
        }
        &.addToCart{
          background: var(--tag-bg-3);
        }
        &.conversions{
          background: var(--tag-bg-4);
        }
        &.salesAmount{
          background: var(--tag-bg-5);
        }
        &.discountedSalesAmount{
          background: var(--tag-bg-8);
        }
        &.commissionAndFeeAmount{
          background: var(--tag-bg-6);
        }
        &.bonusAmount{
          background: var(--tag-bg-7);
        }
      }
    }
    .value {
      margin-left: 27px;
      font-weight: 500;
    }
  }
}
</style>