export const PRICE = [
  { label: 'All' },
  { label: 'Under %s10', max: 10 },
  { label: '%s10 to %s25', min: 10, max: 25 },
  { label: '%s25 to %s50', min: 25, max: 50 },
  { label: '%s50 to %s100', min: 50, max: 100 },
  { label: '%s100 to %s200', min: 100, max: 200 },
  { label: '%s200 & Above', min: 200 }
]

export const SORT = ['Best Sellers', 'Newly Added', 'Commission', 'Price']

export const MARKET_PLACE_MAP = {
  'US': {
    label: 'amazon.com',
    icon: '#icon-guoqi-us'
  },
  'UK': {
    label: 'amazon.co.uk',
    icon: '#icon-guoqi-uk'
  },
  'DE': {
    label: 'amazon.de',
    icon: '#icon-guoqi-de'
  },
  'CA': {
    label: 'amazon.ca',
    icon: '#icon-guoqi-ca'
  },
  'FR': {
    label: 'amazon.fr',
    icon: '#icon-guoqi-fr'
  },
}

export const PLATFORM_LIST = ['Tiktok', 'Instagram', 'Facebook', 'YouTube', 'Pinterest', 'Red', 'WeChat', 'Weibo', 'LinkedIn', 'X']
