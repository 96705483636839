import client from '../client';
import { exportFile } from '../requestDownloadFile';

function buildQuery(data) {
  return Object.keys(data).map(key => {
    return `${key}=${data[key]}`
  }).join('&')
}

export default {
  // products
  getFirstLevelCategory: data => {
    return client.get('category/first-level/list?' + buildQuery(data)).then(res => res.data);
  },
  getBrandsList: data => {
    return client.get('brand/list?' + buildQuery(data)).then(res => res.data);
  },
  getProductList: data => {
    return client.get('publisher/product/find/list?' + buildQuery(data)).then(res => res.data);
  },
  getCategoryList: data => {
    return client.get('category/aggregation/list?' + buildQuery(data)).then(res => res.data);
  },
  getCategoryTree: (id, countryCode) => {
    return client.get(`category/${id}/tree/list?countryCode=${countryCode}`).then(res => res.data);
  },
  // brands
  searchBrandsList: data => {
    return client.get('publisher/brand/find/list?' + buildQuery(data)).then(res => res.data);
  },
  getBrandDetail: data => {//获取品牌信息
    return client.get(`publisher/brand/detail/${data.brandId}`).then(res => res.data);
  },
  getBrandProducts: data => {
    return client.get(`publisher/product/list/by-brand?` + buildQuery(data)).then(res => res.data);
  },
  // links
  getLinkList: data => {
    return client.get('product/tracking/link/list?' + buildQuery(data)).then(res => res.data);
  },
  exportLinks: (fileName, data) => {
    return exportFile('product/tracking/link/list/export', data, fileName)
  },
  getBrandsSuggestion: keyword => {
    return client.get('product/tracking/link/aggregation/brands?searchText=' + keyword).then(res => res.data)
  },
}