<template>
  <div class="report-notice" v-if="showNotice">
    <div class="content">
      <span>
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-a-warring-tianchong3"></use>
        </svg>
      </span>
      <p>All reports have been updated as of January 1, 2025. If you have any questions regarding the data prior to this date, please feel free to contact us.</p>
      <svg @click="closeNotcie" class="icon close-icon" aria-hidden="true">
        <use xlink:href="#icon-close-cu"></use>
      </svg>
    </div>
  </div>
</template>
<script setup>

  import moment from 'moment';
  import { onMounted, onBeforeMount, ref } from 'vue';

  const showNotice = ref(false);
  const dateNow = moment().format('YYYY-MM-DD');

  const closeNotcie = () => {
    showNotice.value = false;
    localStorage.setItem('artemisadsReportsShowNoticeDate', dateNow);
  }

  onMounted(() => {
    const artemisadsReportsShowNoticeDate = localStorage.getItem('artemisadsReportsShowNoticeDate');
    if (!artemisadsReportsShowNoticeDate) {
      showNotice.value = true;
    } else if (artemisadsReportsShowNoticeDate !== dateNow) {
      showNotice.value = true;
    }
  })

</script>

<style lang="less" scoped>
  .report-notice {
    padding-bottom: 16px;
    .content {
      position: relative;
      display: flex;
      padding: 12px 16px;
      background-color: var(--yellow-bg);
      border-radius: 8px;
      border: 2px solid var(--yellow-5);
      > span {
        display: flex;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        box-shadow: 0 0 0 3px var(--yellow-icon-bg);
        justify-content: center;
        margin-right: 8px;
        svg {
          width: 22px;
          min-width: 22px;
          height: 22px;
          fill: var(--yellow-7);
          align-self: center;
        }
      }
      > p {
        font-weight: 400;
        font-size: 14px;
        color: var(--dark-1);
        line-height: 19px;
      }
      .close-icon {
        position: absolute;
        top: 12px;
        right: 12px;
        width: 20px;
        height: 20px;
        fill: var(--dark-4);
        cursor: pointer;
      }
    }
  }
</style>