<template>
  <a-space>
    <a-space-compact>
      <a-button  @click="onClick('toFirst')" :disabled="status.toFirst">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-jiantou-zuo-shuang-cu"></use>
        </svg>
      </a-button>
      <a-button  @click="onClick('pre')" :disabled="status.pre">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-jiantou-zuo-cu"></use>
        </svg>
      </a-button>
      <a-button  @click="onClick('next')" :disabled="status.next">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-jiantou-you-cu"></use>
        </svg>
      </a-button>
      <a-button  @click="onClick('toLast')" :disabled="status.toLast">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-jiantou-you-shuang-cu"></use>
        </svg>
      </a-button>
  
    </a-space-compact>
  </a-space>
</template>
<script setup name="ButtonGroups">
import { DoubleLeftOutlined,DoubleRightOutlined,LeftOutlined,RightOutlined} from '@ant-design/icons-vue';
import { reactive,watch } from 'vue';
const {pagination,tableData}=defineProps(['pagination','tableData'])
let status=reactive({
  toFirst:true,
  pre:true,
  next:false,
  toLast:false
})
watch(()=>tableData,()=>{
  const {total,size,current} =pagination;
  let endPage= Math.ceil(total / size);
  if(total<=size){//只有一页
    status.pre=true;
    status.toFirst=true;
    status.next=true;
    status.toLast=true;
    return
  }
  if(current==endPage){
    status.next=true;
    status.toLast=true;
    status.pre=false;
    status.toFirst=false;
  }else if(current==1){
    status.pre=true;
    status.toFirst=true;
    status.next=false;
    status.toLast=false;
  }else{
    status.pre=false;
    status.toFirst=false;
    status.next=false;
    status.toLast=false;
  }
})
const emit =defineEmits(['pageChange']);
const onClick=(direction)=>{
  const {total,size,current} =pagination;
  let endPage= Math.ceil(total / size);
  if((direction == 'toFirst' || direction == 'pre') && current == 1){
    return
  }
  if((direction == 'next' || direction == 'toLast') && current == endPage){
    return
  }
  let currPage = current;
  switch(direction){
    case 'pre':
      status.pre=true;
      currPage--;
        break;
    case 'next':
      status.next=true;
      currPage++;
        break;
    case 'toLast':
      status.toLast=true;
      currPage = endPage ;
        break;
    default:
      status.toFirst=true;
      currPage = 1;
        break;
  }
  console.log('page-groups',currPage,direction);
  emit('pageChange',currPage)
}
</script>
<style lang="less" scoped>
.ant-btn-compact-item{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: unset;
  width: 36px;
  height: 32px;
  background-color: #ffffff ;
  border: 1px solid var(--border-1);
  color: var(--dark-2);
  box-shadow: 0 1px 0 0 var(--box-shadow-2);
  &:disabled{
    background:var(--bg-gray-1);
    color: var(--dark-4);
    &:hover{
      color: var(--dark-4);
      background: var(--bg-gray-1);
      border: 1px solid var(--border-1);
    }
  }
  &:hover{
    background-color:var(--bg-gray-1) ;
    border: 1px solid var(--bg-gray-7);
    color: var(--dark-1);
  }
  &:active{
    background-color: var(--bg-gray-2);
    border: 1px solid var(--bg-gray-7);
    color: var(--dark-1);
  }
}
</style>
 