<template>
  <div class="admin-home">
    <div>
      <left-menu
        :menus="items"
        :bottomEntries="bottomEntries"
        userType="seller"
      />
    </div>
    <div class="wrap">
      <topBar />
      <div class="content">
        <div class="page">
          <router-view />
        </div>
        <div class="footer">
          <foot></foot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="jsx">
import foot from './leftMenu/foot.vue'
import topBar from '~/components/topBar.vue'
import leftMenu from '~/components/LeftMenu.vue'
// import leftMenu from './leftMenu/index.vue'
import { h, onMounted, ref, watch, reactive } from 'vue'

const pageMessage = ref({
  names: ['Brands', 'name'],
  title: 'Your Brands',
  phrase:
    'Review and update your creator-facing details and logo for each brand',
})

const items = reactive([
  {
    key: '/seller/brand/index',
    icon: () =>
      h('svg', { class: 'icon', 'aria-hidden': 'true' }, [
        h('use', { 'xlink:href': '#icon-pinpai' }),
      ]),
    routeName: 'brand-index',
    title: '',
    label: 'Brands',
  },
  {
    key: '/seller/product',
    icon: () =>
      h('svg', { class: 'icon', 'aria-hidden': 'true' }, [
        h('use', { 'xlink:href': '#icon-shangpin' }),
      ]),
    routeName: 'product',
    title: '',
    label: 'Products',
  },
  {
    key: '/seller/reports',
    icon:()=> h('svg', { class: 'icon', 'aria-hidden': 'true' }, 
    [ h('use', { 'xlink:href': '#icon-chart' })]),
    title: '',
    label: <div class="reports-beta">Reports <span>BETA</span></div>,
    children: [
      {
        routeName: 'reports-performance',
        key: '/seller/reports/performance',
        preKey: '/seller/reports',
        label: 'Performance',
        title: 'Performance',
      },
      {
        preKey: '/seller/reports',
        routeName: 'reports-product',
        key: '/seller/reports/product',
        label: 'Product',
        title: 'Product',
      },
      {
        preKey: '/seller/reports',
        routeName: 'reports-brand',
        key: '/seller/reports/brand',
        label: 'Brand',
        title: 'Brand',
      },
      {
        preKey: '/seller/reports',
        routeName: 'reports-publisher',
        key: '/seller/reports/publisher',
        label: 'Publisher',
        title: 'Publisher',
      },
    ]
  },
  {
    key: 'sub-settings',
    icon: () =>
      h('svg', { class: 'icon', 'aria-hidden': 'true' }, [
        h('use', { 'xlink:href': '#icon-a-shezhi2' }),
      ]),
    routeName: '',
    title: '',
    label: 'Settings',
    children: [
      {
        key: '/seller/connection',
        preKey: 'sub-settings',
        routeName: 'connection',
        title: '',
        label: 'Connections',
      },
    ],
  },
])
const bottomEntries = [
  {
    key: '/seller/referral',
    icon: () =>
      h('svg', { class: 'icon', 'aria-hidden': 'true' }, [
        h('use', { 'xlink:href': '#icon-zuanshi' }),
      ]),
    routeName: 'referral',
    title: 'Refer Your Friends',
    label: 'Refer Your Friends',
    path: '/seller/referral',
  },
]
</script>

<style lang="less" scoped>
.admin-home {
  display: flex;
  width: 100dvw;
  height: 100dvh;
  overflow-x: scroll;
  overflow-y: scroll;
  .wrap {
    flex: 1;
    display: flex;
    min-width: 1176px;
    flex-direction: column;
    .content {
      flex: 1;
      overflow: auto;
      .page {
        min-height: calc(100% - 72px);
      }
    }
  }
}
</style>
