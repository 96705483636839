import { refresh } from 'less';
import client from '../client';

export default {
  getSellerBrands: data => {//获取所有品牌
    return client.get('seller/brands/all', data).then(res=>res.data);
  },
  getSellerBrandsByUser: data => {//通过AmazonUser获取当前站点所有品牌
    return client.get('seller/brands/all/by-amazon-user-id', {params:data}).then(res=>res.data);
  },
  getPartSellerBrands: data => {//分页获取品牌
    return client.get('seller/brands', {params:data}).then(res=>res.data);
  },
  activateBrands:data => {//批量激活品牌
    return client.post(`seller/brands/activate`, data).then(res=>res.data);
  },
  activateBrand:data => {//激活品牌
    return client.post(`seller/brands/${data.id}/activate`, data).then(res=>res.data);
  },
  disableBrand:data => {//停用品牌
    return client.post(`seller/brands/${data.id}/disable`, data).then(res=>res.data);
  },
  getBrandDetail: data => {//获取品牌信息
    return client.get(`seller/brands/${data.brandId}`).then(res=>res.data);
  },
  updateBrandDetail: data => {//更新品牌信息
    return client.patch(`seller/brands/${data.id}`, data.req).then(res=>res.data);
  },
  setBrandPer: data => {//设置品牌佣金
    return client.patch(`seller/brands/${data.id}/commission`, data).then(res=>res.data);
  },
  getBrandProduct:data=>{//获取品牌下商品
    return client.get(`seller/product/listByBrandId`,{params:data}).then(res=>res.data);
  },
  latestUpdateTime:data => {
    return client.get('seller/brands/latest-update-time', data).then(res=>res.data);
  },
  refetchData:data => {
    return client.post('seller/brands/refetch', data).then(res=>res.data);
  },
}