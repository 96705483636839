import client from '../client';

export default {
  getAmazonConnections: data => {//获取列表
    return client.get('seller/connections',{params:data}).then(res=>res.data);
  },
  bindAmazonConnections:data => {//绑定
    return client.post(`seller/connections/bind`, data).then(res=>res.data);
  },
  unbindAmazonConnections:data => {//绑定
    return client.delete(`seller/connections/${data.connectionId}`).then(res=>res.data);
  },
  activateAmazonConnections:data => {//activate
    return client.post(`seller/connections/${data.connectionId}/activate`, data).then(res=>res.data);
  },
  deactivateAmazonConnections:data => {//deactivate
    return client.post(`seller/connections/${data.connectionId}/deactivate`, data).then(res=>res.data);
  },
}