<template>
  <div :class="className">
    <slot></slot>
  </div>
</template>
<script setup>
  import { computed } from 'vue';
  
    // const props = defineProps(['atype']);
  
    const className = computed(() => {
      let titleClass = 'artmisads-page-title'
      // titleClass += ` ${props.atype || defaultType}`;
      return titleClass;
    })
</script>

<style lang="less" scoped>
  .artmisads-page-title {
    height: 64px;
    line-height: 64px;
    color: var(--dark-1);
    font-weight: 600;
    font-size: var(--font-size-h);
  }
</style>
