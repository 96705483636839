<template>
    <div class="profile">
        <breadcrumbs :breadcrumbsData="breadcrumbsData" #icon>
        <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-a-wode4"></use>
        </svg>
        </breadcrumbs>
        <a-tabs v-model:activeKey="filter.reviewStatus" @change="getList">
            <a-tab-pane key="Pending" :tab="`Pending(${counts.pendingCount || 0})`"></a-tab-pane>
            <a-tab-pane key="Rejected" :tab="`Rejected(${counts.rejectedCount || 0})`"></a-tab-pane>
            <a-tab-pane key="Review" :tab="`Review(${counts.reviewCount || 0})`"></a-tab-pane>
            <a-tab-pane key="Approved" :tab="`Approved(${counts.approvedCount || 0})`"></a-tab-pane>
            <template #rightExtra>
                <artmisads-input placeholder="Email ID Name" v-model:value="filter.keyword" @keyup.enter="pager.page=1;getList()">
                  <template #prefix>
                    <SearchOutlined />
                  </template>
                </artmisads-input>
            </template>
        </a-tabs>
        <a-spin :spinning="loading">
        <div class="profile-wrap" v-for="item in list" :key="item.id" style="margin-bottom: 10px;">
          <div class="profile-header">
              <div class="profile-title">
                <p>ID: {{item.id}}</p>
              </div>
              <div>{{item.reviewStatus}}</div>
          </div>
          <div class="profile-detail">
              <div class="avatar">
                <div class="avatar">
                  <img :src="imgCache(item.publicImage || item.publicImageSource)" />
                </div>
              </div>
              <div class="info" style="width: 100%;">
                  <a-row gutter="20" justify="space-between">
                      <a-col span="10" style="z-index: 2;">
                        <a-col span="24">
                              <p class="label">Creator/Publisher Name:</p>
                              <p class="field">{{ item.creatorName }} </p>
                          </a-col>
                          <a-col span="24">
                              <p class="label">Product Categories:</p>
                              <p class="field">{{ item.promotionProductCategories.map(item => item.name).join(',') }} </p>
                          </a-col>
                          <a-col span="24">
                              <p class="label">Promotion Countries:</p>
                              <p class="field">{{ item.promotionCountries.map(item => item.name).join(',') }} </p>
                          </a-col>
                          <a-col span="24">
                              <p class="label">Bio:</p>
                              <p class="field" style="min-height: 60px;">
                                  {{  item.publicBio  }}
                              </p>
                          </a-col>
                      </a-col>
                      <a-col span="7">
                          <a-col span="24">
                              <p class="label">Creator Email:</p>
                              <p class="field">{{ item.creatorEmail }}</p>
                          </a-col>
                          <a-col span="24">
                              <p class="label">Register Email:</p>
                              <p class="field">{{ item.registerEmail }}</p>
                          </a-col>
                          <a-col span="24">
                              <p class="label">Promotion Methods:</p>
                              <p class="field">{{ item.promotionMethods.map(item => item.name).join(',') }} </p>
                          </a-col>
                      </a-col>
                      <a-col span="7">
                          <a-col span="24">
                              <p class="label">Register Time:</p>
                              <p class="field">{{ moment(item.registerTime).format('YYYY-MM-DD HH:mm') }} </p>
                          </a-col>
                          <a-col span="24">
                              <p class="label">Note:</p>
                              <p class="field">
                                  <artemisads-textarea :rows="3" @blur="(v) => onNoteChange(v, item.id)" :defaultValue="item.note"></artemisads-textarea>
                              </p>
                          </a-col>
                          <a-col span="24">
                              <p class="label" style="margin-bottom: 10px;">IP:<span style="margin-left: 5px;">{{ item.ip }}</span></p>
                          </a-col>
                          <a-col span="24">
                              <artmisads-select-button size="small" v-if="filter.reviewStatus !== 'Approved'" style="margin-right: 10px;" @click="modelRef.reviewStatus = 'Approved';modelRef.id= item.id;open = true;modelRef.emailContent = DEFAULT_EMAIL_APPROVE">Approve</artmisads-select-button>
                              <artmisads-select-button size="small" v-if="filter.reviewStatus !== 'Rejected'" @click="modelRef.reviewStatus = 'Rejected';modelRef.id= item.id;open = true;modelRef.emailContent = DEFAULT_EMAIL_REJECT">Reject</artmisads-select-button>
                          </a-col>
                      </a-col>
                      <a-col span="24">
                              <p class="label">Links:</p>
                              <p class="field-links" style="opacity: 1;">
                                  <template v-for="item in item.links">
                                  <a class="profile-links" :href="item.url" target="_blank" v-if="item.url">
                                    <svg-icon class="icon-app-default" name="lianjie" v-if="item.name === 'Other' || item.name === 'Website/Blog'"/>
                                    <svg-icon class="icon-app" v-else :name="item.name.toLowerCase()" />
                                    <span>{{item.name}}</span>
                                    <svg-icon name="jiantou-you-cu" class='arrow-icon' />
                                  </a>
                                  </template>
                              </p>
                      </a-col>
                  </a-row>
              </div>
          </div>
        </div>
        <div v-if="list.length === 0" style="display: flex;min-height: 300px;justify-content: center;align-items: center;">
          <a-empty />
        </div>
        </a-spin>
        <div style="display: flex;justify-content: right;">
          <a-pagination
            show-less-items
            show-size-changer
            :total="pager.total"
            :pageSize="pager.size"
            @change="onPageChange"
            :hideOnSinglePage="true"
            v-model:current="pager.page"
            :show-total="(total) => `Total ${total} items`"
          />
        </div>
        <a-drawer
            v-model:open="open"
            class="custom-class"
            root-class-name="root-class-name"
            :root-style="{ color: 'blue' }"
            width="550"
            title="Update Pulisher/Creator Proﬁle"
            placement="right"
            @after-open-change="afterOpenChange"
        >
        <a-form>
        <a-form-item 
          v-bind="validateInfos.creatorName"
        >
        <a-radio-group v-model:value="modelRef.sendEmail">
          <a-radio :value="false" style="display: block">
            Don't notify the Publisher.
          </a-radio>
          <a-radio :value="true">
            Notify the Publisher by email.
          </a-radio>
          </a-radio-group>
 
          <artemisads-textarea :disabled="!modelRef.sendEmail" style="margin-top: 10px;" :rows="22" v-model:value="modelRef.emailContent"/>
        </a-form-item>
      </a-form>
        <template #extra>
          <a-space>
            <artmisads-button type="primary" @click="onSubmit">{{modelRef.sendEmail ? 'Send & ' : ''}}{{modelRef.reviewStatus}}</artmisads-button>
          </a-space>
        </template>
        </a-drawer>
    </div>
</template>

<script setup>
import { ref, reactive, toRaw } from 'vue';
import { Form, message } from 'ant-design-vue';
import _ from 'lodash';

import { publisherAdminApi } from '~/server/index'

import breadcrumbs from '../breadcrumbs.vue';

import SvgIcon from '~/components/SvgIcon.vue';

import moment from 'moment';

import { SearchOutlined } from '@ant-design/icons-vue';

import { formattedText, imgCache } from '~/utils';

const breadcrumbsData = {
  title: 'Publisher',
  des: 'Publisher your team profile',
  color:'#FFD358',
  hasTitleIcon:true,
  links: [
    {
      name: 'Home',
      path: '/seller/welcome'
    },
    {
      name: 'Publisher',
      path: '/seller/brand',
    },
  ]
};

const DEFAULT_EMAIL_REJECT = 'Thank you for your interest in joining Artemis Ads Platform and for taking the time to apply. We truly value your effort and appreciate your passion for creating content and connecting with audiences.\n\n' + 
'After careful consideration, we regret to inform you that we are unable to move forward with your application at this time. Please know that this decision was not an easy one, as we receive applications from many talented publishers/creators like yourself. \n\n' +
'We encourage you to continue growing your platform and creating engaging content, and we hope our paths may cross again in the future. Should opportunities arise that align better with your profile, we would be delighted to connect with you.\n\n' +
'Thank you again for your interest in our platform. We wish you the best of luck in your future endeavors.'

const DEFAULT_EMAIL_APPROVE = 'Congratulations, and welcome to Artemis Ads Platform! We are thrilled to have you on board as part of our vibrant community of publishers and creators.' +
'Your application has been reviewed and approved, and you are now ready to start creating engaging content, promoting top products and brands, and earning commissions.\n\n' + 
'To help you get started, we recommend taking the following steps:\n\n' + 
'1. Complete Your Profile: Log in to your account and ensure your profile is up to date. A complete profile helps attract more opportunities.\n\n' +
'2. Explore Brands and Products: Browse the available brands and products on the platform to find those align with your audience and style.\n\n' + 
'3. Start Creating Content: Share authentic and creative content with your audience, and make sure to use your unique tracking links for commissions.\n\n' +
'4. Track Your Performance: Use the platform’s dashboard to monitor your results and earnings in real time.\n\n' +
'Our team is here to support you every step of the way. If you have any questions, need guidance, or want tips for success, don’t hesitate to reach out to us at Support@ArtemisAds.com.'
'We can’t wait to see the amazing work you’ll do. Let’s build something great together!'

const useForm = Form.useForm;

const open = ref(false);

const list = ref([]);
const loading = ref(false);

const counts = ref({});

const filter = reactive({
  keyword: '',
  reviewStatus: 'Pending'
})

const pager = reactive({
  page: 1,
  size: 10,
  total: 0
})

const modelRef = reactive({
  emailContent: DEFAULT_EMAIL_APPROVE,
  id: '',
  reviewStatus: '',
  sendEmail: true,
  keyword: ''
})

const rulesRef = reactive({
      emailContent: [{
            required: true,
            message: 'Company name cannot be empty',
      }],
      sendEmail: [{
        required: true,
        message: 'Must be a valid URL',
      }],
    });
  
const { validate, validateInfos } = useForm(modelRef, rulesRef);

const onSubmit = () => {
      let formState = _.cloneDeep(toRaw(modelRef));
      formState.emailContent = formattedText(formState.emailContent);
      publisherAdminApi.reviewPublisher(formState).then(res => {
        console.log(res);
          if(res.success) {
              message.success('Update Success!')
              modelRef.emailContent = DEFAULT_EMAIL_APPROVE;
              onClose();
              pager.page = 1;
              getList();
          }
      });
  }

const getList = (v) => {
  loading.value = true;

  if (v) { // 切换tab
    pager.page = 1
  }

  publisherAdminApi.getPublisherCount().then(res => {
    counts.value = res.data;
  })
  publisherAdminApi.getPublisherList({...filter, ...pager}).then(res => {
        list.value = res.data;
        pager.total = res.total;
        loading.value = false;
  });
}

const onPageChange = (page, size) => {
  pager.page = page;
  pager.size = size;
  getList();
}

const onNoteChange = (v, id) => {
  publisherAdminApi.modifyPublisher({
    id: id,
    note: v.target.value
  }).then(res => {
    if(res.success) {
      message.success('Note Update Success!')
    }
  })
}

// init();
getList();

function onOpen() {
    open.value = true;
}

const onClose = () => {
  open.value = false;
};
</script>

<style scoped lang="less">
.profile {
    padding: 24px;
    &-wrap {
        background-color: #fff;
        border: 1px solid #E8E5ED;
        border-radius: 12px;
        margin-bottom: 24px;
    }
    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #E8E5ED;
        padding: 16px;
    }
    &-links {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        background-color: var(--bg-gray-8);
        padding: 2px 5px 2px 2px;
        border-radius: 6px;
        margin-right: 8px;
        margin-bottom: 10px;
        color: var(--dark-2);
        &:hover {
          background-color: var(--bg-gray-5);
        }
        span {
            display: block;
        }
        .icon-app {
            font-size: 20px;
            border-radius: 4px;
            overflow: hidden;
            background-color: var(--bg-gray-5);
        }
        .icon-app-default {
            font-size: 16px;
            padding: 2px;
            border-radius: 4px;
            overflow: hidden;
            background-color: var(--bg-gray-5);
            color: var(--dark-4);
        }
        .arrow-icon {
          color: var(--dark-4);
        }
    }
    &-website {
        background: #FFFFFF;
        border: 1px solid #E8E5ED;
        box-shadow: 0 2px 12px 0 #0000000f;
        border-radius: 8px;
        border-left: 4px solid #CCC7D9;
        padding: 12px 18px;
        width: 354px;
        height: 110px;
        color: #0F0629;
        .website-name {
            background: #F1F0F5;
            border-radius: 4px;
            padding: 1px 3px;
            margin-bottom: 24px;
            display: inline-block;
        }
        .tag-label{
            opacity: 0.5;
            font-family: Sora, sans-serif;
            font-weight: 500;
            font-size: 13px;
            color: #0F0629;
            letter-spacing: 0.3px;
            line-height: 18px;
            margin-bottom: 4px;
        }
        .tag-box {
            display: flex;
            justify-content: space-between;
            align-items: center
        }

    }
    &-title {
        display: flex;
        justify-content: left;
        align-items: center;
        font-family: Sora, sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #0F0629;
        letter-spacing: 0.5px;
        line-height: 28px;
        }
    &-subtitle {
            opacity: 0.5;
            font-weight: 400;
            font-size: 13px;
            color: #0F0629;
            letter-spacing: 0.3px;
            line-height: 18px;
            margin-left: 8px;
        }
    &-detail {
        padding: 16px;
        display: flex;
        .avatar {
            width: 200px;
            height: 200px;
            background: #F0EDF5;
            border-radius: 8px;
            // margin-right: 16px;
        }
        .label {
            // width: 280px;
            margin-top: 10px;
            opacity: 0.5;
            font-family: Sora, sans-serif;
            font-weight: 500;
            font-size: 13px;
            color: #0F0629;
            letter-spacing: 0.3px;
            line-height: 18px;
        }
        .field {
            margin-top: 4px;
            opacity: 0.7;
            font-family: Sora, sans-serif;
            font-weight: 500;
            font-size: 14px;
            color: #0F0629;
            letter-spacing: 0.4px;
            line-height: 20px;
            word-break: break-word;
            // width: 280px;
        }
        .field-links {
            margin-top: 4px;
            display: flex;
            font-family: Sora, sans-serif;
            font-weight: 400;
            font-size: 13px;
            color: #0F0629;
            letter-spacing: 0.3px;
            line-height: 18px;
            // width: 480px;
            flex-wrap: wrap
        }
    }
}
.input {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    width: 480px;
    > span {
        height: 16px;
        opacity: 0.7;
        font-family: Sora, sans-serif;
        font-weight: 500;
        font-size: 16px;
        color: #0F0629;
        letter-spacing: 0.4px;
        line-height: 16px;
        margin-bottom: 8px;
    }
    > input {
        height: 40px;
    }
    .main-text {
        opacity: 0.7;
        font-family: Sora, sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #0F0629;
        letter-spacing: 0.4px;
        line-height: 20px;
      }
      .subtitle {
        color: #6a647d;
        font-size: 14px;
        font-family: Sora, sans-serif;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0.4px;
        text-align: left;
      }
      .icon-app {
        font-size: 24px;
        border-radius: 4px;
        overflow: hidden;
      }
}
.link-card {
        margin-top: 83px;
        .title {
          font-family: Sora, sans-serif;
          font-weight: 500;
          font-size: 20px;
          color: #0F0629;
          letter-spacing: 0.5px;
          line-height: 28px;
          margin-bottom: 6px;
        }
        .subtitle {
          font-family: Sora, sans-serif;
          font-weight: 400;
          font-size: 12px;
          color: #0F0629;
          letter-spacing: 0.3px;
          line-height: 16px;
          opacity: 0.7;
        }
        .input {
            width: 440px;
        }
      }
      .tips {
      opacity: 0.5;
      font-family: Sora, sans-serif;
      font-weight: 400;
      font-size: 13px;
      color: #0F0629;
      letter-spacing: 0.3px;
      line-height: 18px;
    }
    .ant-alert {
      width: auto;
      margin-top: 40px;
      background: #FEF4E5;
      border: 2px solid #F6AC2D;
      border-radius: 8px;
      box-shadow: none;
    }
    .social-addon {
        font-size: 14px;
        justify-content: left;
        align-items: center;
        width: 113px;
        text-align: left;
        display: flex;
        gap: 3px;
        box-sizing: border-box;
      }
</style>