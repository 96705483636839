<template>
  <div class="step-wrapper"
  >
    <div 
    :class="['step-box', { 'is-active': props.current == 1, 'is-finished': props.current > 1 }]"
    >
      <div class="step-option">
        <div class="num">1</div>
        <div class="txt">Verify Email</div>
      </div>
    </div>
    <div 
     :class="['step-box', { 'is-active': props.current > 1 }]"
    >
      <div class="step-line"></div>
    </div>
    <div 
     :class="['step-box', { 'is-active': props.current == 2, 'is-finished': props.current > 2 }]"
    >
      <div class="step-option">
        <div class="num">2</div>
        <div class="txt">Reset Password</div>
      </div>
    </div>
    <div 
     :class="['step-box', { 'is-active': props.current > 2, }]"
    >
      <div class="step-line"></div>
    </div>
    <div 
    :class="['step-box', { 'is-active': props.current == 3, 'is-finished': props.current > 3 }]"
    >
      <div class="step-option">
        <div class="num">3</div>
        <div class="txt">Success</div>
      </div>
    </div>
  </div>
</template>
<script setup>
const props=defineProps(['current']);
</script>
<style lang="less" scoped>
.step-wrapper{
  background-color: transparent;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  font-family: Sora, sans-serif;
  .step-box{
    display: flex;
    justify-content: space-between;
    .step-option{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // cursor: pointer;
      .num{
      margin-bottom: 16px;
      width: 40px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 8px;
      background-color: rgba(238, 237, 242, 1);
      border:2px solid rgba(156, 151, 172, 1) ;
      font-size: 20px;
      color: #878294;
      text-align: center;
      &:active{
        border: unset;
        background-color: var(--primary);
        color: #fff;
      }
    }
    .txt{
      font-family: Sora, sans-serif;
      font-weight: 500;
      font-size: 14px;
      color:var(--dark-3);
      letter-spacing: 0.4px;
      line-height: 19px;
      &:active{
        color: var(--primary);
      }
    }
    }
    
  }
  .is-active{
    .num,.step-line{
      color: #fff !important;
      background-color: var(--primary-text) !important;
      border: unset !important;
    }
    .txt{
      color: var(--primary-text) !important;
    }
  }
  .is-finished{
    .num{
      color:var(--primary-text) !important;
      background-color: transparent !important ;
      border:2px solid var(--primary-text) !important;
    }
    .txt{
      color: var(--dark-1) !important;
    }
  }
  

  .step-line{
    margin-top: 19px;
    width: 80px;
    height: 2px;
    background-color:var(--border-1);
    border-radius: 50px;
  }

}
</style>