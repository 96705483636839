<template>
  <div class="table">
    <artmisads-table
      :columns="columns"
      row-key="id"
      :data-source="props.dataList"
      :pagination="{
        total: props.tableTotal,
        current: props.params.page,
        pageSize: props.params.size,
        showSizeChanger: true
      }"
      :change="onChangePage"
      rowClassName="table-item"
      tableLayout="fixed"
    >
      <template #bodyCell="{ column, text, record }">
        <template v-if="column.name === 'showCurrency'">
          {{text ? AMAZON_CURRENCY_MAPPER[props.marketplace] : ''}}{{text}}
        </template>
        <template v-if="column.name === 'reportDateTimestamp'">
          {{moment(text).format('YYYY-MM-DD')}}
        </template>
        <template v-if="column.name === 'more'">
          <svg @click="() => onClickTableChart(record)" class="icon chart-icon" aria-hidden="true">
            <use xlink:href="#icon-chart"></use>
          </svg>
        </template>
      </template>
      <template #summary>
        <a-table-summary-row>
          <a-table-summary-cell>Total</a-table-summary-cell>
          <a-table-summary-cell>
            <a-typography-text>{{ props.tagNumParams.totalClicks }}</a-typography-text>
          </a-table-summary-cell>
          <a-table-summary-cell>
            <a-typography-text>{{ props.tagNumParams.totalDetailPageViews }}</a-typography-text>
          </a-table-summary-cell>
          <a-table-summary-cell>
            <a-typography-text>{{ props.tagNumParams.totalAddToCart }}</a-typography-text>
          </a-table-summary-cell>
          <a-table-summary-cell>
            <a-typography-text>{{ props.tagNumParams.totalConversions }}</a-typography-text>
          </a-table-summary-cell>
          <a-table-summary-cell>
            <a-typography-text>{{props.tagNumParams.totalSalesAmount ? AMAZON_CURRENCY_MAPPER[props.marketplace] : ''}}{{ props.tagNumParams.totalSalesAmount }}</a-typography-text>
          </a-table-summary-cell>
          <a-table-summary-cell>
            <a-typography-text>{{props.tagNumParams.totalDiscountedSalesAmount ? AMAZON_CURRENCY_MAPPER[props.marketplace] : ''}}{{ props.tagNumParams.totalDiscountedSalesAmount }}</a-typography-text>
          </a-table-summary-cell>
          <a-table-summary-cell>
            <a-typography-text>{{props.tagNumParams.totalCommissionAndFeeAmount ? AMAZON_CURRENCY_MAPPER[props.marketplace] : ''}}{{ props.tagNumParams.totalCommissionAndFeeAmount }}</a-typography-text>
          </a-table-summary-cell>
          <a-table-summary-cell>
            <a-typography-text></a-typography-text>
          </a-table-summary-cell>
        </a-table-summary-row>
      </template>
    </artmisads-table>
  </div>
</template>
<script setup lang="jsx">
  import moment from 'moment';
  import { onMounted, ref, watch, computed } from 'vue';

  import { AMAZON_CURRENCY_MAPPER } from '~/contants/common';
  import { reportsApi } from '~/server/index';

  const props = defineProps(['dataList', 'marketplace', 'tableTotal', 'params', 'setParams', 'onClickTableChart', 'tagNumParams']);

  const onChangePage = (data, filters, sorter) => {
    const { current, pageSize } = data;
    let newParams = { ...props.params };
    newParams.page = current;
    newParams.size = pageSize;
    if (sorter) {
      const { field, order } = sorter;
      newParams.sortField = field;
      newParams.sortDirection = order === 'ascend' ? 'asc' : 'desc';
    } else {
      newParams.sortField = '';
      newParams.sortDirection = '';
    }
    props.setParams(newParams);
  }

  const columns = [
    {
      title: 'Product',
      dataIndex: 'productTitle',
      width: 'auto',
      customRender: ({ text, record }) => {
        return (
          <a target="_blank" href={`/seller/product/${record.productId}`} class="product-item">
            <img src={record.productImageUrl}/>
            <div class="text">
              <p class="title">{text}</p>
              <span class="asin">{record.productAsin}</span>
            </div>
          </a>
        )
      }
    },
    {
      title: 'Clicks',
      dataIndex: 'clicks',
      width: 90,
      sorter: true,
    },
    {
      title: 'DPVs',
      dataIndex: 'detailPageViews',
      width: 80,
      sorter: true,
    },
    {
      title: 'Add to Cart',
      dataIndex: 'addToCart',
      width: 130,
      sorter: true,
    },
    {
      title: 'Conversions',
      dataIndex: 'conversions',
      width: 120,
      sorter: true,
    },
    {
      title: 'Sales',
      dataIndex: 'salesAmount',
      name: 'showCurrency',
      width: 110,
      sorter: true,
    },
    {
      title: 'Sales for Commission',
      dataIndex: 'discountedSalesAmount',
      name: 'showCurrency',
      width: 210,
      sorter: true,
    },
    {
      title: 'Commission',
      dataIndex: 'commissionAndFeeAmount',
      name: 'showCurrency',
      width: 110,
      sorter: true,
    },
    {
      title: 'More',
      name: 'more',
      dataIndex: 'commissionAndFeeAmount',
      width: 70,
    },
  ]

</script>
<style lang="less" scoped>
  :deep(.ant-table) {
    .chart-icon {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
    table {
      table-layout: fixed !important;
    }
    .inactive-commission {
      color: var(--dark-4);
    }
    .status-text {
      text-transform: capitalize;
    }
    .product-item {
      display: flex;
      img {
        width: 56px;
        height: 56px;
        margin-right: 12px;
        border-radius: 8px;
        object-fit: contain;
      }
      .text {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        justify-content: center;
        .title {
          max-width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: var(--dark-1);
          font-weight: 500;
          font-size: var(-font-size-middle);
          text-decoration: underline;
        }
        .asin {
          color: var(--dark-3);
          font-weight: 500;
          font-size: var(--font-size-min);
          letter-spacing: 0.3px;
          text-align: right;
          line-height: 16px;
          text-align: left;
          margin-top: 2px;
        }
      }
    }
  }
</style>
