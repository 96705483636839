<!--
 * @Author: Hankill(hao.chen)
 * @Date: 2024-12-11 17:20:20
 * @LastEditors: Hankill(hao.chen)
 * @LastEditTime: 2024-12-13 10:40:08
 * @FilePath: /web_artemisads/src/artemisadsComponents/artemisadsCheckbox.vue
 * @Description: checkbox 
-->
<template>
    <a-checkbox class="artemisads-checkbox" v-bind="props">
        <slot name="default"></slot>
    </a-checkbox>
  </template>
    
    <script setup>
    </script>
    
    <style lang="less" scoped>
    .artemisads-checkbox.ant-checkbox-wrapper-checked {
      :deep(.ant-checkbox-inner) {
        background-color: var(--primary);
        border-color: var(--primary);
      }
    }
    .artemisads-checkbox.ant-checkbox-wrapper-disabled {
      :deep(.ant-checkbox-inner) {
        background-color: var(--disabled-fill-1);
        border-color: var(--border-1);
        box-shadow: var(--disabled-box-shadow-4);
      }
      &:hover {
          :deep(.ant-checkbox-inner) {
            background-color: var(--disabled-fill-1);
            border: 1px solid var(--border-1);
            box-shadow: var(--disabled-box-shadow-4);
        }
      }
    }
    .artemisads-checkbox {
      color: var(--dark-1);
      font-weight: 400;
      font-family: Sora, sans-serif;
      &:hover {
          :deep(.ant-checkbox-inner) {
            background: #F9F7FF;
            border: 2px solid var(--primary-border-2);
            box-shadow: var(--section-box-shadow-2);
          }
      }
      :deep(.ant-checkbox-inner) {
          width: 16px;
          height: 16px;
          background: #fff;
          border: 1px solid var(--border-1);
          box-shadow: var(--section-box-shadow-2);
          border-radius: 4px;
      }
    }
    </style>
    