import { defineStore } from 'pinia'
import { ref, computed, watch, reactive } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import adminApi from '../server/seller/admin'

export const useSystemStore = defineStore(
  'system',
  () => {
    const commissionConfig = ref({});
    const getCommissionConfig= ()=>{
      adminApi.getCommissionConfig().then(res=>{
        commissionConfig.value=res.data
      })
    }
    getCommissionConfig();
    const brandUpdateBtn = ref(false);
    const productUpdateBtn = ref(false);
    const setBtnStatus=(type,status) =>{
      if(type=='brand'){
        brandUpdateBtn.value = status
      }
      if(type=='product'){
        productUpdateBtn.value = status
      }
    }
    const setUpdateBtnStatus=status=>{
      updateBtnStatus.value=status
    }
    return {
      commissionConfig,
      getCommissionConfig,
      setUpdateBtnStatus,
      brandUpdateBtn,
      productUpdateBtn,
      setBtnStatus,
    }
  }
)
