<template>
  <div class="profile-up">
    <div class="profile-left">
      <signInUpLeft />
    </div>
    <div class="profile-right">
        <div class="profile-wrap">
          <topBar/>
          <div class="profile-up-content">
            <a-carousel effect="fade" 
              ref="myCarousel"
              dot-position="top"
              :afterChange="afterChange"
            >
              <div class="carousel-item">
                <connecting v-if="type==3" title="Checking your Account" :goTo="goTo"/>
                <selectUserType v-if="type==1" :goTo="goTo"/>
              </div>
              <div class="carousel-item">
                <seller v-if="type==1" :goTo="goTo"/>
                <creator v-if="type==2" :goTo="goTo"/>
              </div>
              <div class="carousel-item">
                <connecting v-if="type==1" :goTo="goTo"/>
                <target v-if="type==2" :goTo="goTo"/>
              </div>
              <div class="carousel-item">
                <h3 v-if="type==1"></h3>
                <avatar v-if="type==2" :goTo="goTo" />
              </div>
            </a-carousel>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
  import { ref } from 'vue';
  import jwt from 'jsonwebtoken';

  import { useUserInfoStore } from '../../storeData/index';
  import profileApi from '~/server/register'
  import signInUpLeft from '../../components/signInUpLeft.vue'
  import topBar from '../../components/topBar.vue';
  import selectUserType from './selectUserType.vue'
  import seller from './seller.vue'
  import connecting from './connecting.vue'
  import creator from './creator.vue'
  import target from './target.vue'
  import avatar from './avatar.vue'

  const SOCIAL_MAP = [
        {
            "id": 4,
            "name": "Tiktok",
            "url": null
        },
        {
            "id": 5,
            "name": "Instagram",
            "url": null
        },
        {
            "id": 6,
            "name": "Facebook",
            "url": null
        },
        {
            "id": 7,
            "name": "YouTube",
            "url": null
        },
        {
            "id": 8,
            "name": "Pinterest",
            "url": null
        },
        {
            "id": 9,
            "name": "Red",
            "url": null
        },
        {
            "id": 10,
            "name": "WeChat",
            "url": null
        },
        {
            "id": 11,
            "name": "Weibo",
            "url": null
        },
        {
            "id": 12,
            "name": "LinkedIn",
            "url": null
        },
        {
            "id": 13,
            "name": "X",
            "url": null
        }
    ]
  
  export default {
    setup() {
      const myCarousel = ref(null);  
      const store = useUserInfoStore();
      const type = ref(1);
      return {
          myCarousel,
          store,
          type,
      }
    },
    components: {
      signInUpLeft,
      selectUserType,
      topBar,
      seller,
      creator,
      target,
      avatar,
      connecting
    },
    methods: {
      goTo(number, type) {
        this.$refs.myCarousel.goTo(number);
        if(type) {
          this.type = type;
        }
      },
      afterChange (current) {
        const $slickSots = document.querySelectorAll('.slick-dots li')
        $slickSots.forEach(($item, index) => {
          if (index < current) {
            $item.classList.add('slick-active');
          }
        })
      },
      async userActivate (activateToken) {
        const params = {
          activateToken
        }
        const res = await profileApi.userActivate(params);
        const { data, success, error } = res;
        if (success) {
          const userToken = data.token;
          const artmisAdsUserData = jwt.decode(userToken);
          this.store.setUserInfo(artmisAdsUserData, userToken);
          if (artmisAdsUserData.status !== 'PENDING_CHOOSE_TYPE') {
            location.href = '/seller/welcome';
          }
        } else {
          location.href = '/sign-in';
        }
      },
      checkPage () {
        const localStoragePrefix = location.hostname;
        const { code, token } = this.$route.query;
        const userToken = localStorage.getItem(`${location.hostname}_userToken`);
        const userInfo = localStorage.getItem(`${localStoragePrefix}_artmisAdsUserInfo`);
        if(userInfo && JSON.parse(userInfo).userType === "publisher" && JSON.parse(userInfo).reviewStatus === 'Rejected') {
            this.type = 3;
            profileApi.getUserProfile().then(res => {
                res.data?.links.map(item => item.isUrl = true);
                const links = res.data.links;
                const ids = links.map(item => item.id);
                const extraSocial = SOCIAL_MAP.filter(item => {
                  return ids.indexOf(item.id) === -1;
                }).map(item => {
                  return {
                    label: item.name,
                    value: item.id
                  }
                })
                const step2 = {
                  creatorName: res.data.creatorName,
                  creatorEmail: res.data.creatorEmail,
                  links: links,
                  extraSocial: extraSocial
                }
                const step3 = {
                  promotionCountryCodes: res.data.promotionCountryCodes,
                  promotionProductCategoryIds: res.data.promotionProductCategoryIds,
                  promotionMethodIds: res.data.promotionMethodIds,
                }
                const step4 = {
                  publicImage: res.data.publicImage,
                  publicImageSource: res.data.publicImageSource,
                  publicBio: res.data.publicBio,
                }
                localStorage.setItem('profile-step-2', JSON.stringify(step2));
                localStorage.setItem('profile-step-3', JSON.stringify(step3));
                localStorage.setItem('profile-step-4', JSON.stringify(step4));
                this.goTo(1,2);
            });
        } else {
          if (code) {
            this.goTo(2);
          } else if (token) {
            this.userActivate(token)
          } else if (!userToken) {
            location.href = '/sign-in';
          } else if (userInfo) {
            if (JSON.parse(userInfo).status !== 'PENDING_CHOOSE_TYPE') {
              location.href = '/';
            }
          }
        }
      }
    },
    updated () {
      this.checkPage();
    },
    mounted () {
      this.checkPage();
    }
  }

</script>

<style>
body {
  overflow: hidden;
}
</style>

<style lang="less" scoped>
  .profile-up {
    width: 100vw;
    height: 100vh;
    min-height: 700px;
    overflow: hidden;
    display: flex;
    :deep(.ant-carousel) {
      width: 100%;
    }
  }
  .profile-right {
    width: 66.666vw;
    display: flex;
    justify-content: center;
    background: #F4F2FA;
    flex: 1;
  }
  .profile-wrap {
    width: 100%;
  }
  .profile-up-content {
    margin-top: 8vh;
    width: 100%;
    display: flex;
    justify-content: center;
    .carousel-item {
      overflow-y: scroll;
      padding-top: 60px;
      display: flex !important;
      justify-content: center;
    }
  }
  :deep(.slick-dots) {
    justify-content: center;
    margin-left: -247px;
    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 20px;
      top: -10px;
      background-color: transparent;
      z-index: 1;
    }
    li button {
      background: #7D58E5;;
    }
    
    li, button, .slick-active {
      width: 56px !important;
    }
    .slick-active button {
      background: #7D58E5 !important;
    }
  }
// }

// @media screen and (max-width: 479px) {
//   .profile-right {
//     position: relative;
//     width: 100dvw;
//     height: auto;
//     min-height: 100dvh;
//     display: flex;
//     overflow: scroll;
//   }
//   .profile-wrap {
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     .profile-up-content {
//       margin-top: 120px;
//       align-self: center;
//       position: relative;
//       z-index: 1;
//       width: calc(100dvw - 32px);
//       padding: 24px 32px;
//       width: 343px;
//       background: #FFFFFF;
//       box-shadow: 0 1px 0 0 #1a16250d;
//       border-radius: 16px;
//     }
//   }
// }
  
// </style>
