<template>
  <div class="product">
    <div class="breadcrumbs">
      <breadcrumbs :breadcrumbsData="breadcrumbsData" #icon>
        <svg class="icon" aria-hidden="true" style="fill: #fff">
          <use xlink:href="#icon-shangpin"></use>
        </svg>
      </breadcrumbs>
    </div>
    <reFetch
        :key="product"
        type="product"
        :refetch="getProductList"
      />
    <div class="content-wrap">
      <div class="content-main">
        <fileBar
          :params="params"
          :getProductList="getProductList"
          :exportsNum="exportsNum"
        />
        <div class="content">
          <operationBar
            :onChangeActiveItems="onChangeActiveItems"
            :selectedRowKeys="selectedRowKeys"
            :setSelectedRowKeys="setSelectedRowKeys"
            :productList="productList"
            :getProductList="getProductList"
            :params="params"
            :setParams="setParams"
            :listTotal="listTotal"
            :setShowSetCommission="setShowSetCommission"
            :brandswithAvailableProducts="brandswithAvailableProducts"
            :amazonNameList="amazonNameList"
          />
          <tableContent
            :setParams="setParams"
            :loading="loading"
            :noData="noData"
            :params="params"
            :dataSource="productList"
            :brandswithAvailableProducts="brandswithAvailableProducts"
            :selectedRowKeys="selectedRowKeys"
            :getProductList="getProductList"
            :onSelectChange="onSelectChange"
            :total="total"
            :onChangeActiveItems="onChangeActiveItems"
            :listTotal="listTotal"
            :setShowSetCommission="setShowSetCommission"
            :setSelectedRowKeys="setSelectedRowKeys"
            :setSelectSingleProduct="setSelectSingleProduct"
          />
        </div>
      </div>
    </div>
    <setPublicCommission
      :type="'product'"
      :showSetCommission="showSetCommission"
      :productList="productList"
      :selectedRowKeys="selectedRowKeys"
      :setShowSetCommission="setShowSetCommission"
      :getProductList="getProductList"
      :selectSingleProduct="selectSingleProduct"
      :setSelectSingleProduct="setSelectSingleProduct"
    />
    <contextHolder />
  </div>
</template>
<script lang="jsx" setup>
  import { onBeforeMount, onMounted, ref, watch } from 'vue';
  import setPublicCommission from './setPublicCommission.vue';
  import reFetch from '../Brands/components/reFetch.vue';

  import { useUserInfoStore } from '../../../storeData';
  import { useSellerProductStore } from '../../../storeData/sellerproduct';
  import { productsApi } from '../../../server/index';
  import { connectionApi } from "@/server/seller/index";
  import breadcrumbs from '../breadcrumbs.vue';
  import tableContent from './tableContent.vue';
  import operationBar from './operationBar.vue';
  import fileBar from './fileBar/index.vue';
  import { message, notification } from 'ant-design-vue';

  const breadcrumbsData = {
    title: 'Your Products',
    des: 'Review you active ASINs that will be listed on our marketplace for Creator discovery',
    color:'#4682FA',
    hasTitleIcon:true,
    links: [
      {
        name: 'Home',
        path: '/seller/welcome'
      },
      {
        name: 'Products',
        path: '/seller/product'
      }
    ]
  };
  const userStore=useUserInfoStore();
  const store = useSellerProductStore();
  const brandswithAvailableProducts = ref([]);
  const amazonNameList=ref([]);
  const selectSingleProduct = ref('');
  const showSetCommission = ref(false);
  const selectedRowKeys = ref([]);
  const loading = ref(true);
  const productList = ref([]);
  const listTotal = ref(0);
  const exportsNum = ref({
    activeProductCount: 0,
    allProductCount: 0,
    inActiveProductCount: 0,
    filterProductCount: 0,
  });
  const params = ref({
    availability: '',
    commission: '',
    maxPrice: '',
    minPrice: '',
    page: 1,
    size: 20,
    sortType: 1,
    status: '',
    title: '',
    brandId: ''
  })
  const noData=ref(false);

  const [api, contextHolder] = notification.useNotification();
  const openNotification = description => {
    api.info({
      message: `Notice`,
      description: description,
      placement: 'topRight',
      duration: 3
    });
  };

  const setSelectSingleProduct = id => {
    selectSingleProduct.value = id;
  }

  const setShowSetCommission = status => {
    showSetCommission.value = status;
  }


  watch(() => params.value.sortType, () => {
    getProductList();
  })

  const setParams = data => {
    params.value = data;
  }

  const setSelectedRowKeys = data => {
    selectedRowKeys.value = data;
  }

  const onChangeActiveItems = async (status, ids) => {
    loading.value = true;
    let res, params = ids;
    if (status === 'active') {
      res = await productsApi.activeProduct(params);
    } else {
      res = await productsApi.inactiveProduct(params);
    }
    const { success, error, code } = res;
    if (success) {
      getProductList();
      message.success('Success');
      getProductCount();
    } else {
      if (code === '1014') {
        openNotification(error);
      }
    }
    loading.value = false;
  }

  const onSelectChange = keys => {
    selectedRowKeys.value = keys;
  };

  const getProductCount = async () => {
    const res = await productsApi.getProductCount();
    const { success, data } = res;
    if (success) {
      exportsNum.value = { ...exportsNum.value, ...data };
    }
  }

  const getProductList = async (goToTop = false) => {
    loading.value = true;
    let newparams={...params.value};
    const newParams = newparams = Object.keys(newparams).reduce((acc, key) => {
      if(key == 'status' && newparams[key]=='all'){

      }else if(newparams[key] !== null && newparams[key] !== undefined && newparams[key] !== '' ) {
        acc[key] = newparams[key];
      }
      return acc;
    }, {});  
    const res = await productsApi.getProductList(newParams);
    const { success, data } = res;
    if (success) {
      if (params.value.page > 1 && !data.data.length) {
        setParams({...params.value, page: 1});
        getProductList();
      } else {
        productList.value = data.data;
        listTotal.value = data.total;
        exportsNum.value.filterProductCount = data.total;
        if (goToTop) {
          document.querySelector('.product').scrollIntoView({
            behavior: 'smooth'
          })
        }
        if(!data.data?.length){
          const status = userStore.userInfo.status;
          if (status === 'SELLER_PENDING_AGREED_TO_NOTICE') {
            noData.value=true;
            let _user=userStore.userInfo;
            _user.status='NORMAL';
            userStore.setUserInfo(_user);
          }else{
            noData.value=false;
          }
        }
       
      }
      
    }else{
      const status = userStore.userInfo.status;
      if (status === 'SELLER_PENDING_AGREED_TO_NOTICE') {
        noData.value=true;
        let _user=userStore.userInfo;
        _user.status='NORMAL';
        userStore.setUserInfo(_user);
      }else{
        noData.value=false;
      }
    }
    loading.value = false;
  }

  const getBrandswithAvailableProducts = async () => {
    const res = await productsApi.getBrandswithAvailableProducts();
    const { success, data } = res;
    if (success && data.length) {
      const list = data.map(item => {
        item.value = item.id;
        item.label = item.amazonBrandName;
        if(item.amazonProfileAccountInfoName){
          item.label += `(${item.amazonProfileAccountInfoName})`
        }
        return item;
      })
      brandswithAvailableProducts.value = list;
    }
  }
  const getAmazonNameList = () => {
    connectionApi.getAmazonConnections().then((res) => {
      if (res.success) {
        res.data.data.map((v) => {
          v.label = v.amazonName;
          v.value = v.amazonUserId;
        });
        amazonNameList.value = res.data.data;
      }
    });
  };
  const checkFromBrandList = () => {
    let paramsFromBrand = store.params;
    if (paramsFromBrand) {
      params.value.brandId = paramsFromBrand.brandId;
      params.value.status = paramsFromBrand.status;
      store.setParams({});
    }
  }

  onBeforeMount(() => {
    getBrandswithAvailableProducts();
    getAmazonNameList();
  })

  onMounted(() => {
    checkFromBrandList();
    getProductCount();
    getProductList();
  })


</script>
<style lang="less" scoped>
  .product {
    padding:0 16px 16px;
  }
  .content-main {
    overflow: hidden;
    border-radius: 8px;
    background-color: var(--color-white);
    box-shadow: var(--section-box-shadow-6);
  }
  .content {
    padding: 15px;
  }
</style>