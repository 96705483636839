<template >
  <div class="brand">
    <breadcrumbs :breadcrumbsData="breadcrumbsData" #icon>
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-pinpai"></use>
      </svg>
    </breadcrumbs>
    <reFetch
    :key="brand"
    type="brand"
    :refetch="getData"
    />
      <div class="table">
        <div class="table-head">
          <a-space>
            <ButtonGroups 
          :pagination="pagination"
          :tableData="dataSource"
          @pageChange="onPageChange"
          />
          </a-space>
          
          <a-space class="filters">
            <a-input-search
              v-model:value="keywords"
              placeholder="Search..."
              allow-clear
              @search="onSearch"
              @enter="onSearch"
            >
            </a-input-search>
            
            <div class="statusFilter">
              <span style="margin-right: 8px;">Status:</span>
              <artmisads-select
                v-model:value="status"
                style="width: 130px;"
                @change="onStatusChange"
                :getPopupContainer="
                  triggerNode => {
                    return triggerNode.parentNode || document.body;
                  }
                "
                trigger="click"
              >
                <a-select-option value="all">All</a-select-option>
                <a-select-option value="ACTIVE" >Active</a-select-option>
                <a-select-option value="INACTIVE">Inactive</a-select-option>
              </artmisads-select>

            </div>
          </a-space>
        </div>
        <artmisads-table
          v-model:pagination="pagination"
          :columns="columns"
          :data-source="dataSource"
          :change="onTableChange"
          :loading="load"
          :noData="noData"
        >
          <template #emptyText>
            <div>
              <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg"><g transform="translate(0 1)" fill="none" fill-rule="evenodd"><ellipse fill="#f5f5f5" cx="32" cy="33" rx="32" ry="7"></ellipse><g fill-rule="nonzero" stroke="#d9d9d9"><path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path><path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#fafafa"></path></g></g></svg>
            </div>
            <p class="ant-empty-description">There is currently no Brands data.</p>
            <p class="ant-empty-description">You can click the "Update" button above to try again.</p>
          </template>
          <template #bodyCell="{column,record}">
            <template v-if="column.key === 'amazonBrandName'">
              <a class="brand-title"  target="_blank" :href="`/seller/brand/detail/${record.id}`">  
                <div class="brand-img-show" :style="record?.logoImageUrl?{'background':'#ffffff'}:''">
                  <img :src="record.logoImageUrl" alt="" v-if="record.logoImageUrl">
                </div>
                <div  class="brand-font">
                  <span style="text-decoration: underline;">{{ record.amazonBrandName }}</span>
                  <span v-show="record.amazonProfileAccountInfoName">({{record.amazonProfileAccountInfoName }})</span>
                </div>
              </a>
            </template>
            <template v-if="column.key === 'status'">
              <showStatus :isActive="record.status=='active'" :noBorder="true"></showStatus>

            </template>
            <template v-if="column.key === 'activeProductCount'">
              <a class="table-font" @click="goProductPage(record,'active')">{{ record.activeProductCount }}</a>
            </template>
            <template v-if="column.key === 'inactiveProductCount'">
              <a class="table-font"  @click="goProductPage(record,'inactive')">{{ record.inactiveProductCount }}</a>
            </template>
            <template v-if="column.key === 'action'">
              <a-space direction="vertical">
              <artmisads-select-button size="small" @click="onOpenModal(record,'setCommission')">
                Set Commission
              </artmisads-select-button>
              <artmisads-button :isSquare="true" type="primary" size="small"  @click="onOpenModal(record,'active')" v-if="record.status == 'inactive'">
                Activate
              </artmisads-button>
              <artmisads-select-button v-if="record.status == 'active'" size="small" @click="onOpenModal(record,'deactive')">
                Deactivate
              </artmisads-select-button>
            </a-space>
            </template>
          </template>
        </artmisads-table>
      </div>

    <TableModal 
      :configs="modalConfigs"
      :item="singleRecord"
      @cancel="onModalCancel"
      @ok="onModalOK"
    />
    <setPublicCommission
      :type="'brand'"
      :showSetCommission="showSetCommission"
      :productList="dataSource"
      :selectedRowKeys="[]"
      :setShowSetCommission="setShowSetCommission"
      :getProductList="getData"
      :selectSingleProduct="selectSingleProduct"
      :setSelectSingleProduct="setSelectSingleProduct"
    />
    <contextHolder />
  </div>

</template>
<script setup>
import {ref,onMounted,computed,reactive,onUnmounted} from 'vue';
import { useRouter,useRoute } from 'vue-router';
import breadcrumbs from '../breadcrumbs.vue';
import TableModal from './components/tableModal.vue';
import { brandApi } from '../../../server/index';
import ButtonGroups from './components/buttonGroups.vue';
import showStatus from './components/showStatus.vue';
import { useSellerProductStore } from '@/storeData/sellerproduct';
import { useSellerBrandStore } from '@/storeData/sellerbrand';
import { message, notification } from 'ant-design-vue';
import setPublicCommission from '../product/setPublicCommission.vue';
import reFetch from './components/reFetch.vue';
import { useUserInfoStore } from "@/storeData";


const [api, contextHolder] = notification.useNotification();
const openNotification = description => {
  api.info({
    message: `Notice`,
    description: description,
    placement: 'topRight',
    duration: 3
  });
};
const breadcrumbsData = {
  title: 'Your Brands',
  des: 'Review and update your creator-facing details and logo for each brand.',
  color:'#FFD358',
  hasTitleIcon:true,
  links: [
    {
      name: 'Home',
      path: '/seller/welcome'
    },
    {
      name: 'Brands',
      path: '/seller/brand'
    }
  ]
};
const noData=ref(false);
const load=ref(true);
const productStore = useSellerProductStore();
const brandStore=useSellerBrandStore();
const userStore=useUserInfoStore();
const brandRouter=useRouter();
const columns=computed(()=>{
  let _columns=[
  {
    title: 'Brand',
    key:'amazonBrandName',
    dataIndex: 'amazonBrandName',
  },
  {
    title: 'Amazon Seller Account',
    key:'amazonName',
    dataIndex: 'amazonName',
    width:220
  },
  {
    title: 'Status',
    key:'status',
    dataIndex: 'status',
    width:130
  },
  {
    title: 'Active Products',
    key:'activeProductCount',
    dataIndex: 'activeProductCount',
    width:160
  },
  {
    title: 'Inactive Products',
    key:'inactiveProductCount',
    dataIndex: 'inactiveProductCount',
    width:180
  },
  {
    title: 'Action',
    key:'action',
    dataIndex:'action',
    width:160
  }
  ];

  if(_dataSource.value?.length>1){
    return _columns;
  }else{
    return _columns.filter(item=>item.key !== 'amazonName')
  }
});
const dataSource=ref([]);
const _dataSource=ref([]);
const pagination=reactive({
  total:30,
  defaultPageSize:10,
  size :10,
  current:1,
  showSizeChanger: true,
  pageSize:10
});

let keywords=ref('');
let status=ref('all');
let amazonUserId;
let modalConfigs=ref({
  open:false,
  modalType:'deactive'
});
const showSetCommission = ref(false);
const selectSingleProduct =ref('');
const singleRecord =ref({});//列表的单条数据
let selectedList=[];//批量操作品牌
onMounted(()=>{
  amazonUserId=brandStore?.params?.amazonUserId;
  getData();
})
onUnmounted(()=>{
  brandStore.setParams(null);
})
const getData= async()=>{
  getPartData()
}
const getPartData =(params)=>{
  load.value=true;
  let _params={
    keyword:keywords.value,
    status:status.value,
    page:1,
    size:pagination.size,
    amazonUserId:amazonUserId
  }
  let newparams={..._params,...params};
  pagination.current=newparams.page;
  newparams=Object.keys(newparams).reduce((acc, key) => {
    if(key == 'status' && newparams[key]=='all'){

    }else if(newparams[key] !== null && newparams[key] !== undefined && newparams[key] !== '' ) {
      acc[key] = newparams[key];
    }
    return acc;
  }, {});  
  brandApi.getPartSellerBrands(newparams).then(res=>{
    load.value=false;
    if(res.success){
      if(dataSource.length){
        document.querySelector('.brand').scrollIntoView({
        behavior: 'smooth'
        })
      } 
      res.data.data.map(item=>{
       item.commission=item?.commissionRate || 0;
      })
      if(!_dataSource.value.length){ 
        _dataSource.value=res.data.data;
      }
      dataSource.value=res.data.data;
      pagination.total=res.data.total;
      if(!res.data.data?.length){
        const status = userStore.userInfo.status;
        if (status === 'SELLER_PENDING_AGREED_TO_NOTICE') {
          noData.value = true;
          let _user=userStore.userInfo;
          _user.status='NORMAL';
          userStore.setUserInfo(_user);
        }else{
          noData.value = false;
        }
      }
    }else{
      const status = userStore.userInfo.status;
      if (status === 'SELLER_PENDING_AGREED_TO_NOTICE') {
        noData.value=true;
        let _user=userStore.userInfo;
        _user.status='NORMAL';
        userStore.setUserInfo(_user);
      }else{
        noData.value=false;
      }
    }
  });
}

const rowSelection = ref({
  getCheckboxProps:(record)=>{
  },
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(`11-selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  onSelect: (record, selected, selectedRows) => {
    selectedList.push(record);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    selectedList=selectedRows;  

    console.log('length',selectedList.length);
    
  },
});
const goBrandDetail =(item)=>{
  const routeData = brandRouter.resolve({
    path: `detail/${item.id}`
  });
  window.open(routeData.href, '_blank')
  // brandRouter.push({
  //   name:'brandDetail',
  //   params:{
  //     id:item.id,
  //   }
  // })
}
const goProductPage=(item,type)=>{
  let params={
    brandName:item.amazonBrandName,
    brandId:item.id,
  };
  if(type=='active'){
    params.status='active'
  }else{
    params.status='inactive'
  }
  productStore.setParams(params);
  brandRouter.push({
    name:'product'
  })

}
const onTableChange =({current,pageSize})=>{
  pagination.current=current;
  pagination.size =pageSize;
  pagination.pageSize=pageSize;
  getPartData({page:current,size:pageSize})
}
const onPageChange=(current)=>{
  pagination.current=current;
  getPartData({page:current})
}
const onSearch=()=>{
  getPartData({keyword:keywords.value})
}
const onStatusChange=(key)=>{
  status.value = key ;
  getPartData({status:key})
}
const onOpenModal=(record,modalType)=>{
  dataSource.value.map(v=>v.visible=false);
  singleRecord.value=record;
  switch (modalType){
    case 'deactive':
      modalConfigs.value.modalType='deactive';
      break;
    case 'setCommission':
    showSetCommission.value =true;
    setSelectSingleProduct(record.id);
      break;
    case 'active':
      modalConfigs.value.modalType='active';
      break;
  }
  if(modalType !== 'setCommission'){
    modalConfigs.value.open=true;
  }
 
}

const setSelectSingleProduct = id => {
  selectSingleProduct.value = id;
}
const onModalOK=(item)=>{
  switch (modalConfigs.value.modalType){
    case 'deactive':
      onDeactiveBrand(item);
      break;
    case 'setCommission':
      onSetCommission(item);
      break;
    case 'active':
      onActiveBrand(item);
      break;
  }
}
const onModalCancel=()=>{
  modalConfigs.value.open=false;
}
const onDeactiveBrand=(item)=>{
  modalConfigs.value.open=false;
  brandApi.disableBrand({id:item.id}).then(res=>{
    if(res.success){
      getData();
      message.success('Success!',1);
    }
  })
}
const setShowSetCommission = status => {
    showSetCommission.value = status;
  }
const onActiveBrand=(item)=>{
  modalConfigs.value.open=false;
  brandApi.activateBrand({id:item.id}).then(res=>{
    if(res.success){
      getData();
      message.success('Success!',1);
    }else{
    
      openNotification(res?.error);
    }
  })
}

</script>
<style lang="less" scoped>
  .brand-title{
    display: flex;
    align-items: center;
    .brand-font{
      word-break: break-all;
      flex: 1;
      margin-left: 12px;
      font-weight: 500;
      color: var(--dark-1);
      letter-spacing: 0.4px;
      line-height: 22px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-decoration: underline;
    }
    .brand-img-show{
      width: 56px;
      height: 56px;
      background: var(--bg-gray-2);
      border-radius: 8px;
      img{
        border-radius: 8px;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    
  }
  
  .table-font{
    font-family: Sora, sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: var(--dark-1);
    letter-spacing: 0.4px;
    line-height: 19px;
    cursor: pointer;
    text-decoration: underline;
  }
  .action-font{
    display: inline-block;
    cursor: pointer;
    border: 1px solid var(--border-1);
    height: 24px;
    border-radius: 8px;
    padding: 0 8px;
    opacity: 0.7;
    font-family: Sora, sans-serif;
    font-weight: 400;
    font-size: 13px;
    color: var(--dark-1);
    letter-spacing: 0.3px;
    line-height: 24px;
  }
  .brand{
    padding: 16px;
    padding-top: unset;
  }
  .table{
    padding: 0 16px;
    // margin: 0 16px;
    background-color: var(--color-white);
    border-radius: 8px;
    box-shadow: var(--section-box-shadow-6);
    // border: 2px solid #E8E5ED;
  }
  .table-head{
    display: flex;
    height: 64px;
    justify-content: space-between;
    align-items: center;
    .filters{
      display: flex;
      justify-content: space-between;
      .statusFilter{
        position: relative;
        z-index: 1;
        margin-left: 18px;
        // opacity: 0.7;
        font-family: Sora, sans-serif;
        font-weight: 400;
        font-size: 14px;
        color: var(--dark-2);
        letter-spacing: 0.4px;
        line-height: 20px;
      }
    }
   

  }
</style>
