<template>
  <div class="product">
    <breadcrumbs :breadcrumbsData="breadcrumbsData" #icon>
      <svg class="icon" aria-hidden="true" style="fill: #fff">
        <use xlink:href="#icon-shangpin"></use>
      </svg>
    </breadcrumbs>
    <a-spin :spinning="spinning">
      <div class="content-main">
        <filterBar />
        <div class="content">
          <chart />
          <div class="table">
            <tableContent />
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>
<script setup>

  import { ref } from 'vue';

  import breadcrumbs from '../../../seller/breadcrumbs.vue';
  import filterBar from '~/components/reports/filterBar.vue';
  import chart from '~/components/reports/chart.vue';
  import tableContent from '~/components/reports/tableContent.vue';

  const breadcrumbsData = {
    title: 'Product Reports',
    des: 'Last Update at: 2024-12-15  00:00',
    color:'#4682FA',
    hasTitleIcon:true,
    links: [
      {
        name: 'Home',
        path: '/#/publisher/reports'
      },
      {
        name: 'Reports',
        path: '/#/publisher/reports/product'
      },
      {
        name: 'Performance',
        path: '/#/publisher/reports/product'
      }
    ]
  };

  const spinning = ref(false);
  

</script>
<style lang="less" scoped>
  .product {
    padding:0 16px 16px;
  }
  .content-main {
    border-radius: 12px;
    overflow: hidden;
    border-radius: 12px;
    background-color: var(--color-white);
  }
  .content {
    padding: 15px;
  }
</style>
