<!--
 * @Author: Hankill(hao.chen)
 * @Date: 2024-12-18 17:27:58
 * @LastEditors: Hankill(hao.chen)
 * @LastEditTime: 2024-12-24 09:37:19
 * @FilePath: /web_artemisads/src/pages/publisher/index.vue
 * @Description: 
-->
/*
 * @Author: Gang Jiang 
 * @Date: 2024-11-25 15:26:36 
 * @Last Modified by: Gang Jiang
 * @Last Modified time: 2025-01-14 16:03:57
 */
<template>
  <div class="publisher">
    <div class="left">
      <left-menu :menus="menu" :bottomEntries="bottomEntries" />
    </div>
    <div class="right">
      <top-bar>
        <template #title>
          <p style="margin: 0 10px">Welcome to ArtemisAds</p>
        </template>
        <template #status v-if="store.userInfo.reviewStatus === 'Pending'">
          <a-tag class="cus-tag" color="warning" style=""
            >Account Pending Approval</a-tag
          >
        </template>
      </top-bar>
      <div class="content">
        <div class="min-height">
          <router-view />
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script setup>
import { h } from 'vue'
import topBar from '../../components/topBar.vue'
import Footer from '../../components/Footer.vue'
import LeftMenu from '../../components/LeftMenu.vue'

import { useUserInfoStore } from '~/storeData/index'

const store = useUserInfoStore()

const menu = [
  {
    key: '/publisher/products',
    label: 'Products Catalogue',
    icon: () => h('i', { class: 'iconfont icon-shangpin' }),
    routeName: 'products',
  },
  {
    key: '/publisher/brands',
    label: 'Brands Catalogue',
    routeName: 'brands',
    icon: () => h('i', { class: 'iconfont icon-pinpai' }),
  },
  {
    key: '/publisher/links',
    label: 'Product Links',
    routeName: 'links',
    icon: () => h('i', { class: 'iconfont icon-lianjie' }),
  },
  {
    key: '/publisher/profile',
    icon: () =>
      h('svg', { class: 'icon', 'aria-hidden': 'true' }, [
        h('use', { 'xlink:href': '#icon-a-shezhi2' }),
      ]),
    routeName: 'profile',
    title: 'Settings',
    label: 'Settings',
  },
]
const bottomEntries = [
  {
    key: '/publisher/referral',
    icon: () =>
      h('svg', { class: 'icon', 'aria-hidden': 'true' }, [
        h('use', { 'xlink:href': '#icon-zuanshi' }),
      ]),
    routeName: 'referral',
    title: 'Refer Your Friends',
    label: 'Refer Your Friends',
  },
]
</script>

<style lang="less" scoped>
.publisher {
  display: flex;
  width: 100dvw;
  height: 100dvh;
  overflow: hidden;
  // min-width: 1200px;
  background: #f4f2f7;
  :deep(label) {
    font-weight: normal;
  }
  .cus-tag {
    background-color: #fffbe6;
    border: 1px solid #ffe58f;
  }
  :deep(.content-wrapper) {
    display: flex;
    border-radius: 8px;
    padding: 16px 16px 0;
    background-color: #fff;
    box-shadow: var(--section-box-shadow-6);
  }
  .right {
    flex: 1;
    display: flex;
    overflow: hidden;
    flex-direction: column;
  }
  .content {
    padding: 0 16px;
    margin-top: 1px;
    overflow-y: auto;
    background: #f3f2f7;
    min-height: calc(100% - 48px);
  }
  .min-height {
    min-height: calc(100% - 73px);
  }
}
</style>