<template>
  <a-menu :class="className" v-bind="props">
    <slot></slot>
  </a-menu>
</template>
<script setup>
  import { computed } from 'vue';

  const defaultSize = 'middle'; // large small

  const props = defineProps(['size']);

  const className = computed(() => {
    let inputClass = 'artmisads-dropdown-menu'
    if (props.size) {
      inputClass += ` ${props.size}`;
    } else {
      inputClass += ` ${defaultSize}`;
    }
    return inputClass;
  })

</script>
<style lang="less" scoped>
  .artmisads-dropdown-menu {
    box-shadow: var(--section-box-shadow);
    padding: 8px;
    :deep(.ant-dropdown-menu-item) {
      &:nth-child(n+2) {
        margin-top: 2px;
      }
      &:hover {
        background: var(--bg-gray-6);
      }
      .actions-item {
        display: flex;
        justify-content: space-between;
        .num {
          min-width: 20px;
          align-self: center;
          margin-left: 4px;
          height: 18px;
          padding-left: 4px;
          padding-right: 4px;
          background: var(--yellow-1);
          border-radius: 4px;
          font-weight: 500;
          font-size: var(--font-size-mormal);
          color: var(--dark-1);
          text-align: center;
          line-height: 18px;

        }
      }
    }
    // &.large {
    // }
    // &.small {
    // }
  }
</style>
