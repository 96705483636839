

<template>
  <div class="menu">
    <div @click="toggleCollapsed" class="toggle-menu">
      <svg class="icon" aria-hidden="true"  v-if="state.collapsed">
        <use xlink:href="#icon-zhankai"></use>
      </svg>
      <svg class="icon" aria-hidden="true" v-else>
        <use xlink:href="#icon-shouqi"></use>
      </svg>
    </div>
    <div class="logo" :class="state.collapsed ? 'small-logo' : 'large-logo'">
      <img src="../../../assets/images/img/artemisads-logo.svg"/>
    </div>
    <div class="content">
      <a-menu
        @click="onClick"
        v-model:openKeys="state.openKeys"
        v-model:selectedKeys="state.selectedKeys"
        mode="inline"
        :inline-collapsed="state.collapsed"
        :items="items"
      ></a-menu>
    </div>
  </div>
</template>

<script setup>
  import menu1Svg from '../../../components/svg/menu1.vue';
  import { reactive, watch, h, onMounted} from 'vue';
  import {
    ShoppingOutlined,
    CrownOutlined,
    UserOutlined,
    AreaChartOutlined
  } from '@ant-design/icons-vue';
  import { useRoute } from 'vue-router';
  const route=useRoute();
  const state = reactive({
    collapsed: false,
    selectedKeys: ['/seller/product'],
  });
  const items = reactive([
    // {
    //   key: '/operator/dashboard',
    //   icon:()=> h('svg', { class: 'icon', 'aria-hidden': 'true' }, 
    //   [ h('use', { 'xlink:href': '#icon-shangpin' })]),
    //   routeName:'dashboard',
    //   title: 'Dashboard',
    //   label:'Dashboard'
    // },

    {
      key: '/operator/users',
      icon:()=> h('svg', { class: 'icon', 'aria-hidden': 'true' }, 
      [ h('use', { 'xlink:href': '#icon-a-wode1' })]),
      routeName:'users',
      title: 'Users',
      label:'Users'
    },
    {
      key: '/operator/seller',
      icon:()=> h('svg', { class: 'icon', 'aria-hidden': 'true' }, 
      [ h('use', { 'xlink:href': '#icon-a-wode4' })]),
      routeName:'seller',
      title: 'Sellers',
      label:'Sellers'
    },
    {
      key: '/operator/seller-report',
      icon:()=> h(AreaChartOutlined),
      routeName: "seller-report",
      title: "Sellers' Report",
      label:"Sellers' Report"
    },
    {
      key: '/operator/publisher',
      icon:()=> h('svg', { class: 'icon', 'aria-hidden': 'true' }, 
      [ h('use', { 'xlink:href': '#icon-a-wode4' })]),
      routeName:'publisher',
      title: 'Publisher',
      label:'Publisher'
    },
    {
      key: '/operator/commissions',
      icon:()=> h(AreaChartOutlined),
      routeName: "commissions",
      title: "Commissions",
      label:"Commissions"
    },
    
  ]);
  onMounted(()=>{
    getSelected();
  })
  const getSelected=()=>{
    let key;
    let arr=route.path.split('/');
    // console.log(route,arr);
    
    items.forEach(v=>{
      if(v.routeName==arr[2]){
        key=v.key;
      }
    })
    state.selectedKeys=[];
    state.selectedKeys.push(key);
    
  }
  const onClick = ({key}) => {
    location.href = key;
  }
  watch(
    () => state.openKeys,
    (_val, oldVal) => {
      state.preOpenKeys = oldVal;
    },
  );
  watch(()=>route.path,(val)=>{
    getSelected();
    
  });
  const toggleCollapsed = () => {
    state.collapsed = !state.collapsed;
    state.openKeys = state.collapsed ? [] : state.preOpenKeys;
  };
</script>

<style lang="less" scoped>
  .menu {
    // box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-top: 24px;
    position: relative;
    background: var(--color-white);
    border-right: 1px solid var(--border-4);
    .toggle-menu {
      position: absolute;
      z-index: 4;
      cursor: pointer;
      right:-24px;
      top: 10px;
      width: 24px;
      height: 28px;
      background: #FFFFFF;
      border: 1px solid var(--border-4);
      box-shadow: 0 1px 0 0 var(--box-shadow-2);
      border-radius: 0 8px 8px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: var(--dark-3);
      .icon{
          font-size: 12px;
        }
      &:hover{
        color: var(--dark-3);
        background-color:var(--bg-gray-1) ;
        border-color: var(--border-1);
      }
      &:active{
        color: var(--dark-2);
        background-color:var(--bg-gray-2) ;
        border-color: var(--bg-gray-7);
      }

    }
    .logo {
      width: 60px;
      height: 40px;
      overflow: hidden;
      transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
      position: relative;
      img {
        position: absolute;
        left: 20px;
        top: 0;
        min-width: 176px;
        width: 176px;
        height: 40px;
      }
    }
    .large-logo {
      width: 232px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    margin:16px 8px 0;
    :deep(.ant-menu) {
      border-inline-end: none;
      &.ant-menu-inline-collapsed{
        width: 64px !important;
      }
      .ant-menu-item{
        font-size: 15px;
        font-weight: 500;
        color: var(--dark-1);
        letter-spacing: 0.4px;
        line-height: 20px;
        border-radius: 50px;
        height: 40px;
        position: relative;
        border-radius: 8px !important;
        padding-left: 16px !important;
        &:hover{
          background-color:var(--bg-gray-6);
        }
        &:active{
          background-color:var(--bg-gray-4);
        }
      }
      .ant-menu-item-icon{
        font-size: 24px;
        margin-right:2px;
        height: 40px;
      }
      .ant-menu-item-selected{
        opacity: 1;
        background-color:var(--primary-selectd);
        color: var( --primary-text);
        border-radius: 50px;
        font-weight: 600;
        &:hover{
          background-color:var(--primary-selectd);
        color: var( --primary-text);
        }
        &::after{
          position: absolute;
          top: 10px;
          left: 0;
          width:3px;
          height: 20px;
          background-color:var(--primary-text);
          border-radius: 50px;
        }
        .ant-menu-item-icon{
          color: var( --primary-text);
          opacity: 1;

        }
      }
    }
  }
</style>