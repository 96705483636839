<template>
  <div class="sign-in">
    <div class="sign-left">
      <signInUpLeft />
    </div>
    <div class="sign-right">
      <div class="sign-wrap">
        <a-spin :spinning="spinning">
          <h3>Sign In</h3>
          <div class="to-sign-up">
            Don’t have an account yet? <a class="link" href="/sign-up">Join ArtemisAds</a>
          </div>
          <a-form>
            <a-form-item
              name="email"
              v-bind="validateInfos.email"
            >
              <div class="sign-input">
              <span>Email Address</span>
              <artmisads-input @blur="() => validate('email')" v-model:value="modelRef.email"/>
            </div>
            </a-form-item>
          </a-form>
          <a-form-item 
            v-bind="validateInfos.password"
          >
            <div class="password-input">
              <span>Password</span>
              <artmisads-input-password v-model:value="modelRef.password" style="height: 40px;"/>
            </div>
          </a-form-item>
          <div class="submit-btn">
            <a-form-item>
              <artmisads-button type="primary" html-type="submit" @click="onSignIn">Sign In</artmisads-button>
            </a-form-item>
          </div>
          <div  class="forgot">
            <a href="/find-password" >Forget Your Password?</a>
            
          </div>
          <div class="bottom-text">
            By joining or signing in, you agree to our <a class="link" target="_blank" href="/terms-of-service">Terms of Service</a> and <a class="link" target="_blank" href="/privacy-policy">Privacy Policy</a>
          </div>
          </a-spin>
        </div>
    </div>
    <contextHolder />
  </div>
</template>
<script setup>

  import { reactive, ref } from 'vue';
  import { signApi } from '../../server/index'
  import jwt from 'jsonwebtoken';
  import { Form, notification } from 'ant-design-vue';

  import { useUserInfoStore } from '../../storeData/index';

  import signInUpLeft from '../../components/signInUpLeft.vue';

  const spinning = ref(false);
  const store = useUserInfoStore();
  const [api, contextHolder] = notification.useNotification();
  const openNotification = description => {
    api.info({
      message: `Warning`,
      description: description,
      placement: 'topRight',
      duration: 10
    });
  };

  const useForm = Form.useForm;
  const modelRef = reactive({
    email: '',
    password: '',
  });

  const rulesRef = reactive({
    email: [{
      required: true,
      message: 'Invalid email',
      type: 'email',
      trigger: ['blur']
    }],
    password: [{
      required: true,
      message: 'Password cannot be empty',
      trigger: ['blur']
    }]
  });

  const { validate, validateInfos } = useForm(modelRef, rulesRef);

  async function onSignIn () {
    const validateRes = await validate();
    if (!validateRes.errorFields) {
      spinning.value = true;
      const params = validateRes;
      const res = await signApi.signIn(params);
      const { data, success, error } = res;
      if (success) {
        let href = '/seller/welcome';
        const userToken = data.token;
        const userInfo = jwt.decode(userToken);
        if (userInfo.userType === "publisher") {
          href = '/publisher/profile';
        }
        if(userInfo.userType === "publisher" && userInfo.reviewStatus === 'Rejected') {
            href = '/profile';
        }
        if (userInfo.reviewStatus === 'Review') {
          openNotification('We are currently reviewing your information and will usually provide you with the review result within 1 - 2 working days. Please pay attention to check your email.');

          spinning.value = false;
          return;
        }
        
        store.setUserInfo(userInfo, userToken);

        if (userInfo.status === 'PENDING_CHOOSE_TYPE') {
          href = '/profile';
        }
        location.href = href;
      } else {
        openNotification(error)
      }
      spinning.value = false;
    }
  }
</script>
<style lang="less" scoped>
  .sign-in {
    width: 100dvw;
    height: 100dvh;
    min-height: 700px;
    overflow: hidden;
    display: flex;
  }
  .sign-right {
    display: flex;
    justify-content: center;
    background: var(--big-bg-1);
    flex: 1;
  }
  .sign-wrap {
    padding: 48px 40px 50px 40px;
    width: 480px;
    height: 530px;
    min-height: 520px;
    margin-top: 140px;
    background: var(--color-white);
    box-shadow: var(--section-box-shadow-2);
    border-radius: 16px;
    .to-sign-up {
      color: var(--dark-3);
      line-height: 20px;
      margin-top: 8px;
      > a {
        font-weight: 500;
        line-height: 20px;
        color: var(--link-1);
        text-decoration: underline;
      }
    }
    .password-input {
      display: flex;
      flex-wrap: wrap;
      margin-top: 24px;
      > span {
        font-weight: 500;
        color: var(--dark-2);
        line-height: 16px;
        margin-bottom: 8px;
      }
      
    }
    .sign-input {
      display: flex;
      flex-wrap: wrap;
      margin-top: 40px;
      > span {
        height: 16px;
        color: var(--dark-2);
        font-weight: 500;
        line-height: 16px;
        margin-bottom: 8px;
      }
    }
    .submit-btn {
      margin-top: 32px;
      button {
        width: 100%;
      }
    }
    .forgot{
      text-align: center;
      >a{
        text-decoration: underline;
        color: var(--dark-3);
        &:hover {
          color: var(--dark-2);
        }
      }
     
    }
    .bottom-text {
      margin-top: 24px;
      color: var(--dark-3);
      font-size: var(--font-size-small);
      line-height: 18px;
      text-align: left;
      > a {
        text-decoration: underline;
        color: var(--dark-3);
        &:hover {
          color: var(--dark-2);
        }
      }
    }
    :deep(.ant-form) {
      .ant-form-item {
        margin-bottom: 0;
      }
    }
  }

// @media screen and (max-width: 479px) {
//   .sign-in {
//     width: 100dvw;
//     height: 100dvh;
//     overflow: hidden;
//     display: flex;
//   }
//   .sign-right {
//     width: 100%;
//     height: 100%;
//     display: flex;
//     justify-content: center;
//     .sign-wrap {
//       align-self: center;
//       position: relative;
//       z-index: 1;
//       width: calc(100dvw - 32px);
//       padding: 24px 32px;
//       width: 343px;
//       background: #FFFFFF;
//       box-shadow: 0 1px 0 0 #1a16250d;
//       border-radius: 16px;
//     }
//     > h3 {
//       font-family: Sora, sans-serif;
//       font-weight: 500;
//       font-size: 28px;
//       color: #0F0629;
//       letter-spacing: 0.5px;
//       line-height: 38px;
//     }
//     .to-sign-up {
//       color: #6a647d;
//       font-size: 14px;
//       font-weight: 400;
//       line-height: 19px;
//       letter-spacing: 0.4px;
//       text-align: left;
//       margin-top: 8px;
//       > a {
//         display: block;
//         font-size: 14px;
//         font-weight: 500;
//         line-height: 20px;
//         letter-spacing: 0.4px;
//         text-align: left;
//       }
//     }
//     :deep(.ant-form) {
//       .ant-form-item {
//         margin-bottom: 0;
//       }
//     }
//     .password-input {
//       display: flex;
//       flex-direction: column;
//       margin-top: 40px;
//       > span {
//         opacity: 0.7;
//         font-family: Sora, sans-serif;
//         font-weight: 500;
//         font-size: 14px;
//         color: #0F0629;
//         letter-spacing: 0.4px;
//         line-height: 16px;
//         &:first-child {
//           margin-bottom: 8px;
//         }
//         input {
//           height: 40px;
//         }
//       }
//     }
//     .sign-input {
//       margin-top: 32px;
//       display: flex;
//       flex-direction: column;
//       > span {
//         height: 16px;
//         opacity: 0.7;
//         font-family: Sora, sans-serif;
//         font-weight: 500;
//         font-size: 14px;
//         color: #0F0629;
//         letter-spacing: 0.4px;
//         line-height: 16px;
//         margin-bottom: 8px;
//       }
//     }
//     .submit-btn {
//       margin-top: 40px;
//       button {
//         width: 100%;
//         height: 40px;
//         background: #7D58E5;
//         box-shadow: 0 1px 0 0 #1a16250d;
//         border-radius: 20px;
//         font-family: Sora, sans-serif;
//         font-weight: 500;
//         font-size: 15px;
//         color: #FFFFFF;
//         letter-spacing: 0.4px;
//         text-align: center;
//       }
//     }
//     .bottom-text {
//       margin-top: 40px;
//       color: #0f0629;
//       color: #0f0629;
//       font-size: 13px;
//       font-weight: 400;
//       line-height: 18px;
//       letter-spacing: 0.4px;
//       text-align: left;
//       > a {
//         text-decoration: underline;
//       }
//     }
//   }
// }

</style>
