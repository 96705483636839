<template>
    <div class="profile">
        <breadcrumbs :breadcrumbsData="breadcrumbsData" #icon>
        <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-a-shezhi2"></use>
        </svg>
        </breadcrumbs>
        <div class="profile-wrap">
            <div class="profile-header">
            <div class="profile-title">
                <h4 style="font-size: 20px;">Proﬁle</h4>
                <p class="profile-subtitle">This proﬁle is public to brands</p>
            </div>
            <artmisads-select-button @click="onOpen" iconName="gengxin">Update Publisher/Creator Proﬁle </artmisads-select-button>
        </div>
        <div class="profile-detail">
            <div class="avatar">
                <img :src="imgCache(userInfo.publicImage || userInfo.publicImageSource)" />
            </div>
            <div class="info">
                <a-row gutter="20" justify="space-between">
                    <a-col span="16">
                        <a-col span="24">
                            <p class="label" style="margin-top: 10px;">Bio:</p>
                            <p class="field" style="width: 580px;min-height: 50px;font-weight: 400;">
                                {{  userInfo.publicBio  }}
                            </p>
                        </a-col>
                        <a-col span="24">
                            <p class="label">Links:</p>
                            <p class="field-links" style="width: 580px;">
                                <template v-for="item in userInfo.links">
                                <a class="profile-links" :href="item.url" target="_blank" v-if="item.url">
                                    <svg-icon class="icon-app-default" name="lianjie" style="font-size: 12px;padding: 4px;" v-if="item.name === 'Other' || item.name === 'Website/Blog'"/>
                                    <svg-icon class="icon-app" v-else :name="item.name.toLowerCase()" />
                                    <span>{{item.name}}</span>
                                    <svg-icon name="jiantou-you-cu" class='arrow-icon' />
                                </a>
                                </template>
                            </p>
                        </a-col>
                    </a-col>
                    <a-col span="8">
                        <a-col span="24">
                            <p class="label-2" style="margin-top: 10px;">Creator/Publisher Name:</p>
                            <p class="field">{{ userInfo.creatorName }} </p>
                        </a-col>
                        <a-col span="12">
                            <p class="label-2">Product Categories:</p>
                            <p class="field">{{ categories.filter(item => userInfo.promotionProductCategoryIds?.indexOf(item.value) !== -1).map(item => item.label).join(',') }} </p>
                        </a-col>
                        <a-col span="12">
                            <p class="label-2">Email:</p>
                            <p class="field">{{ userInfo.creatorEmail }}</p>
                        </a-col>
                    </a-col>
                </a-row>
            </div>
        </div>
        </div>
        <!-- <div class="profile-wrap" v-if="reviewStatus">
            <div class="profile-header">
            <div class="profile-title">
                <h4>Link Setting</h4>
            </div>
            <artmisads-select-button @click="openTags=true" iconName="bianji">Update Default Tracking Tags </artmisads-select-button>
        </div>
        <div class="profile-detail" style="display: flex; gap: 14px;flex-wrap: wrap;">
          <div class="profile-website" v-for="item in userInfo.trackingTags" :key="item.countryCode">
              <p class="website-name"><svg-icon :name="`guoqi-${item.countryCode.toLowerCase()}`" style="margin-right: 4px;vertical-align: -0.2em;" />{{item.site}}</p>
              <div class="tag-box"><span v-if="item.tag"><p class="tag-label">Default Tracking Tags</p><p :title="item.tag" class="two-line-text ">{{item.tag}}</p></span>
                <span v-else><p class="tag-label">Default Tracking Tags</p><span style="color: #0F0629; opacity: 0.3;">--</span></span>
                <svg-icon style="font-size: 15px;color: #0F0629; opacity: 0.5;" name="bianji2" />
              </div>
          </div>
        </div>
        </div> -->
        <a-drawer
              v-model:open="open"
              class="custom-class"
              root-class-name="root-class-name"
              :root-style="{ color: 'blue' }"
              width="800"
              title="Update Pulisher/Creator Proﬁle"
              placement="right"
              @after-open-change="afterOpenChange"
          >
          <div style="display: flex; justify-content: space-between;">
          <a-form-item v-bind="validateInfos.publicImage">
          <div class="input" style="width: 200px;margin-right: 80px;">
            <span>Image</span>
            <UploadImage 
              :configs="{
                maxCount:1,
                maxSize:5,
                minWidth:600,
                minHeight:600,
                spin:true
              }" 
              @upload-file="uploadImg" 
              #content
            >
            <div class="avatar-uploader">
              <div v-if="modelRef.publicImageSource" class="upload-img avatar">
                <img :src="imgCache(modelRef.publicImage || modelRef.publicImageSource)" alt="">
              </div>
              <div v-else class="upload-icon"> 
                <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-shangchuan-xi"></use>
                </svg>
              </div>
            </div>
            <div style="text-align: center;margin-top: 15px;" v-if="modelRef.publicImageSource">
                <artmisads-select-button size="small" style="width: 100%;" @click="onEditImg">Edit Image</artmisads-select-button>
                <p class="subtitle-btn">Replace Image</p>
            </div>
          </UploadImage>
          <image-cropper 
            @cropped-file="cropperImg"
            @onCancel="openCropper = false"
            :open="openCropper" 
            :coverFile ="modelRef.publicImageSource"
            />
          </div>
          </a-form-item>
          <a-form>
            <a-form-item 
              v-bind="validateInfos.creatorName"
            >
              <div class="input">
                <span>Publisher/Creator Name</span>
                <artmisads-input v-model:value="modelRef.creatorName" @blur="() => validate('creatorName')"  />
              </div>
            </a-form-item>
            <a-form-item
              v-bind="validateInfos.creatorEmail"
            >
              <div class="input" style="margin-top: 32px;">
                <span>Publisher/Creator Email</span>
                <artmisads-input type="email" v-model:value="modelRef.creatorEmail" @blur="() => validate('creatorEmail')" />
              </div>
            </a-form-item>
            <a-form-item
                v-bind="validateInfos.publicBio"
            >
                <div class="input" style="margin-top: 22px;">
                <span>Public Bio</span>
                <artemisads-textarea :rows="4" v-model:value="modelRef.publicBio" @blur="() => validate('publicBio')" />
                <!-- <p class="tips" style="margin-top: 8px;">You can edit your bio and other proﬁle details directly on the platform.</p> -->
                </div>
            </a-form-item>
            <a-form-item 
              v-bind="validateInfos.promotionCountryCodes"
            >
              <div class="input">
                <span>Target countries for promotion</span>
                <artmisads-select size="large" v-model:value="modelRef.promotionCountryCodes" mode="multiple" :options="countries">
                  <template #option="{ value: val, label }">
                    <span role="img" :aria-label="val" style="font-size: 15px;"><svg-icon :name="`guoqi-${val.toLowerCase()}`" /></span>
                    &nbsp;&nbsp;{{ label }}
                    </template>
                    <template #tagRender="{ value: val, label, closable, onClose, option }">
                    <a-tag :closable="closable" style="margin-right: 3px;padding-right: 0;display: flex;align-items: center;justify-content: center;" @close="onClose">
                      <span role="img" :aria-label="val" style="font-size: 15px;"><svg-icon :name="`guoqi-${val.toLowerCase()}`" style="vertical-align: -0.16em;" /></span>
                      &nbsp;&nbsp;{{ label }}
                      <template #closeIcon>
                        <span class="ant-select-selection-item-remove" unselectable="on" aria-hidden="true" style="user-select: none;"><span role="img" aria-label="close" class="anticon anticon-close"><svg focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true" fill-rule="evenodd" viewBox="64 64 896 896"><path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path></svg><!----></span></span>
                      </template>
                    </a-tag>
                  </template>
                </artmisads-select>
              </div>
            </a-form-item>
            <a-form-item
              v-bind="validateInfos.promotionProductCategoryIds"
            >
              <div class="input" style="margin-top: 32px;">
                <span>Mostly promoted product categories</span>
                <artmisads-select size="large" v-model:value="modelRef.promotionProductCategoryIds" mode="multiple" :options="categories"/>
              </div>
            </a-form-item>
            <a-form-item
              v-bind="validateInfos.promotionMethodIds"
            >
              <div class="input" style="margin-top: 32px;">
                <span>Primary promotion methods</span>
                <a-checkbox-group v-model:value="modelRef.promotionMethodIds" style="width: 100%">
                    <a-row>
                        <a-col :span="12" v-for="(item, index) in methods" style="margin-bottom: 9px;">
                            <artemisads-checkbox :value="item.value">
                              <span class="label-checkobox">
                                {{ item.label }}
                              </span>
                            </artemisads-checkbox>
                        </a-col>
                    </a-row>
                </a-checkbox-group>
              </div>
            </a-form-item>
            <a-form-item style="margin-top:40px">
              <artemisads-divid dashed="dashed"></artemisads-divid>
            </a-form-item>
            <a-form-item
               v-bind="validateInfos.links"
               :validateStatus="modelRef.links[modelRef.links.findIndex(item => item.id == 3)].isUrl ? '' : 'error'"
            >
            <div class="link-card">
                <h3 class="title">Your Links</h3>
                <p class="subtitle">You must provide at least one link, but not for every ﬁeld. Brands use these URLs to assess your audience and content. Optimize your proﬁle for approval by including relevant links and excluding any unrelated social media URLs.</p>
                <a-form-item
                  :validateStatus="modelRef.links[modelRef.links.findIndex(item => item.id == 3)].isUrl ? '' : 'error'"
                >
                <div class="input" style="margin-top: 24px;">
                    <span>Website / Blog</span>
                    <artmisads-input @blur="(v) => onUrlChange(v, modelRef.links.findIndex(item => item.id == 3))" v-model:value="modelRef.links.filter(item => item.id == 3)[0].url"/>
                    <div v-if="!modelRef.links[modelRef.links.findIndex(item => item.id == 3)].isUrl" class="ant-form-item-explain-error" style="">Please input the accurate URL</div>
                </div>
                </a-form-item>
                <a-form-item
                >
                <div class="input" style="margin-top: 24px;">
                    <span>Social</span>
                    <template v-for="(item, index) in modelRef.links">
                      <a-form-item
                          :key="index"
                          v-if="item.id != 2 && item.id != 3"
                          style="margin-bottom: 12px;"
                          :validateStatus="item.isUrl ? '' : 'error'"
                          >
                            <artmisads-input @blur="(v) => onUrlChange(v, index)" atype="addonBefore" :placeholder="`Url（Type：${item.name} Account URL）`" v-model:value="item.url">
                              <template #addonBefore>
                                <div class="social-addon">
                                  <svg-icon class="icon-app" :name="item.name.toLowerCase()" />
                                  <span class="main-text" style="margin-left: 12px;"> {{ item.name }}</span>
                                </div>
                              </template>
                            </artmisads-input>
                            <div v-if="!item.isUrl" class="ant-form-item-explain-error" style="">Please input the accurate URL</div>
                      </a-form-item>
                    </template>
                    <a-form-item style="margin-top: 28px;" v-if="modelRef.extraSocial.length>0">
                        <artmisads-select size="large" value="+ Select to Add Other Platform" placeholder="+ Select to Add Other Platform" :options="modelRef.extraSocial" @change="onAddSocial">
                          <template #option="{ value: val, label }">
                            <span role="img" :aria-label="val" style="font-size: 18px;vertical-align: -0.1em;"><svg-icon style="border-radius: 4px; overflow: hidden;" :name="label.toLowerCase()" /></span>
                            &nbsp;&nbsp;{{ label }}
                          </template>
                        </artmisads-select>
                    </a-form-item>
                </div>
                </a-form-item>
                <a-form-item
                  :validateStatus="modelRef.links[modelRef.links.findIndex(item => item.id == 2)].isUrl ? '' : 'error'"
                >
                    <div class="input" style="margin-top: 32px;">
                        <span>Other</span>
                        <artmisads-input @blur="(v) => onUrlChange(v, modelRef.links.findIndex(item => item.id == 2))" v-model:value="modelRef.links.filter(item => item.id == 2)[0].url"/>
                        <div v-if="!modelRef.links[modelRef.links.findIndex(item => item.id == 2)].isUrl" class="ant-form-item-explain-error" style="">Please input the accurate URL</div>
                    </div>
                </a-form-item>
            </div>
            </a-form-item>
            </a-form> 
        </div>
        <template #extra>
          <a-space>
            <artmisads-button type="primary" @click="onSubmit">Update</artmisads-button>
          </a-space>
        </template>
        </a-drawer>
        <!-- <a-drawer
            v-model:open="openTags"
            class="custom-class"
            root-class-name="root-class-name"
            :root-style="{ color: 'blue' }"
            width="550"
            title="Set Default Custom Tracking Tags"
            placement="right"
        >
            <p class="profile-subtitle" style="margin-left: 0;">This will set the default custom tracking tag for all links where the option is enabled.</p>
            <a-form
            
            >
                <a-form-item 
                v-for="(item, index) in modelRef.trackingTags"
                :key="item.countryCode"
                v-bind="validateInfosTk[item.countryCode]"
                >
                  <div class="input">
                    <span style="font-size: 14px;">{{ item.countryCode }} Custom Tracking Tags <span class="website-name"><svg-icon :name="`guoqi-${item.countryCode.toLowerCase()}`" style="margin-right: 4px;vertical-align: -0.2em;" />{{item.site}}</span></span>
                    <artmisads-input @blur="() => validateTk(`${item.countryCode}`)" v-model:value="modelRefTk[item.countryCode]" />
                  </div>
                </a-form-item>
            </a-form>
            <template #extra>
              <a-space>
                <artmisads-button type="primary" @click="onTagsSubmit">Save</artmisads-button>
              </a-space>
            </template>
        </a-drawer> -->
    </div>
</template>

<script setup>
import { ref, reactive, toRaw, onMounted, computed } from 'vue';
import { Form, message } from 'ant-design-vue';
import _ from 'lodash';

import profileApi from '~/server/register'

import { imgCache } from '~/utils';
import breadcrumbs from '../../seller/breadcrumbs.vue';

import SvgIcon from '~/components/SvgIcon.vue';
import ImageCropper from '~/components/ImageCropper.vue';
import UploadImage from '~/components/uploadImage.vue';

import { useUserInfoStore } from '~/storeData/index';

const store = useUserInfoStore();

const breadcrumbsData = {
  title: 'Team Settings',
  des: 'Review your public team profile and manage team members',
  color:'#FFD358',
//   hasTitleIcon:true,
  links: [
    {
      name: 'Home',
      path: '/publisher/profile'
    },
    {
      name: 'Settings',
      path: '/publisher/profile',
    },
    {
      name: 'Team',
      path: '/publisher/profile'
    }
  ]
};

const SOCIAL_MAP = [
        {
            "id": 4,
            "name": "Tiktok",
            "url": null
        },
        {
            "id": 5,
            "name": "Instagram",
            "url": null
        },
        {
            "id": 6,
            "name": "Facebook",
            "url": null
        },
        {
            "id": 7,
            "name": "YouTube",
            "url": null
        },
        {
            "id": 8,
            "name": "Pinterest",
            "url": null
        },
        {
            "id": 9,
            "name": "Red",
            "url": null
        },
        {
            "id": 10,
            "name": "WeChat",
            "url": null
        },
        {
            "id": 11,
            "name": "Weibo",
            "url": null
        },
        {
            "id": 12,
            "name": "LinkedIn",
            "url": null
        },
        {
            "id": 13,
            "name": "X",
            "url": null
        }
    ]

const DEFAULT_TAGS =  [{
          countryCode: "US",
          site: "amazon.com",
          tag: ""
      }, {
          countryCode: "CA",
          site: "amazon.ca",
          tag: ""
      }, {
          countryCode: "DE",
          site: "amazon.de",
          tag: ""
      }, {
          countryCode: "FR",
          site: "amazon.fr",
          tag: ""
      }, {
          countryCode: "UK",
          site: "amazon.uk",
          tag: ""
      }];

const useForm = Form.useForm;

const open = ref(false);
const openTags =ref(false);
const openCropper = ref(false);

const categories = ref([]);
const countries = ref([]);
const methods = ref([]);

const reviewStatus = ref(false);

const userInfo = reactive({
    links: [],
    trackingTags: []
});

const modelRef = reactive({
    promotionCountryCodes: [],
    promotionProductCategoryIds: [],
    promotionMethodIds: [],
    creatorName: '',
    creatorEmail: '',
    publicImage: '',
    publicImageSource: '',
    publicBio: '',
    links: [{
    name: 'Other',
    id: 2,
    url: '',
    isUrl: true
    }, {
    name: 'Website/Blog',
    id: 3,
    url: '',
    isUrl: true
    }, {
    name: 'Facebook',
    url: '',
    id: 6,
    isUrl: true
    }, {
    name: 'Instagram',
    url: '',
    id: 5,
    isUrl: true
    }, {
    name: 'YouTube',
    url: '',
    id: 7,
    isUrl: true
    }, {
    name: 'Tiktok',
    url: '',
    id: 4,
    isUrl: true
    }, {
    name: 'X',
    url: '',
    id: 13,
    isUrl: true
    }],
    extraSocial: [{
        label: 'Red',
        value: 9
    }, {
        label: 'WeChat',
        value: 10
    }, {
        label: 'Weibo',
        value: 11
    }, {
        label: 'Pinterest',
        value: 8
    }, {
        label: 'LinkedIn',
        value: 12
    }],
    trackingTags: []
});
const modelRefTk = reactive({
  US: '',
  CA: '',
  DE: '',
  FR: '',
  UK: ''
});
const rulesRef = reactive({
      creatorName: [{
        required: true,
        message: 'Publisher/Creator Name cannot be empty',
        trigger: ['blur']
      }],
      creatorEmail: [{
        required: true,
        message: 'Email cannot be empty',
        trigger: ['change']
      }, {
        type: 'email',
        message: 'Invalid email',
        trigger: ['blur']
      }],
      promotionMethodIds: [{
        required: true,
        message: 'Please select at least one method',
      }],
      promotionCountryCodes: [{
        required: true,
        message: 'Please select at least one country.',
      }],
      promotionProductCategoryIds: [{
        required: true,
        message: 'Please select at least one category.',
      }],
      // publicImage: [{
      //   required: true,
      //   message: 'Public Image cannot be empty',
      // }],
      publicBio: [{
        required: true,
        message: 'A proﬁle bio is required.',
        trigger: ['change']
      }, {
        min: 10,
        message: 'It should have a minimum of 10 characters.',
        trigger: ['blur']
      }, {
        max: 10000,
        message: 'It must not exceed 10,000 characters',
        trigger: ['blur']
      }],
      links: [{
        validator: (rule, value, callback) => {
          const count = value.filter(item=> {
              return item.url !== ''
          }).length;
          if (!count) {
            callback('Please provide at least one link.');
          }
          const isUrl = value.filter(item=> {
              return !item.isUrl
          }).length;
          if (isUrl > 0) {
            callback('');
          }
          callback()
        },
      }],
    });

const rulesRefTrack = computed(() => {
  let obj = {};
  modelRef.trackingTags.map(item => {
    obj[item.countryCode] = [{
        pattern: '^[a-zA-Z0-9_-]+$',
        trigger: 'blur',
        message: 'Only numbers, letters, hyphens and underscores are allowed.'
    }];
  }) 
  return obj;
})
const { validate, validateInfos } = useForm(modelRef, rulesRef);

const { validate: validateTk, validateInfos: validateInfosTk} = useForm(modelRefTk, rulesRefTrack)

const onUrlChange = (v, index) => {
        if (v.target.value) {
          const regex = /^(https?:\/\/)([a-zA-Z0-9_-]+\.)+[a-zA-Z]{2,}(\/[^\s]*)?$/;
          modelRef.links[index].isUrl = regex.test(v.target.value);
        } else {
          modelRef.links[index].isUrl = true;
        }
}

onMounted(() => {
  const userInfo = store.userInfo
  if (userInfo.reviewStatus !== 'Pending') {
    reviewStatus.value = true;
  }
})

const onSubmit = () => {
      validate().then(res => {
        if (!res.errorFields) {
          let formState = {...toRaw(modelRef)};
          profileApi.updateProfile(formState).then(res => {
            console.log(res);
              if(res.success) {
                  message.success('Update Success!')
                  Object.assign(userInfo, formState)
                  userInfo.links = _.cloneDeep(formState.links);
                  onClose();
              }
          });
        }
      })
}

const onTagsSubmit = () => {
  validateTk().then(res => {
    if (!res.errorFields) {
        modelRef.trackingTags.map(item => {
           item.tag = modelRefTk[item.countryCode];
        });
        profileApi.updateTrackingTags(modelRef.trackingTags).then(res => {
        if(res.success) {
          message.success('Save Success!');
          userInfo.trackingTags = _.cloneDeep(modelRef.trackingTags);
          openTags.value = false;
        }
    })
    }
  })
}

const uploadImg = (res) => {
      try {
        modelRef.publicImageSource = res.data.url;
        console.log(modelRef.publicImageSource);
        openCropper.value = true;
      } catch (error) {
        console.log('upload-false');
        
      }
  }

  const cropperImg = (res =>{
    try {
        modelRef.publicImage = res.data.url;
        openCropper.value = false;
      } catch (error) {
        console.log('upload-false');
      }
})

const onEditImg = (e) => {
  e.preventDefault();
  e.stopPropagation();
  openCropper.value = true
}

const onAddSocial = (v) => {
        const name = modelRef.extraSocial.filter(item => {
            return item.value === v
        })[0].label;
        const index = modelRef.extraSocial.findIndex(item => {
            return item.value === v
        })
        modelRef.links.push({
            name: name,
            id: v,
            url: '',
            isUrl: true
        })
        modelRef.extraSocial.splice(index, 1);
}

const getInfo = () => {
    profileApi.getUserProfile().then(res => {
        res.data.links.map(item => item.isUrl = true);
        const links = res.data.links;
        const ids = links.map(item => item.id);
        const extraSocial = SOCIAL_MAP.filter(item => {
          return ids.indexOf(item.id) === -1;
        }).map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
        if (!res.data.trackingTags) {
          res.data.trackingTags = DEFAULT_TAGS;
        }
        Object.assign(userInfo, res.data)
        Object.assign(modelRef, res.data)
        modelRef.trackingTags = _.cloneDeep(res.data.trackingTags);
        modelRef.links = _.cloneDeep(res.data.links);
        console.log(modelRef.links);
        modelRef.extraSocial = extraSocial;
        let trackingTagsRef = {};
        res.data.trackingTags.map(item => {
            trackingTagsRef[item.countryCode] = item.tag;
        })
        console.log(trackingTagsRef);
        Object.assign(modelRefTk, trackingTagsRef)
    });
}

async function init() {
    const p1 = await profileApi.getPromotionProductCategories().then(res => {
        categories.value = res.data.map(item => {
            return {
                label: item.name,
                value: item.id
            }
        })
    });
    const p2 = await profileApi.getPromotionCountries().then(res => {
        countries.value = res.data.map(item => {
            return {
                label: item.name,
                value: item.code
            }
        })
    });
    const p3 = await profileApi.getPromotionMethods().then(res => {
        methods.value = res.data.map(item => {
            return {
                label: item.name,
                value: item.id
            }
        })
    });
    Promise.all([p1, p2, p3])
}

init();
getInfo();

function onOpen() {
    open.value = true;
}

const onClose = () => {
  open.value = false;
};
</script>

<style scoped lang="less">
.profile {
    // padding: 24px;
    &-wrap {
        background-color: #fff;
        box-shadow: var(--section-box-shadow-6);
        border-radius: 8px;
        padding: 0 16px;
    }
    &-header {
      height: 64px;
      display: flex;
      line-height: 64px;
      align-items: center;
      margin-right: -16px;
      padding-right: 16px;
      justify-content: space-between;
      border-bottom: 1px solid var(--border-4);
    }
    &-links {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        background-color: var(--bg-gray-8);
        padding: 2px 5px 2px 2px;
        border-radius: 6px;
        margin-right: 8px;
        margin-bottom: 10px;
        color: var(--dark-2);
        &:hover {
          background-color: var(--bg-gray-5);
        }
        span {
            display: block;
        }
        .icon-app {
            font-size: 20px;
            border-radius: 4px;
            overflow: hidden;
            background-color: var(--bg-gray-5);
        }
        .icon-app-default {
            font-size: 16px;
            padding: 2px;
            border-radius: 4px;
            overflow: hidden;
            background-color: var(--bg-gray-5);
            color: var(--dark-4);
        }
        .arrow-icon {
          color: var(--dark-4);
        }
    }
    &-website {
        background: #FFFFFF;
        border: 1px solid #E8E5ED;
        box-shadow: 0 2px 12px 0 #0000000f;
        border-radius: 8px;
        border-left: 4px solid #CCC7D9;
        padding: 12px 18px;
        width: 354px;
        height: 110px;
        color: #0F0629;
        position: relative;
        line-height: 1.35;
        .website-name {
            background: #F1F0F5;
            border-radius: 4px;
            padding: 1px 3px;
            margin-bottom: 24px;
            display: inline-block;
        }
        .tag-label{
            font-family: Sora, sans-serif;
            font-weight: 500;
            font-size: 13px;
            color: var(--dark-3);
            letter-spacing: 0.3px;
            line-height: 18px;
            margin-bottom: 4px;
        }
        .tag-box {
            position: absolute;
            bottom: 12px;
            left: 16px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 500;
        }

    }
    &-title {
        display: flex;
        justify-content: left;
        align-items: center;
        font-family: Sora, sans-serif;
        font-weight: 500;
        font-size: 20px;
        color: #0F0629;
        letter-spacing: 0.5px;
        line-height: 28px;
        > h4 {
          font-weight: 600;
        }
      }
    &-subtitle {
            opacity: 0.5;
            font-weight: 400;
            font-size: 13px;
            color: #0F0629;
            letter-spacing: 0.3px;
            line-height: 18px;
            margin-left: 8px;
        }
    &-detail {
        padding: 14px 0 24px 0;
        display: flex;
        .info {
            width: 100%;
        }
        .avatar {
            flex-shrink: 0; 
            width: 136px;
            height: 136px;
            background: var(--bg-gray-2);
            border-radius: 8px;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 6px;
        }
        .label {
            min-width: 280px;
            margin-top: 24px;
            font-family: Sora, sans-serif;
            font-weight: 500;
            font-size: 13px;
            color: var(--dark-3);
            letter-spacing: 0.3px;
            line-height: 18px;
        }
        .field {
            margin-top: 4px;
            font-family: Sora, sans-serif;
            font-size: 14px;
            color: var(--dark-1);
            letter-spacing: 0.4px;
            line-height: 20px;
            min-width: 280px;
            word-break: break-word;
            font-weight: 500;
        }
        .label-2 {
            min-width: 280px;
            margin-top: 24px;
            font-family: Sora, sans-serif;;
            font-size: 13px;
            color: var(--dark-3);
            letter-spacing: 0.3px;
            line-height: 18px;
            font-weight: 400;
        }
        .field-links {
            margin-top: 4px;
            display: flex;
            font-family: Sora, sans-serif;
            font-weight: 400;
            font-size: 13px;
            color: var(--dark-2);
            letter-spacing: 0.3px;
            line-height: 18px;
            min-width: 480px;
            flex-wrap: wrap
        }
    }
}
.input {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    width: 480px;
    > span {
        height: 16px;
        font-family: Sora, sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: var(--dark-2);
        letter-spacing: 0.4px;
        line-height: 16px;
        margin-bottom: 8px;
    }
    > input {
        height: 40px;
    }
    .main-text {
        opacity: 0.7;
        font-family: Sora, sans-serif;
        font-weight: 500;
        font-size: 14px;
        color: #0F0629;
        letter-spacing: 0.4px;
        line-height: 20px;
      }
      .subtitle {
        color: #6a647d;
        font-size: 14px;
        font-family: Sora, sans-serif;
        font-weight: 400;
        line-height: 19px;
        letter-spacing: 0.4px;
        text-align: left;
      }
      .icon-app {
        font-size: 24px;
        border-radius: 4px;
        overflow: hidden;
      }
      .arrow-icon {
        font-size: 12px;
        color:var(--dark-4); 
      }
}
.link-card {
        margin-top: 40px;
        .title {
          font-family: Sora, sans-serif;
          font-weight: 500;
          font-size: 20px;
          color: #0F0629;
          letter-spacing: 0.5px;
          line-height: 28px;
          margin-bottom: 6px;
        }
        .subtitle {
          font-family: Sora, sans-serif;
          font-weight: 400;
          font-size: 13px;
          color: #0F0629;
          letter-spacing: 0.3px;
          line-height: 16px;
          opacity: 0.7;
        }
        .input {
            width: 440px;
        }
      }
      .tips {
      opacity: 0.5;
      font-family: Sora, sans-serif;
      font-weight: 400;
      font-size: 13px;
      color: #0F0629;
      letter-spacing: 0.3px;
      line-height: 18px;
    }
    .ant-alert {
      margin-top: 40px;
      background: #FEF4E5;
      border: 2px solid #F6AC2D;
      border-radius: 8px;
      box-shadow: none;
    }
    .social-addon {
        font-size: 14px;
        justify-content: left;
        align-items: center;
        width: 113px;
        text-align: left;
        display: flex;
        box-sizing: border-box;
      }
      .website-name {
            background: #F1F0F5;
            border-radius: 4px;
            padding: 1px 3px;
            margin-bottom: 24px;
            display: inline-block;
            font-weight: 400;
      }
      :deep(.ant-upload) {
        .ant-spin .ant-spin-dot {
                // margin: 0;
                top: 40%;
          }
      }
        .avatar-uploader {
            width: 200px;
            height: 200px;
            background: var(--bg-gray-6);
            border-radius: 8px;
            cursor: pointer;
            &:hover {
              background: var(--bg-gray-4);
              .upload-icon {
                border-color: var(--bg-gray-7);
              }
            }
            .upload-icon{
              border: 1px dashed var(--border-1);
              border-radius: 8px;
              font-size: 14px;
              width: inherit;
              height: inherit;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 36px;
              color: var(--dark-3);
          }
          .upload-img{
            width: 200px;
            height: 200px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 8px;
            img{
              // width: 100%;
              // height: 100%;
              border-radius: 8px;
            }
          }
  }
  :deep(.ant-form) {
      .ant-form-item {
        margin-bottom: 0;
      }
  }
  .subtitle-btn {
    margin-top: 6px;
    color: var(--dark-2);
    cursor: pointer;
    &:hover {
      color: var(--dark-1);
    }
  }
  .two-line-text {
    display: -webkit-box;           /* 使用盒模型 */
    -webkit-box-orient: vertical;   /* 垂直排列子元素 */
    -webkit-line-clamp: 2;          /* 限制显示的行数为2 */
    overflow: hidden;               /* 超出部分隐藏 */
    text-overflow: ellipsis;        /* 超出部分显示省略号 */
    white-space: normal;            /* 正常换行 */
    line-height: 1.2;
  }
</style>