<template>
  <div :class="'product-variation box'" ref="zjdgx">
    <h2>Product Variations</h2>
    <a-spin :spinning="isLoading">
      <div :class="'header' + (dataLoaded && pagination.total > pagination.pageSize ? '' : ' no-pagination')">
        <top-pagination
          v-if="dataLoaded && pagination.total > pagination.pageSize"
          :pagination="{
            size: filter.size,
            current: filter.page,
            total: pagination.total,
          }"
          @pageChange="(page) => setFilter('page', page)"
        />
        <a-input-search
          allow-clear
          class="search"
          placeholder="Search..."
          @search="(v) => setFilter('keywords', v)"
          v-model:value="filter.keywords"
        />
      </div>
      <artmisads-table
        :columns="columns"
        :scroll="{ x: 1000 }"
        :data-source="dataList"
        :pagination="pagination.total > 10 ? {
          total: pagination.total,
          current: pagination.current,
          pageSize: pagination.pageSize,
          showSizeChanger: true
        } : null"
        :change="onChangePage"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'productId'">
            <a
              target="_blank"
              class="product-name"
              :href="`/${userType}/product${
                userType === 'publisher' ? 's' : ''
              }/${record.id}`"
            >
              <div
                :class="
                  'product-img-show' + (record.imageUrl ? '' : ' show-bg')
                "
              >
                <img :src="record.imageUrl" alt="" v-if="record.imageUrl" />
              </div>
              <div class="product-title">
                <span>{{ record.title }}</span>
                <div class="asin">
                  <a-tooltip
                    v-if="record.status == 'inactive' && userType == 'publisher'"
                    :getPopupContainer="() => $refs.zjdgx"
                  >
                    <template #title>
                      This product has been set to an inactive status by the
                      Seller. Please contact the Seller in a timely manner to
                      confirm during the promotion.
                    </template>
                    <i class="iconfont icon-a-warring-tianchong3" />
                  </a-tooltip>
                  {{ record.asin }}
                </div>
              </div>
            </a>
          </template>
          <template v-else-if="column.key === 'status'">
            <show-status
              :isActive="record.status === 'active'"
              :noBorder="true"
              :text="['Active', 'Inactive']"
            />
          </template>
          <template v-else-if="column.key === 'deals'">
            <div class="deals">
              <product-deals-column
                v-if="record.productDeals?.length"
                v-for="item in record.productDeals"
                :record="item"
                :key="item.id"
              />
              <div class="no-deal" v-else>--</div>
            </div>
          </template>
          <template v-else-if="column.key === 'commission'">
            <span :class="'commission ' + record.status"
              >{{ record.commission }}%</span
            >
          </template>
          <template v-else-if="column.key === 'estPayout'">
            <div :class="'fw500 est-payout ' + record.status">
              {{ record.currencySymbol
              }}{{ Number(record.estPayout).toFixed(2) }}
            </div>
          </template>
          <template v-else-if="column.key === 'price'">
            <price :record="record" />
          </template>
        </template>
      </artmisads-table>
    </a-spin>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import Price from '~/components/Price.vue'
import ShowStatus from '~/components/showStatus.vue'
import { publisherProductApi } from '~/server/index'
import productDealsColumn from './productDealsColumn.vue'
import TopPagination from '~/components/TopPagination.vue'

const isLoading = ref(false)
const dataLoaded = ref(false)
const { id, userType, showStatus } = defineProps([
  'id',
  'userType',
  'showStatus',
])
const filter = ref({
  page: 1,
  size: 10,
  keywords: '',
})
const pagination = ref({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true,
})
const defaultColumns = [
  {
    title: 'Product',
    key: 'productId',
    fixed: 'left',
    width: 400,
  },
  {
    width: 100,
    key: 'deals',
    title: 'Deals',
  },
  {
    title: 'Price',
    key: 'price',
    width: 150,
  },
  {
    width: 120,
    key: 'commission',
    title: 'Commission',
    dataIndex: 'commission',
  },
]
const dataList = ref([])
const columns = ref(
  showStatus
    ? [
        defaultColumns[0],
        {
          title: 'Status',
          key: 'status',
          dataIndex: 'status',
          width: 120,
        },
        ...defaultColumns.slice(1),
      ]
    : [
        ...defaultColumns,
        {
          title: 'Est. Payout',
          key: 'estPayout',
          width: 114,
        },
      ]
)

function onChangePage(data) {
  const { current, pageSize } = data;
  filter.value.page = current
  filter.value.size = pageSize
  pagination.value.pageSize = pageSize
  getVariationList()
}

function getVariationList() {
  isLoading.value = true

  publisherProductApi
    .getProductVariations(id, userType, filter.value)
    .then((res) => {
      isLoading.value = false

      if (res.success) {
        dataLoaded.value = true
        dataList.value = res.data
        pagination.value.total = res.total
        pagination.value.current = filter.value.page

        if (filter.value.page === 1 && res.total <= filter.value.size) {
          pagination.value.disabled = true
        }

        let hasPromoCode = false

        for (let product of res.data) {
          if (product.productDeals?.length) {
            columns.value[0]['width'] = 360
            columns.value[showStatus ? 2 : 1]['width'] = 170

            for (let deal of product.productDeals) {
              if (deal.promoCode) {
                hasPromoCode = true
                columns.value[0]['width'] = 320
                columns.value[showStatus ? 2 : 1]['width'] = 300
                break
              }
            }

            if (hasPromoCode) {
              break
            }
          }
        }
      }
    })
}

getVariationList()

function setFilter(type, option) {
  if (type == 'page') {
    filter.value.page = option
  } else {
    if (option.type) {
      if (option.type == 'click') {
        // clear event
        filter.value.page = 1
      } else {
        return
      }
    } else {
      filter.value.keywords = option
    }

    filter.value.page = 1
  }

  getVariationList()
}
</script>

<style lang="less" scoped>
.product-variation {
  display: block;
}
h2 {
  height: 64px;
  font-size: 20px;
  font-weight: 600;
  margin-top: -16px;
  line-height: 64px;
  margin-right: -16px;
  color: var(--dark-1);
  letter-spacing: 0.5px;
  border-radius: 8px 8px 0 0;
  background: var(--color-white);
  border-bottom: 1px solid #e8e5ed;
}

.header {
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.no-pagination {
    justify-content: right;
  }
  > .ant-input-search {
    width: 200px;
  }
}

.product-variation {
  :deep(.ant) {
    &-table {
      &-thead {
        .ant-table-cell {
          white-space: nowrap;
        }
      }
    }
  }
}

.product-name {
  display: flex;
  align-items: center;

  .product-title {
    overflow: hidden;
    margin-left: 12px;
    flex: 0 0 calc(100% - 64px);

    > span {
      display: block;
      cursor: pointer;
      max-height: 22px;
      font-weight: 500;
      overflow: hidden;
      line-height: 22px;
      white-space: nowrap;
      color: var(--dark-1);
      letter-spacing: 0.4px;
      text-overflow: ellipsis;
      text-decoration: underline;
    }

    > div {
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: var(--dark-3);
      letter-spacing: 0.3px;
    }

    .asin {
      margin-top: 2px;
      .iconfont {
        margin-right: 2px;
        color: #f59b00;
      }
    }
  }

  .product-img-show {
    width: 56px;
    height: 56px;
    border-radius: 8px;
    &.show-bg {
      background: var(--bg-gray-2);
    }

    img {
      border-radius: 8px;
      width: 56px;
      height: 56px;
      object-fit: contain;
    }
  }
}

.commission {
  opacity: 0.9;
  font-weight: 500;
}
.inactive {
  opacity: 0.3;
}

.no-deal {
  color: var(--dark-3);
}
</style>