<template>
  <div class="table" ref="tableRef">
    <artmisads-table
      :columns="columns"
      row-key="id"
      :data-source="props.dataList"
      :pagination="{
        total: props.listTotal,
        current: props.params.page,
        pageSize: props.params.size,
        showSizeChanger: true
      }"
      :loading="props.loading"
      :change="onChangePage"
      rowClassName="table-item"
      tableLayout="fixed"
      :scroll="{ x: 1200 }"
    >
      <template #bodyCell="{ column, record, text }">
        <template v-if="column.name === 'showCurrency'">
          {{text ? AMAZON_CURRENCY_MAPPER[record.marketplace] : ''}}{{text}}
        </template>
        <template v-if="column.name === 'productStatus'">
          <a-tooltip v-if="record.productStatus === 'inactive'" placement="topLeft" :getPopupContainer="() => tableRef">
            <template #title>
              <span>This product was set to Inactive on {{moment(record.productLastInactiveAt).format('YYYY-MM-DD hh:mm')}}</span>
            </template>
            <div class="inactive-info">
              Inactive
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-info"></use>
              </svg>
            </div>
          </a-tooltip>
          <showStatus v-else :isActive="record.productStatus === 'active'" :noBorder="true"/>
        </template>
        <template v-if="column.name === 'productTitle'">
          <span class="product-title">{{record.productTitle}}</span>
        </template>
        <template v-if="column.name === 'reportDatePstTimestamp'">
          {{moment(record.reportDatePstTimestamp).format('YYYY-MM-DD')}}
        </template>
        <template v-if="column.name === 'note'">
          <div class="note">
            <div v-if="showNoteId && record.id === showNoteId" class="change-note">
              <a-input-group compact>
                <a-input v-model:value="noteValue" style="width: 150px" />
                <a-button @click="() => submitEditNote(record.id)" type="primary">确定</a-button>
              </a-input-group>
            </div>
            <div class="edit-note" v-else>
              <span>{{record.note}}</span>
              <artmisads-button @click="() => showEditNote(record.id, record.note)" size="small" type="text">Edit</artmisads-button>
            </div>
          </div>
        </template>
        <template v-if="column.name === 'trackingLink'">
          <div class="tracking-link">
            <a target="_blank" :href="record.trackingLink">
              <svg class="icon" aria-hidden="true" style="fill: rgba(108, 61, 242, 1)">
                <use xlink:href="#icon-lianjie"></use>
              </svg>
            </a>
            <svg @click="() => copyTrackingLink(record.shortTrackingLink)" class="icon" aria-hidden="true" style="fill: rgba(108, 61, 242, 1)">
              <use xlink:href="#icon-copy2-cu"></use>
            </svg>
          </div>
        </template>
      </template>
    </artmisads-table>
    <artmisads-modal
      v-model:open="showDeactivateModal"
      :title="`Are you sure you want to Deactivate this Product?`"
      :width="500"
      :onCancel="onCancel"
      :destroyOnClose="true"
      :maskClosable="false"
    >
      <div class="inactive-content">
        <p>Deactivating a Product will archive all active Publisher/Creator links. and make this product unavailable to publishers and creators.</p>
      </div>
      <template #footer>
        <artmisads-button @click="() => setShowDeactivateModal(false)">Cancel</artmisads-button>
        <artmisads-button @click="confirmInactive" type="primary">Confirm</artmisads-button>
      </template>
    </artmisads-modal>
  </div>
</template>
<script lang="jsx" setup>
  import moment from 'moment';
  import { ref, computed } from 'vue';
  import { message } from "ant-design-vue";
  import showStatus from '~/pages/seller/Brands/components/showStatus.vue';
  import { AMAZON_CURRENCY_MAPPER } from '~/contants/common';

  const tableRef = ref(null);
  const noteValue = ref('');
  const showNoteId = ref('');
  const props = defineProps([ 'listTotal', 'setParams', 'params', 'dataList', 'editNote', 'getList', 'listTotal']);

  const submitEditNote = async (id) => {
    await props.editNote({
      id,
      note: noteValue.value
    });
    showNoteId.value = '';
    noteValue.value = '';
  }

  const showEditNote = (id = '', value = '') => {
    noteValue.value = value;
    showNoteId.value = id;
  }

  const columns = [
    {
      title: 'Sales Time',
      dataIndex: 'reportDatePstTimestamp',
      width: 140,
      name: 'reportDatePstTimestamp',
      sorter: true,
    },
    {
      title: 'Publisher',
      dataIndex: 'publisherName',
      width: 120,
    },
    {
      title: 'ASIN',
      dataIndex: 'asin',
      width: 150,
    },
    {
      title: 'Product',
      dataIndex: 'productTitle',
      name: 'productTitle',
      width: 200,
    },
    {
      title: 'Product Status',
      dataIndex: 'productStatus',
      name: 'productStatus',
      width: 140,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      name: 'status',
      width: 120,
    },
    {
      title: 'AA Username',
      dataIndex: 'sellerName',
      width: 130,
    },
    {
      title: 'Seller Amazon Name',
      dataIndex: 'sellerAmazonName',
      width: 220,
    },
    {
      title: 'Brand',
      dataIndex: 'brandName',
      width: 80,
    },
    {
      title: 'Sales',
      dataIndex: 'salesAmount',
      width: 100,
      name: 'showCurrency',
      sorter: true,
    },
    {
      title: 'C.Sales',
      dataIndex: 'discountedSalesAmount',
      width: 100,
      name: 'showCurrency',
      sorter: true,
    },
    {
      title: 'Bonus',
      dataIndex: 'bonusAmount',
      width: 100,
      name: 'showCurrency',
      sorter: true,
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      width: 100,
    },
    {
      title: 'Total Commission Rate from Seller',
      dataIndex: 'commissionAndFeeRate',
      width: 180,
      sorter: true,
    },
    {
      title: 'Total Commission Amount from Seller',
      dataIndex: 'commissionAndFeeAmount',
      width: 190,
      sorter: true,
    },
    {
      title: 'Commission Rate to Publisher',
      dataIndex: 'commissionRate',
      width: 170,
      sorter: true,
    },
    {
      title: 'Commission Amount to Publisher',
      dataIndex: 'commissionAmount',
      width: 180,
      sorter: true,
    },
    {
      title: 'Commission Ratio to AA',
      dataIndex: 'platformFeeFactor',
      width: 170,
      sorter: true,
    },
    {
      title: 'AA Fees Rate Calculation',
      dataIndex: 'platformFeeRate',
      width: 170,
      sorter: true,
    },
    {
      title: 'AA Fees Rate Maximum',
      dataIndex: 'platformMaxFeeRate',
      width: 170,
      sorter: true,
    },
    {
      title: 'AA Fees Rate Final',
      dataIndex: 'platformFinalFeeRate',
      width: 170,
      sorter: true,
    },
    {
      title: 'AA Fees Amount',
      dataIndex: 'platformFeeAmount',
      name: 'showCurrency',
      width: 180,
      sorter: true,
    },
    {
      title: 'Clicks',
      dataIndex: 'clicks',
      width: 100,
      sorter: true,
    },
    {
      title: 'DPVs',
      dataIndex: 'detailPageViews',
      width: 100,
      sorter: true,
    },
    {
      title: 'ATCs',
      dataIndex: 'addToCart',
      width: 100,
      sorter: true,
    },
    {
      title: 'Conversions',
      dataIndex: 'conversions',
      width: 130,
      sorter: true,
    },
    {
      title: 'Marketplace',
      dataIndex: 'marketplace',
      width: 135,
      sorter: true,
    },
    {
      title: 'Brand ID',
      dataIndex: 'brandId',
      width: 100,
    },
    {
      title: 'Seller ID',
      dataIndex: 'sellerId',
      width: 100,
    },
    {
      title: 'Publisher ID',
      dataIndex: 'publisherId',
      width: 130,
    },
    {
      title: 'Category',
      dataIndex: 'categoryName',
      width: 100,
    },
    {
      title: 'AA Link',
      dataIndex: 'trackingLink',
      name: 'trackingLink',
      width: 100,
      fixed: 'right',
    },
    {
      title: 'Payment ID',
      dataIndex: 'paymentId',
      width: 120,
      fixed: 'right',
    },
    {
      title: 'Note',
      dataIndex: 'note',
      name: 'note',
      width: 150,
      fixed: 'right',
    },
  ]

  const copyTrackingLink = async copyText => {
    await navigator.clipboard.writeText(copyText);
    message.success('Copied!',1)
  }


  const onChangePage = (data, filters, sorter) => {
    const { current, pageSize } = data;
    let newParams = { ...props.params };
    newParams.page = current;
    newParams.size = pageSize;
    if (sorter) {
      const { field, order } = sorter;
      newParams.sortField = field;
      newParams.sortDirection = order === 'ascend' ? 'asc' : 'desc';
    } else {
      newParams.sortField = '';
      newParams.sortDirection = '';
    }
    props.setParams(newParams);
    props.getList();
  }
</script>
<style lang="less" scoped>
  .table {
    padding: 16px;
    :deep(.ant-tooltip-arrow) {
      display: none;
    }
    :deep(.ant-table) {
      .inactive-info {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        svg {
          width: 20px;
          height: 20px;
          vertical-align: -4px;
          fill: var(--red-text);
        }
      }
      .product-title {
        display: block;
        width: 168px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .note {
        position: relative;
        .change-note {
          width: 220px;
          position: absolute;
          right: -21px;
          top: -15px;
        }
        .edit-note {
          display: flex;
          justify-content: space-between;
          > span {
            width: 72px;
          }
          button {
            margin-left: 4px;
            padding: 0 6px;
          }
        }
      }
      .ant-table-thead {
        .ant-table-cell {
          line-height: 24px;
        }
      }
      .tracking-link {
        display: flex;
        svg {
          width: 18px;
          height: 18px;
          margin-right: 10px;
          cursor: pointer;
        }
      }
    }
  }
</style>