<template>
  <footer class="footer-component">
    <div class="base-container">
      <div class="footer-top-wrapper">
        <a href="/" class="footer-logo-link">
          <img
            src="../../assets/images/img/artemisads-logo.svg"
            loading="lazy"
            alt="Brand Logo"
          />
        </a>
        <div class="footer-link-column" v-if="pageType === 'home'">
          <p class="footer-link-title">Pages</p>
          <a @click="scrollToEle('#about')" class="link">About Us</a>
          <a @click="scrollToEle('#services')" class="link">Marketing Services</a>
          <a @click="scrollToEle('#clients')" class="link">Clients and Parnters</a>
        </div>
        <div class="footer-link-column" v-else>
          <p class="footer-link-title">Pages</p>
          <a href="/?point=about">About Us</a>
          <a href="/?point=services">Marketing Services</a>
          <a href="/?point=clients">Clients and Parnters</a>
        </div>
        <div class="footer-link-column">
          <p class="footer-link-title">Contact Us</p>
          <div class="office-location-wrapper">
            <img
              src="../../assets/images/img/location.svg"
              loading="lazy"
              alt="Location Icon"
              class="location-icon"
            />
            <span class="footer-link">740 E Campbell Rd STE 725, Richardson, TX</span>
          </div>
          <div class="office-location-wrapper">
            <img
              src="../../assets/images/img/email.svg"
              loading="lazy"
              alt="Location Icon"
              class="location-icon"
              style="transform: translateY(4px)"
            />
            <span class="footer-link">Support@Artemisads.com</span>
          </div>
          <div class="office-qrcode">
            <img src="../../assets/images/img/qrcode-3.png" />
          </div>
        </div>
      </div>
      <div class="footer-bottom-wrapper">Copyright © 2024ArtemisAds</div>
    </div>
  </footer>
</template>
<script setup>
import { ref } from "vue";

const props = defineProps(['pageType']);
function scrollToEle(ele) {
  document.querySelector(ele).scrollIntoView({
    behavior: "smooth",
  });
}
</script>
<style lang="less" scoped>
  .footer-component {
    background-color: #efeafc;
    padding: 80px 15px 0 15px;
  }
  .base-container {
    width: 100%;
    max-width: 1296px;
    margin-left: auto;
    margin-right: auto;
  }
  .footer-top-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 100px;
  }
  .footer-link-column {
    // color: #2c2933;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    display: flex;
    flex-direction: column;
    padding-right: 15px;
    > p {
      font-size: 20px;
      margin-top: 22px;
      margin-bottom: 36px;
    }
    > a {
      color: var(--dark-1);
      text-decoration: unset;
      font-size: 16px;
      margin-bottom: 24px;
    }
  }
  .office-location-wrapper {
    display: flex;
    margin-bottom: 20px;
    padding-right: 40px;
    > img {
      margin-right: 12px;
      transform: translateY(4px);
      align-self: center;
    }
    > span {
      align-self: center;
      color: #2c2933;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.2;
      margin-top: 7px;
    }
  }
  .office-qrcode {
    width: 100px;
    height: 100px;
  }
  .footer-bottom-wrapper {
    border-top: 1px solid #cecdd1;
    padding-top: 24px;
    padding-bottom: 24px;
  }
</style>