<!--
 * @Author: Hankill(hao.chen)
 * @Date: 2024-12-13 15:19:22
 * @LastEditors: Hankill(hao.chen)
 * @LastEditTime: 2024-12-30 10:36:09
 * @FilePath: /web_artemisads/src/pages/seller/product/fileBar/uploadProductDeals.vue
 * @Description: 
-->
<template>
    <a-spin :spinning="loading">
      <div class="example-table">
        <p>
          <span>Upload a CSV file with the following columns:</span>
          <artmisads-select-button class="download" @click="downloadExample">
            <svg class="icon icon-daochu1" aria-hidden="true">
              <use xlink:href="#icon-daochu1"></use>
            </svg>
            Download the Template
          </artmisads-select-button>
        </p>
        <div class="content">
          <div class="content-left">
            <div class="table">
              <artmisads-table
                row-key="id"
                :columns="columns"
                :bordered="true"
                :pagination="false"
                :scroll="{x: 1800}"
                :data-source="exampleDataList"
              >
              <template #bodyCell="{ column, record }">
                <template v-if="column.name === 'status' && (record.status === 'inactive' || record.status === 'active')">
                  <showStatus :isActive="record.status === 'active'" :noBorder="true"/>
                </template>
              </template>
              </artmisads-table>
            </div>
            <div class="file-name" v-if="fileName">
              <div>
                <svg class="icon icon-biaoge" aria-hidden="true">
                  <use xlink:href="#icon-biaoge"></use>
                </svg>
                <span class="name">{{fileName}}</span>
                <svg class="icon close-cu" aria-hidden="true" @click="clearFile">
                  <use xlink:href="#icon-close-cu"></use>
                </svg>
              </div>
            </div>
            <a-upload
              name="avatar"
              list-type="picture-card"
              :show-upload-list="false"
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              :before-upload="beforeUpload"
              @change="handleChange"
              v-else
            >
              <p class="ant-upload-drag-icon">
                <inbox-outlined></inbox-outlined>
              </p>
              <svg class="icon shangchuan2" aria-hidden="true">
                <use xlink:href="#icon-jiahao-cu"></use>
              </svg>
              Choose a file...
            </a-upload>
          </div>
          <div class="content-right">
            <div class="description">
              <span class="line"></span>
              <span class="text">Description</span>
            </div>
            <div class="required">
              <span class="line"></span>
              <span class="text">Required</span>
            </div>
            <div class="example">
              <span class="line"></span>
              <span class="text">Example</span>
            </div>
          </div>
        </div>
        <div style="margin-bottom: 24px;" class="example-table">
            <p class="sub-title">Instructions for Deal Settings</p>
            <p style="font-weight: 400;">1.Date Format: Set the date format as either "YYYY/MM/DD HH:MM:SS" or "MM/DD/YYYY HH:MM:SS".</p>
            <p style="font-weight: 400;">2.Time Omission Rule: To set a deal to start or end at the beginning of the day, you can omit the time portion.</p>
            <p style="font-weight: 400;">3.Time Zone: All times must follow the {{ getTimeZoneStr(user.userInfo.countryCode, false) }}.</p>
            <p style="font-weight: 400;">4.Discount Calculation: For the same ASIN with overlapping deals, the discount percentage is calculated as:</p>
            <p style="font-weight: 400;">Discount Percentage = 1 - (Lowest Final Price / Highest Full Price).</p>
            <p style="font-weight: 400;">In product details, the Full Price represents the maximum price, and the Final Price represents the minimum price.</p>
        </div>
        <div class="next-btn">
          <artmisads-button type="primary" @click="toNext" v-if="dataList.failList.length || dataList.successList.length">
            Next
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-jiantou-you-cu"></use>
            </svg>
          </artmisads-button>
          <artmisads-button type="primary" v-else disabled>Next</artmisads-button>
        </div>
      </div>
    </a-spin>
  </template>
  <script setup>
    import { DeleteOutlined } from "@ant-design/icons-vue";
    import { message } from 'ant-design-vue';
    import { ref } from 'vue';
    import { getTimeZoneStr } from '~/utils';
    import { useUserInfoStore } from "@/storeData";
    import showStatus from '../../Brands/components/showStatus.vue';
    import { productsApi } from '../../../../server/index'
  
    const fileName = ref('');
    const loading = ref(false);
    const user = useUserInfoStore();
    const props = defineProps([
      'setDataList', 'setShowExample', 'dataList'
    ]);
  
    const clearFile = () => {
      fileName.value = '';
      props.setDataList({ successList: [], failList: [] });
    }
  
    const exampleDataList = [
      {
        id: 1,
        asin: 'We only accept ASINs that are in your ArtemisAds account',
        amazon: 'The same ASIN may exist under different Amazon Seller Accounts.This column can ensure that you update it correctly. The Amazon Seller Name can be found under Setting -> Connections. Please make sure they are consistent.',
        start: 'The Start date of the deal.',
        end: 'The End date of the deal.',
        price: 'The full retail price of the product before any discount',
        finalprice: 'The price of the product after the current discount is applied',
        commission: 'The type of the Deal Options are "Deal", "Clippable Coupon"&"Promo Code"',
        status: 'It’s only required when the deal type is promo code'
      },
      {
        id: 2,
        asin: 'Yes',
        amazon: 'Yes',
        start: 'Yes',
        end: 'Yes',
        price: 'Yes',
        finalprice: 'Yes',
        commission: 'Yes',
        status: 'No',
      },
      {
        id: 3,
        asin: 'B001HSIE123',
        amazon: 'Amazon Seller Name',
        start: '11/01/2024 12:00:00',
        end: '11/11/2024 23:59:59',
        price: '100',
        finalprice: '20',
        commission: 'Deal',
        status: '',
      },
      {
        id: 4,
        asin: 'B001HSIE124',
        amazon: 'Amazon Seller Name',
        start: '11/01/2024 12:00:00',
        end: '11/11/2024 23:59:59',
        price: '100',
        finalprice: '25',
        commission: 'Clippable Coupon',
        status: '',
      },
      {
        id: 5,
        asin: 'B001HSIE125',
        amazon: 'Amazon Seller Name',
        start: '11/01/2024 12:00:00',
        end: '11/11/2024 23:59:59',
        price: '100',
        finalprice: '30',
        commission: 'Promo Code',
        status: 'HK7612',
      },
    ];
    const columns = [
      {
        title: 'ASIN',
        dataIndex: 'asin',
        width: 200,
      },
      {
        title: 'Amazon Seller Account',
        dataIndex: 'amazon',
        width: 460,
      },
      {
        title: 'Start Date',
        dataIndex: 'start',
        width: 200
      },
      {
        title: 'End Date',
        dataIndex: 'end',
        width: 200
      },
      {
        title: 'Full Price',
        dataIndex: 'price',
        width: 180
      },
      {
        title: 'Final Price',
        dataIndex: 'finalprice',
        width: 180
      },
      {
        title: 'Deal Type',
        dataIndex: 'commission',
        width: 220
      },
      {
        title: 'Promo Code',
        dataIndex: 'status',
        name: 'status',
        width: 200
      }
    ];

    const downloadExample = () => {
      productsApi.downloadProduct();
    }
  
    const handleChange = async ({file}) => {
      const { type, originFileObj, percent, name } = file;
      let form = new FormData();
          form.append('file', originFileObj);
      if (percent === 100) {
        if (type !== 'text/csv') {
          return message.error('Please upload a csv file.');
        } else {
          loading.value = true;
          let res = await productsApi.updaloadProductDealsCsv(form);
          const { success, error, data } = res;
          if (success) {
            const successList = data.successfulList || [];
            const failList = data.failedList || [];
            if (!successList.length && !failList.length) {
              message.warning('No matching data found.');
            } else {
              message.success('Success');
              const dataList = { successList, failList };
              props.setDataList(dataList);
              fileName.value = name;
            }
            
          }
          loading.value = false;
        }
      }
    }
  
    const toNext = () => {
      props.setShowExample(false);
    }
  
  </script>
  
  <style lang="less" scoped>
    .example-table {
      > p {
        width: 1026px;
        display: flex;
        padding-top: 8px;
        font-weight: 500;
        line-height: 20px;
        align-items: center;
        color: var(--dark-2);
        letter-spacing: 0.4px;
        justify-content: space-between;
      }
      .download {
        display: flex;
        svg {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          align-self: center;
        }
      }
      .sub-title {
        font-weight: 600;
        color: var(--dark-1);
      }
      .content {
        padding: 12px 0 8px ;
        display: flex;
        :deep(.ant) {
          &-table-wrapper .ant-table-cell {
            word-break: break-word;
            padding: 8px;
            line-height: 20px;
            border-color: var(--border-4) !important;
          }
        }
      }
      .content-right {
        margin-left: 8px;
        margin-top: 40px;
        .description,
        .required,
        .example {
          display: flex;
          .line {
            width: 2px;
            height: 100%;
          }
          .text {
            font-weight: 500;
            line-height: 20px;
            padding-left: 8px;
            padding-right: 8px;
            height: 20px;
            align-self: center;
            border-radius: 4px;
            margin-left: 16px;
          }
        }
        .description {
          height: 96px;
          .line {
            background-color: var(--primary-bg);
          }
          .text {
            color: var(--primary-text);
            background-color: var(--table-cell-bg-3);
            border: 1px solid var(--primary-border-3);
          }
        }
        .required {
          height: 32px;
          margin-top: 4px;
          .line {
            background-color: var(--yellow-5);
          }
          .text {
            color: var(--yellow-4);
            background-color: var(--table-cell-bg-2);
            border: 1px solid var(--yellow-5);
          }
        }
        .example {
          height: 110px;
          margin-top: 4px;
          .line {
            background-color: var(--green-4);
          }
          .text {
            color: var(--green-1);
            background-color: var(--table-cell-bg-1);
            border: 1px solid var(--green-2);
          }
        }
      }
      .table {
        width: 1026px;
      }
      .content-left {
        .file-name {
          display: flex;
          width: 100%;
          height: 60px;
          cursor: pointer;
          background: var(--big-bg-1);
          border-radius: 8px;
          color: var(--dark-2);
          margin-top: 8px;
          &:hover {
            background: var( --bg-gray-2);
          }
          > div {
            display: flex;
            height: 28px;
            padding: 0 24px;
            border-radius: 8px;
            align-self: center;
            // &:hover {
            //   background-color: var(--bg-gray-4);
            // }
            .icon-biaoge {
              width: 24px;
              height: 24px;
              fill: var( --primary-text);
              align-self: center;
            }
            .name {
              color: var( --primary-text);
              font-weight: 500;
              font-size: 16px;
              letter-spacing: 0.4px;
              line-height: 22px;
              margin-left: 8px;
              margin-right: 24px;
              align-self: center;
            }
            .close-cu {
              cursor: pointer;
              width: 24px;
              height: 24px;
              padding: 4px;
              align-self: center;
              fill: var(--dark-3);
              &:hover {
                fill: var(--dark-1);
              }
            }
          }
          
        }
        :deep(.ant-upload-wrapper){
          margin-top: 8px;
          .ant-upload.ant-upload-select {
            width: 100%;
            height: 64px;
            color: var(--dark-2);
            background: var(--big-bg-1);
            border: 2px dashed var(--border-1);
            border-radius: 8px;
            font-size: var(--font-size-h-l);
            &:hover {
              background: var( --bg-gray-2);
              border: 2px dashed var(--bg-gray-7);
            }
            svg {
              width: 32px;
              height: 32px;
              fill: var(--dark-2);
              margin-right: 16px;
            }
          }
        }
        :deep(.ant-upload-wrapper){
          margin-top: 16px;
          .ant-upload.ant-upload-select {
            width: 100%;
            height: 60px;
            color: var(--dark-2);
            background: var(--big-bg-1);
            border: 1px dashed var(--border-1);
            border-radius: 8px;
            font-size: var(--font-size-h-l);
            font-weight: 500;
            &:hover {
              background: var( --bg-gray-2);
              border: 1px dashed var(--bg-gray-7);
            }
            svg {
              width: 24px;
              height: 24px;
              fill: var(--dark-2);
              margin-right: 8px;
            }
          }
        }
        :deep(.ant-table-wrapper) {
          .ant-table-cell {
            padding: 8px;
            line-height: 20px;
            word-break: break-word;
            border-color:  var(--border-4) !important;
          }
          .ant-table-thead {
            .ant-table-cell {
            border-bottom-color:  var(--border-1) !important;
            }
          }
          .ant-table-container {
            border-color:  var(--border-4) !important;
          }
          table {
            border-top: none !important;
            border-color:  var(--border-4) !important;
          }
        }
      }
      .next-btn {
        display: flex;
        padding: 10px 0 0 0;
        justify-content: right;
        border-top: 1px solid var(--border-4);
      }
    }
  </style>