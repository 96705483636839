<template>
  <div class="performance">
    <breadcrumbs :breadcrumbsData="breadcrumbsData" #icon>
      <svg class="icon" aria-hidden="true" style="fill: #fff">
        <use xlink:href="#icon-shangpin"></use>
      </svg>
    </breadcrumbs>
    <noticeTool/>
    <a-spin :spinning="spinning">
      <div class="content-main" ref="mainRef">
        <div class="content-head">
          <p>
            <span v-if="tagNumParams.lastUpdateAt">
              Last Update at: {{moment(tagNumParams.lastUpdateAt).format('YYYY-MM-DD hh:mm')}}
            </span>
          </p>
          <a-dropdown :getPopupContainer="() => mainRef">
            <template #overlay>
              <artmisads-dropdown-menu @click="handleExportClick">
                <a-menu-item key="all">Export All Data</a-menu-item>
                <a-menu-item key="filter">Export with Filters</a-menu-item>
              </artmisads-dropdown-menu>
            </template>
            <artmisads-button-special>
              <svg class="icon daochu" aria-hidden="true">
                <use xlink:href="#icon-daochu"></use>
              </svg>
              <span class="btn-text">Export</span>
              <svg class="icon artmisads-select-down-icon" aria-hidden="true">
                <use xlink:href="#icon-jiantou-xia-cu"></use>
              </svg>
            </artmisads-button-special>
          </a-dropdown>
        </div>
        <filterBar
          :params="params"
          :getList="getList"
          :setParams="setParams"
        />
        <div class="tags">
          <filterTag
            :setParams="setParams"
            :tagNumParams="tagNumParams"
            :marketplace="params.marketplace"
          />
        </div>
        <div class="content">
          <p>
            {{RESPORTS_TYPE_MAP[params.metricName].name}}
            <span v-if="showTop10">(Top 10)</span>
          </p>
          <div class="chart">
            <chart
              :showDate="false"
              :dataList="dataList"
              :width=chartWidth
              showYName="productAsin"
              showXName="metricValue"
              :lineColor="chartParams.lineColor"
              :lineBgOffsetTop="chartParams.lineBgOffsetTop"
              :lineBgOffsetZero="chartParams.lineBgOffsetZero"
              chartType="bar"
              :selectDaysNum="selectDaysNum"
              :showCurrent="false"
              :startTimestamp="params.startReportDateTimestamp"
              :endTimestamp="params.endReportDateTimestamp"
              :onClickBarChart="onClickBarChart"
              :metricName="params.metricName"
            />
          </div>
          <div class="table">
            <tableContent
              :dataList="tableList"
              :marketplace="params.marketplace"
              :tableTotal="tableTotal"
              :params="tableParams"
              :setParams="setTableParams"
              :onClickTableChart="onClickTableChart"
              :tagNumParams="tagNumParams"
            />
          </div>
        </div>
      </div>
    </a-spin>
    <lineChartModal
      v-if="showlineChartModal"
      title="Product Report"
      :itemInfo="itemInfo"
      :onCancleModal="onCancleModal"
      pageType="sellerProduct"
      :marketplace="params.marketplace"
    />
  </div>
</template>
<script setup>
  import { computed, onMounted, ref, watch } from 'vue';
  import moment from 'moment';

  import { reportsApi } from '~/server/index';
  import { RESPORTS_TYPE_MAP, RESPORTS_TEST_DATA } from '~/contants/reports';
  import breadcrumbs from '../../breadcrumbs.vue';
  import filterBar from '~/components/reports/filterBar.vue';
  import filterTag from '~/components/reports/filterTag.vue';
  import lineChartModal from '~/components/reports/lineChartModal.vue';
  import chart from '~/components/reports/chart.vue';
  import tableContent from './tableContent.vue';
  import noticeTool from '~/components/reports/noticeTool.vue';

  const breadcrumbsData = {
    title: 'Product Reports',
    color:'#4682FA',
    // hasTitleIcon:true,
    links: [
      {
        name: 'Home',
        path: '/seller/welcome'
      },
      {
        name: 'Reports',
        path: '/seller/reports/performance'
      },
      {
        name: 'Product',
        path: '/seller/reports/product'
      }
    ]
  };
  const showTop10 = ref(false);
  const showlineChartModal = ref(false);
  const chartWidth = ref(1092);
  const spinning = ref(false);
  const dataList = ref([]);
  const params = ref({
    marketplace: 'amazon.com',
    startReportDateTimestamp:moment().subtract(30, 'day').valueOf(),
    endReportDateTimestamp: moment().valueOf(),
    brandId: null,
    productId: null,
    metricName: 'clicks',
    asin: null,
  })

  const itemInfo = ref();
  const tableList = ref([]);
  const mainRef = ref();
  const tableTotal = ref(0);
  const tableParams = ref({
    page: 1,
    size: 200,
    sortDirection: '',
    sortField: ''
  })

  const tagNumParams = ref({
    totalClicks: null,
    totalDetailPageViews: null,
    totalAddToCart: null,
    totalConversions: null,
    totalSalesAmount: null,
    totalDiscountedSalesAmount: null,
    totalBonusAmount: null,
    totalCommissionAndFeeAmount: null,
    lastUpdateAt: null
  });

  const selectDaysNum = computed(() => {
    const { endReportDateTimestamp, startReportDateTimestamp } = params.value;
    const intervalDays = moment(endReportDateTimestamp).diff(moment(startReportDateTimestamp), 'days');
    return intervalDays;
  });

  const chartParams = computed(() => {
    let lineColor, lineBgOffsetTop, lineBgOffsetZero, chartParamsData, itemData;
    itemData =  RESPORTS_TYPE_MAP[params.value.metricName];
    lineColor = itemData.lineColor;
    lineBgOffsetTop = itemData.lineBgOffsetTop;
    lineBgOffsetZero = itemData.lineBgOffsetZero;
    chartParamsData = { lineColor, lineBgOffsetTop, lineBgOffsetZero }
    return chartParamsData;
  })

  const getChartWidth = () => {
    const $chart = document.querySelector('.chart');
    const widthNum = $chart.clientWidth - 20;
    chartWidth.value = widthNum;
  }

  const setTableParams = obj => {
    const newObj = {...tableParams.value, ...obj};
    tableParams.value = newObj;
  }

  const setParams = obj => {
    const newObj = {...params.value, ...obj};
    params.value = newObj;
  }

  watch(params, async (newValue, oldValue) => {
    if (newValue.metricName !== oldValue.metricName) {
      getList();
    } else {
      getSellerCommissionReportsAgg();
      getList();
      getTableList();
    }
  });

  watch(tableParams, async () => {
    getTableList();
  });

  const onClickBarChart = productAsin => {
    const item = dataList.value.find(i => i.productAsin === productAsin);
    itemInfo.value = item;
    showlineChartModal.value = true;
  }

  const onClickTableChart = item => {
    itemInfo.value = item;
    showlineChartModal.value = true;
  }

  const onCancleModal = () => {
    showlineChartModal.value = false;
    itemInfo.value = undefined;
  } 

  const getList = async () => {
    spinning.value = true;
    let newparams = {...params.value};
    const res = await reportsApi.getSellerCommissionReportsProductMetric(newparams);
    let { success, data } = res;
    if (success) {
      if (data.length > 10) {
        data = data.splice(0, 10);
        showTop10.value = true;
      }
      dataList.value = data;
    }
    spinning.value = false;
  }

  const getTableList = async () => {
    spinning.value = true;
    let newparams = {...params.value, ...tableParams.value};
    const res = await reportsApi.getSellerCommissionReportsProduct(newparams);
    const { success, data } = res;
    if (success) {
      tableList.value = data.data;
      tableTotal.value = data.total;
    }
    spinning.value = false;
  }

  const getSellerCommissionReportsAgg = async () => {
    const res = await reportsApi.getSellerCommissionReportsAgg(params.value);
    const { success, data } = res;
    if (success) {
      tagNumParams.value = data;
    }
  }

  const handleExportClick = async ({ key }) => {
    let exportParams = {
      endReportDatePstTimestamp: moment().valueOf(),
      marketplace: params.value.marketplace,
      startReportDatePstTimestamp: moment().subtract(365, 'day').valueOf(),
    }, newExportParams = {};
    if (key === 'filter') {
      exportParams = {...params.value };
    }
    for(let item in exportParams) {
      if (exportParams[item]) {
        newExportParams[item] = exportParams[item];
      }
    }
    const finalParams = {
      qs: newExportParams,
      fileName: `Product-Reports-${moment().format('YYYY-MM-DD')}.csv`,
    }
    reportsApi.exportCommissionProductReports(finalParams);
  }

  onMounted(() => {
    getChartWidth();
    getList();
    getTableList();
    getSellerCommissionReportsAgg();
    window.addEventListener('resize', function() {
      getChartWidth();
    });
  })

</script>
<style lang="less" scoped>
  .performance {
    padding:0 16px;
  }
  .content-main {
    border-radius: 12px;
    overflow: hidden;
    border-radius: 12px;
    background-color: var(--color-white);
    box-shadow: var(--section-box-shadow-6);
  }
  .content {
    padding: 16px;
    .tags {
      padding: 20px 12px;
    }
    .table {
      margin-top: 40px;
    }
    .chart {
      padding: 0 10px;
    }
    > p {
      display: flex;
      font-weight: 600;
      font-size: 20px;
      color: var(--dark-1);
      letter-spacing: 0.5px;
      line-height: 28px;
      margin-bottom: 30px;
      > span {
        color: var(--red-text);
        font-size: 16px;
        margin-left: 10px;
        line-height: 28px;
      }
    }
    .content-head {
      display: flex;
      justify-content: space-between;
      height: 64px;
      padding-left: 16px;
      padding-right: 16px;
      > p {
        font-size: 14px;
        color: var(--dark-2);
        line-height: 19px;
        align-self: center;
      }
      > button {
        align-self: center;
        cursor: pointer;
        display: flex;
        .jiantou {
          width: 16px;
          height: 16px;
          fill: var(--dark-3);
          margin-left: 8px;
        }
        .daochu {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          fill: var(--dark-1);
        }
        > svg {
          align-self: center;
        }
        > span {
          align-self: center;
        }
        .btn-text {
          align-self: center;
          font-weight: 500;
          line-height: 20px;
          margin-right: 8px;
        }
      }
    }
  }
</style>
