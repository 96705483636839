<template>
    <div ref="chartRef" class="chart"></div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';

import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import {
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent
} from 'echarts/components';

import moment from 'moment-timezone';

const props = defineProps(['commissionHistoryList'])

const chartRef = ref()
let commissionHistoryChart = null;

onMounted(() => {
    echarts.use([LineChart, TitleComponent, TooltipComponent, GridComponent, LegendComponent, CanvasRenderer])
})

const setChart = () => {
    let data = props.commissionHistoryList;
    let chartOptions = {
        grid: {
            width: 840,
            left: '1%',
            right: '1%',
            bottom: '5%',
            top: '5%',
            containLabel: true,
        },
        tooltip: {
            trigger: 'axis',
            backgroundColor: '#fff',
            borderColor: '#fff',
            extraCssText: 'box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);',
        },
        xAxis: {
            type: 'category',
            data: (() => {
                let optData = data.map((item, index) => moment(item.startDate).format('MMM DD')).reverse()
                optData.push('Current')
                return optData
            })(),
            axisLine: {
                lineStyle: {
                    color: 'rgba(15, 6, 41, .5)'
                }
            },
            axisLabel: {
                fontSize: 12
            }
        },
        yAxis: {
            type: 'value',
            axisLabel: {
                formatter: '{value}%'
            },
            axisLine: {
                lineStyle: {
                    color: 'rgba(15, 6, 41, .7)'
                }
            }
        },
        series: [
            {
                data: (() => {
                    let optData = data.map(item => item.commission).reverse()
                    optData.push(data[0].commission)
                    return optData
                })(),
                step: 'end',
                type: 'line',
                color: '#835CF2',
                lineStyle: {
                    width: 2
                },
                areaStyle: {
                    color: {
                        type: 'linear',
                        x: 0,
                        y: 0,
                        x2: 0,
                        y2: 1,
                        colorStops: [{
                            offset: 0, color: 'rgb(131, 92, 242, .3)' // 0% 处的颜色
                        }, {
                            offset: 1, color: 'rgb(131, 92, 242, 0)' // 100% 处的颜色
                        }],
                        global: false // 缺省为 false
                    },
                }
            }
        ]
    }
    commissionHistoryChart.setOption(chartOptions)
}

watch([() => props.commissionHistoryList, chartRef], async () => {
    if (chartRef) {
        if (!commissionHistoryChart) {
            commissionHistoryChart = echarts.init(chartRef.value)
        }
        setChart();
    }
})

</script>

<style lang="less">
.chart {
    height: 300px;
    margin: 0 36px
}

</style>