<template>
  <a-config-provider :theme="{
    token: {
      colorPrimary: '#835CF2',
      borderRadius: '8px',
      fontFamily: 'Sora,PingFang SC,Helvetica Neue,Helvetica,Microsoft YaHei,Arial,sans-serif',
      colorPrimaryHover: 'rgba(117,82,217,1)'
    },
    components: {
      Button: {
        borderRadiusSM: 6,
        colorPrimaryActive: 'rgba(104, 73, 193, 1)',
      },
      Input: {
        colorBorder: 'rgba(204, 199, 217, 1);',
        controlOutline: 'rgba(131, 92, 242, 0.8);',
        controlOutlineWidth: 1,
        colorPrimaryActive: 'rgba(131, 92, 242, 0.8)',
        colorPrimaryHover: 'rgba(156, 151, 172, 1);',
        colorBgContainer: '#fff'
      }
    }
  }">
    <a-spin :spinning="store.userLoading">
      <router-view></router-view>
    </a-spin>
  </a-config-provider>
</template>

<script setup>
import { theme } from 'ant-design-vue';
import { watch } from 'vue';
import { useUserInfoStore } from './storeData/index';

const store = useUserInfoStore();

</script>

<style>
.a.ant-input-affix-wrapper {
  box-shadow: var(--section-box-shadow-2) !important;
}
.ant-input-affix-wrapper-focused {
  outline: 1px solid rgba(131, 92, 242, 0.8) !important;
  border-color: rgba(131, 92, 242, 0.8) !important;
}
</style>