<template>
  <div class="product">
    <div class="breadcrumbs">
      <breadcrumbs :breadcrumbsData="breadcrumbsData" #icon>
        <svg class="icon" aria-hidden="true" style="fill: #fff">
          <use xlink:href="#icon-shangpin"></use>
        </svg>
      </breadcrumbs>
    </div>
    <div class="content-wrap">
      <div class="content-main">
        <a-spin :spinning="loading">
          <filterCont
            :params="params"
            :getList="getList"
            :setParams="setParams"
            :getCommissionReportsAgg="getCommissionReportsAgg"
          />
          <tag
            :totalParams="totalParams"
            :params="params"
            :currency="currency"
          />
          <tableCont
            :params="params"
            :dataList="dataList"
            :editNote="editNote"
            :setParams="setParams"
            :getList="getList"
            :listTotal="listTotal"
          />
        </a-spin>
      </div>
    </div>
  </div>
</template>
<script lang="jsx" setup>
  import moment from 'moment';
  import { onMounted, ref, computed } from 'vue';
  import { publisherAdminApi } from '~/server/index'
  import breadcrumbs from '~/pages/seller/breadcrumbs.vue';
  import filterCont from './filterCont.vue';
  import tag from './tag.vue';
  import tableCont from './tableCont.vue';
  import { AMAZON_CURRENCY_MAPPER } from '~/contants/common';

  const loading = ref(true);
  const dataList = ref([]);
  const listTotal = ref(0);
  const totalParams = ref({
    totalCommissionAmount: 0,
    totalCommissionAndFeeAmount: 0,
    totalDiscountedSalesAmount: 0,
    totalFeeAmount: 0,
    totalSalesAmount: 0,
    lastUpdateAt: moment().valueOf()
  })
  const params = ref({
    asin: null,
    brandId: null,
    endReportDatePstTimestamp: moment().valueOf(),
    marketplace: 'amazon.com',
    publisherId: null,
    productStatus: '',
    sellerId: null,
    startReportDatePstTimestamp: moment().subtract(180, 'day').valueOf(),
    status: [],
    page: 1,
    size: 200,
    sortField: '',
    sortDirection: '',
  })

  const currency = computed(() => {
    const data = AMAZON_CURRENCY_MAPPER[params.value.marketplace];
    return data;
  })

  const breadcrumbsData = computed(() => {
    const data =  {
      title: 'Commissions',
      des: `Last Update at: ${moment(totalParams.value.lastUpdateAt).format('YYYY-MM-DD hh:mm')}`,
      color:'#4682FA',
      hasTitleIcon:true,
      links: [
        {
          name: "Home",
          path: "/operator/users",
        },
        {
          name: "Commissions",
          path: "/operator/commissions",
        },
      ],
    };
    return data;
  })

 

  const setParams = data => {
    if (data) {
      params.value = data;
    } else {
      params.value = {
        asin: null,
        brandId: null,
        endReportDatePstTimestamp: moment().valueOf(),
        marketplace: 'amazon.com',
        publisherId: null,
        sellerId: null,
        startReportDatePstTimestamp: moment().subtract(180, 'day').valueOf(),
        status: [],
        page: 1,
        size: 200,
        sortField: '',
        sortDirection: '',
      };
      getList();
      getCommissionReportsAgg();
    }
  }

  const editNote = async paramsData => {
    loading.value = true;
    const res = await publisherAdminApi.changePublisherNote(paramsData);
    const index = dataList.value.findIndex(item => item.id === paramsData.id);
    const newDataList = [...dataList.value];
    newDataList[index].note = paramsData.note;
    dataList.value = newDataList;
    loading.value = false;
  }

  const getList = async () => {
    loading.value = true;
    let newParams = { ...params.value };
    newParams.status = newParams.status.join(',')
    const res = await publisherAdminApi.getCommissionReports(newParams);
    if (res) {
      const { data, total } = res;
      dataList.value = data;
      listTotal.value = total;
    }
    loading.value = false;
  }

  const getCommissionReportsAgg = async () => {
    loading.value = true;
    let newParams = { ...params.value };
    newParams.status = newParams.status.join(',')
    const res = await publisherAdminApi.getCommissionReportsAgg(newParams);
    if (res) {
      totalParams.value = res;
    }
    loading.value = false;
  }

  onMounted(() => {
    getCommissionReportsAgg();
    getList();
  })


</script>
<style lang="less" scoped>
  .product {
    padding:0 16px 16px;
  }
  .content-main {
    overflow: hidden;
    border-radius: 8px;
    background-color: var(--color-white);
    box-shadow: var(--section-box-shadow-6);
  }
</style>