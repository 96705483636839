<template>
  <div class="filters" ref="filtersWrapper" >
    <a-popover placement="bottom" :getPopupContainer="()=>$refs.filtersWrapper" 
      v-model:open="visible"
      @openChange="handleOpen"
      trigger="click"
    >
     <artmisads-select-button
        showFilterIcon="true"
        :filterNum="filtersNum"
        showDownIcon="true"
      >Filters
      </artmisads-select-button>
     <template #content  >
        <div class="filters-down" ref="filtersDown">
          <div class="filters-name">Commission Min</div>
          <div class="commission-number">
              <artmisads-input-number
              v-model:value="filters.commission"
              style="width: 240px"  
              placeholder="Please enter..."
              :step="0.1"
              min="0"
              max="100"
              suffix="%"
              @change="handleChange($event,'comission')"
              >
              </artmisads-input-number>
            </div>
          <div class="filters-name">Availability </div>
          <div >
            <artmisads-select
              v-model:value="filters.availability"
              style="width: 240px"
              @change="handleChange($event,'availability')"
              :getPopupContainer="()=>$refs.filtersDown"
            >
              <a-select-option value="all">All</a-select-option>
              <a-select-option value="IN_STOCK" >In Stock</a-select-option>
              <a-select-option value="OUT_OF_STOCK">Out of Stock</a-select-option>
            </artmisads-select>
          </div>
          <div class="filters-name" >Price </div>
          <div>
            <artmisads-select
              v-model:value="filters.price"
              style="width: 240px"
              @change="handleChange($event,'price')"
              :getPopupContainer="()=>$refs.filtersDown"
              placement="topRight"
            >
              <a-select-option value="all"  >All</a-select-option>
              <a-select-option value="get10" >Under {{currencySymbol}}10</a-select-option>
              <a-select-option value="get15" >{{currencySymbol}}10 to {{currencySymbol}}25</a-select-option>
              <a-select-option value="get25" >{{currencySymbol}}25 to {{currencySymbol}}50</a-select-option>
              <a-select-option value="get50" >{{currencySymbol}}50 to {{currencySymbol}}100</a-select-option>
              <a-select-option value="get100" >{{currencySymbol}}100 to {{currencySymbol}}200</a-select-option>
              <a-select-option value="get200" >{{currencySymbol}}200 & Above </a-select-option>
              
            </artmisads-select>
          </div>
        
        </div>
        <div class="btn">
          <a
            @click="reset"
            :class="'reset' + (filtersNum ? '' : ' disabled')"
            href="javascript:;"
          >
            <span style="margin-right: 3px">Reset</span
            ><span class="filter-num" v-show="filtersNum">{{ filtersNum }}</span
            ><span style="margin-left: 3px">Filters</span>
          </a>
          <artmisads-button type="primary" size="middle" @click="onSave">Confirm</artmisads-button>
        </div>
     </template> 
    </a-popover>
   
  </div>
  
</template>
<script setup name="FilterOptions">
import {ref,onMounted, reactive, toRefs, toRaw, computed} from 'vue';
import {CURRENCY_MAPPER} from '@/contants/common';
import { useUserInfoStore } from '~/storeData/index';

const store = useUserInfoStore();
const currencySymbol = computed(() => {
  return CURRENCY_MAPPER[store.userInfo.countryCode].currency;
});
const emit =defineEmits(['get-data']);
let visible=ref(false);
let filtersWrapper =ref();
let filtersDown =ref();
let filtersNum =ref(0);
let filters=reactive({
  availability:'all',
  commission:undefined,
  price:'all'
})
let filtersOrigi={
  availability:'all',
  commission:null,
  price:'all'
}
let options =[];
const prices=[
  {
    lable:'get10',
    maxPrice:10,
    minPrice:0
  },
  {
    lable:'get15',
    maxPrice:25,
    minPrice:10
  },
  {
    lable:'get25',
    maxPrice:50,
    minPrice:25
  },
  {
    lable:'get50',
    maxPrice:100,
    minPrice:50
  },
  {
    lable:'get100',
    maxPrice:200,
    minPrice:100
  },
  {
    lable:'get200',
    maxPrice:null,
    minPrice:200
  },

]
const onSave=()=>{
  visible.value=false
  let req={...filters};
  if (req.price !='all'){
    prices.forEach(v=>{
      if(v.lable==req.price){
        req.maxPrice = v.maxPrice;
        req.minPrice =v.minPrice;
      }
    })
    req.page=1;
  } 
  delete req.price;
  emit('get-data',req)
}
const handleChange =(e,itemName)=>{
  let isMark = options.includes(itemName)
  if(!isMark &&  e!= filtersOrigi[itemName]){
    options.push(itemName);
    filtersNum.value ++
  }
  if(isMark &&  e == filtersOrigi[itemName]){
    let index=options.findIndex(item=>item==itemName);
    options.splice(index,1);
    if(filtersNum){ filtersNum.value --}
   
  }
  if(itemName == 'comission'){
    filters.commission=e;
    if (isNaN(parseFloat(e))) {
    filters.commission = undefined;
    return;
    }
    filters.commission = Math.floor(e * 10) / 10;
  }
}
const reset=()=>{
  visible.value=false
  let _filters={
    availability:'all',
    commission:undefined,
    price:'all'
  }
  options=[];
  Object.assign(filters,_filters);
  filtersNum.value=0;
  emit('get-data',{})
}
</script>
<style lang="less" scoped>
@base-font:{
  font-family: Sora, sans-serif;
  font-size: 14px;
  color: var(--dark-1);
  letter-spacing: 0.4px;
  line-height: 20px;
}
.filters-down:deep(.ant-input-number-handler-wrap){
  opacity: 1 !important;
}
.filters:deep{
  .ant-popover{
    left: 850px !important;
  }
  .filters-down{
    width: 280px;
    padding: 16px 16px 0;
    border-radius: 8px;
    background-color: var(--color-white);
  }
  .filters-name{
    margin:16px 0 8px 0;
    @base-font();
    opacity: 0.7;
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.3px;
    line-height: 18px;
  }
  .btn {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid var(--border-4);
      padding: 8px 16px;
      margin-top: 32px;
      .reset {
        line-height: 32px;
        color: var(--primary-text);
        &.disabled {
          color: var(--dark-4);
        }
        .filter-num {
          font-weight: 700;
        }
      }
      button {
        padding: 0 16px;
        border-radius: 16px;
        min-width: 88px !important;
      }
    }
 
  .filters-button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    @base-font();
    font-weight: 500;
    opacity: .7;
  }
  .filters-num{
    margin: 4px;
    width: 20px;
    height: 18px;
    border-radius: 4px;
    background-color: var(--yellow-1);
    display: flex;
    align-items: center;
    justify-content: center;
    @base-font();
    font-weight: 500;
    line-height: 14px;
  }
}

</style>