import client from '../client';
import { exportFile } from '../requestDownloadFile';

export default {
  exportCommissionBrandReports: data => {
    return exportFile('seller/commission/reports/brand/export', data.qs, data.fileName);
  },
  exportCommissionPublisherReports: data => {
    return exportFile('seller/commission/reports/publisher/export', data.qs, data.fileName);
  },
  exportCommissionProductReports: data => {
    return exportFile('seller/commission/reports/product/export', data.qs, data.fileName);
  },
  exportCommissionPerformanceReports: data => {
    return exportFile('seller/commission/reports/performance/export', data.qs, data.fileName);
  },


  getSellerCommissionReportsAgg: data => {
    return client.get(`seller/commission/reports/agg`, { params: data }).then(res => res.data);
  },


  getSellerCommissionReportsBrand: data => {
    return client.get(`seller/commission/reports/brand`, { params: data }).then(res => res.data);
  },
  getSellerCommissionReportsBrandDailyMetric: data => {
    return client.get(`seller/commission/reports/brand/daily-metric`, { params: data }).then(res => res.data);
  },
  getSellerCommissionReportsBrandMetric: data => {
    return client.get(`seller/commission/reports/brand/metric`, { params: data }).then(res => res.data);
  },
  getSellerCommissionReportsPerformance: data => {
    return client.get(`seller/commission/reports/performance`, { params: data }).then(res => res.data);
  },
  getSellerCommissionReportsPerformanceDailyMetric: data => {
    return client.get(`seller/commission/reports/performance/daily-metric`, { params: data }).then(res => res.data);
  },
  getSellerCommissionReportsProduct: data => {
    return client.get(`seller/commission/reports/product`, { params: data }).then(res => res.data);
  },
  getSellerCommissionReportsProductDailyMetric: data => {
    return client.get(`seller/commission/reports/product/daily-metric`, { params: data }).then(res => res.data);
  },
  getSellerCommissionReportsProductMetric: data => {
    return client.get(`seller/commission/reports/product/metric`, { params: data }).then(res => res.data);
  },
  getSellerCommissionReportsPublisher: data => {
    return client.get(`seller/commission/reports/publisher`, { params: data }).then(res => res.data);
  },
  getSellerCommissionReportsPublisherDailyMetric: data => {
    return client.get(`seller/commission/reports/publisher/daily-metric`, { params: data }).then(res => res.data);
  },
  getSellerCommissionReportsPublisherMetric: data => {
    return client.get(`seller/commission/reports/publisher/metric`, { params: data }).then(res => res.data);
  },
  getSellerBrandSearch: data => {
    return client.get(`seller/brands/search`, { params: data }).then(res => res.data);
  },
  getSellerProductSearch: data => {
    return client.get(`seller/product/searchAsin`, { params: data }).then(res => res.data);
  },
}
