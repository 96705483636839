<!--
 * @Author: Hankill(hao.chen)
 * @Date: 2024-12-18 10:01:34
 * @LastEditors: Hankill(hao.chen)
 * @LastEditTime: 2024-12-27 16:31:16
 * @FilePath: /web_artemisads/src/components/PriceDealLayout.vue
 * @Description: 
-->
<template>
    <div class="coupon-price">
        <div class="promo-code" v-if="record.promoCode" @click="() => onCopy(record.promoCode)">
            <div class="promo-code-title">
                <p>PROMO</p>
                <p>CODE</p>
            </div>
            <div class="promo-code-brand">
                <p>{{ record.promoCode }}</p>
            </div>
            <div>
                <artemisads-icon name="copy2" />
            </div>
        </div>
        <p class="full-price">{{ record.currencySymbol }}{{ record.fullPrice.toFixed(2) }}</p>
        <p class="final-price">{{ record.currencySymbol }}{{ record.finalPrice.toFixed(2) }}</p>
        <p class="price-off-lite">{{ record.priceOff.toFixed(1) }}% off</p>
    </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { copyToClipboard } from '~/utils'

const props = defineProps({
    record: {
        type: Object,
    },
});
const record = ref(props.record);
watch(
  () => props.record,
  (newValue) => {
    console.log(newValue);
    record.value = newValue;
  }
)
const onCopy = (v) => {
    copyToClipboard(v);
}
</script>

<style lang="less" scoped>
.coupon-price {
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;
    .full-price {
        margin-right: 8px;
        color: var(--dark-3);
        text-decoration: line-through;
    }
    .final-price {
        font-weight: 600 !important;
        color: var(--dark-1);
    }
    .price-off {
        background: rgba(242, 85, 68, 0.1);
        border: 1px solid rgba(242, 85, 68, 0.4);
        border-radius: 4px;
        padding: 0 6px;
        color: rgba(235, 78, 61, 1);
        font-weight: 500;
        margin-left: 8px;
    }
    .price-off-lite {
        font-size: 12px;
        height: 12px;
        position: absolute;
        right: 0px;
        bottom: 4px;
        color: rgba(235, 78, 61, 1);
    }
}
.promo-code {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--border-1);
    border-radius: 6px;
    padding: 0px 6px 0 2px;
    box-sizing: border-box;
    margin-right: 8px;
    height: 24px;
    cursor: pointer;
    .anticon {
            color: var(--dark-4);
            margin-left: 4px;
        }
        &:hover {
         .anticon {
            color: var(--dark-2)
         }   
    }
    .promo-code-brand {
        font-size: 13px;
        font-weight: 600;
        margin-right: 2px;
        border-left: 1px solid var(--border-1);
        padding-left: 8px;
    }
    .promo-code-title{
        width: 44px;
        height: 24px;
        line-height: 10px;
        color: var(--dark-3);
        p {
            font-size: 12px;
            transform: scale(0.8);
            font-weight: 500;
            letter-spacing: 0.2px;
            color: var(--dark-3);
            line-height: 12px;
            height: 12px;
            display: block;
            margin-top: 2px;
            &:last-child {
                margin-top: -3px;
                color: var(--dark-3);
                letter-spacing: 3.3px;
            }
        }
    }
}
</style>