<template>
  <div class="filterBar" ref="marketplaceRef">
    <div class="filterBar-content">
      <div class="marketplace">
        <span>Marketplace</span>
        <artmisads-select
          style="width: 232px"
          v-model:value="props.params.marketplace"
          @change="onChangeCountry"
          :getPopupContainer="() => marketplaceRef"
        >
          <template v-for="option in COUNTRY_BASE" :key="option.countryCode"> 
            <a-select-option :value="option.host" >
              <Icon :name="option.icon"></Icon>
              <span>{{ option.host }}</span>
            </a-select-option>
          </template>
        </artmisads-select>
      </div>
      <div class="date">
        <span>Date Range</span>
        <artemisads-range-picker
          :allowClear="false"
          :value="hackValue || dateParams"
          @openChange="onOpenChange"
          :presets="RANGE_PRESETS"
          :disabled-date="disabledDate"
          @change="onChangeDate"
          @calendarChange="onCalendarChange"
        />
      </div>
      <div class="brand">
        <span>Brand</span>
        <artmisads-select
          show-search
          v-model:value="props.params.brandId"
          style="width: 232px"
          :options="brandOptions"
          @search="searchBrand"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          placeholder="All"
          allowClear
          @change="onChangeBrand"
        />
      </div>
      <div class="product">
        <span>Product</span>
        <artmisads-select
          show-search
          v-model:value="props.params.asin"
          style="width: 232px"
          :options="productOptions"
          @search="searchProduct"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          placeholder="All"
          allowClear
          @change="onChangeProduct"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
  import moment from 'moment';
  import { ref, watch } from 'vue';

  import { COUNTRY_BASE } from '~/contants/common';
  import { RANGE_PRESETS } from '~/contants/reports';
  import Icon from '../Icon.vue';
  import { reportsApi } from '~/server/index';

  const dateParams = ref([
    moment().subtract(30, 'day'), moment()
  ])
  const brandOptions = ref([]);
  const productOptions = ref([]);
  const dates = ref();
  const hackValue = ref();
  const marketplaceRef = ref(null);
  const props = defineProps(['params', 'dateParams', 'setParams']);

  const disabledDate = current => {
    let notDate = false, tooLate = false, tooEarly = false;
    notDate = current.valueOf() > moment().valueOf();
    if (dates.value && dates.value.length) {
      tooEarly = dates.value[1] && dates.value[1].diff(current, 'days') > 365;
      tooLate = dates.value[0] && current.diff(dates.value[0], 'days') > 365;
    }
    return notDate || tooEarly || tooLate;
  };

  const onOpenChange = open => {
    if (open) {
      dates.value = [];
      hackValue.value = [];
    } else {
      hackValue.value = undefined;
    }
  };
  const onChange = val => {
    dateParams.value = val;
  };
  const onCalendarChange = val => {
    dates.value = val;
  };

  const onChangeDate = val => {
    if (val) {
      dateParams.value = val;
      const dateTime = {
        startReportDateTimestamp: val[0].valueOf(),
        endReportDateTimestamp: val[1].valueOf(),
      };
      props.setParams(dateTime);
    } else {
      dateParams.value = [];
    }
  }

  const searchBrand = async keyword => {
    const params = {
      keyword,
      marketplace: props.params.marketplace
    };
    const res = await reportsApi.getSellerBrandSearch(params);
    let { success, data } = res;
    if (success) {
      data = data.map(item => {
        item.value = item.id;
        item.label = item.amazonBrandName;
        return item;
      })
      brandOptions.value = data;
    }
  }

  const searchProduct = async asin => {
    const params = {
      asin,
      marketplace: props.params.marketplace
    };
    const res = await reportsApi.getSellerProductSearch(params);
    let { success, data } = res;
    if (success) {
      data = data.map(item => {
        const newItem = {
          value: item,
          label: item
        }
        return newItem;
      })
      productOptions.value = data;
    }
  }

  const onChangeBrand = brandId => {
    props.setParams({ brandId });
  }

  const onChangeProduct = asin => {
    props.setParams({ asin });
  }

  const onChangeCountry = marketplace => {
    props.setParams({ marketplace });
  }

</script>
<style lang="less" scoped>
  .filterBar {
    display: flex;
    padding: 0 16px;
    .filterBar-content {
      padding: 16px;
      background-color: var(--bg-gray-1);
      width: 100%;
      display: flex;
      justify-content: space-between;
      border-radius: 8px;
    }
  }
  .marketplace, .date, .product, .brand {
    display: flex;
    flex-direction: column;
    > span {
      color: var(--dark-2);
      font-weight: 500;
      font-size: 13px;
      letter-spacing: 0.3px;
      line-height: 18px;
      margin-bottom: 4px;
    }
  }
  .marketplace {
    display: flex;
    align-content: center;
    &:deep{
      .ant-select-selection-item,.ant-select-item-option-content{
        display: flex;
        align-items: center;
        .iconfont{
          margin-right: 4px;
          font-size: 18px;
        }
      }
    }
  }
  
</style>
