<template>
  <svg :class="className" aria-hidden="true">
    <use :xlink:href="'#icon-' + name" />
  </svg>
</template>

<script setup>
import { computed,defineProps } from 'vue'
const props = defineProps(['name','class'])
const className = computed(() => {
  let icon_class = "iconfont";
  if (props.class) {
    icon_class += ` ${props.class}`;
  }
  return icon_class;
});


</script>