<!--
 * @Author: Hankill(hao.chen)
 * @Date: 2024-12-10 15:05:28
 * @LastEditors: Hankill(hao.chen)
 * @LastEditTime: 2024-12-13 10:40:29
 * @FilePath: /web_artemisads/src/artemisadsComponents/artemisadsDivid.vue
 * @Description: divid component
-->
<template>
    <div :className="className">
    </div>
  </template>
    
  <script setup>
    import { computed } from 'vue';
    const props = defineProps({
      dashed: {
        type: String,
        default: ''  // 默认为空字符串
      }
    });
    const className = computed(() => {
    let lineClass = 'artemisads-divid'
    if (props.dashed === 'dashed') {
      lineClass += ' dashed';
    }
      return lineClass;
    })
  </script>
    
    <style lang="less" scoped>
    .artemisads-divid {
      width: 100%;
      height: 1px;
      background-color: var(--border-4);
      &.dashed {
        background-color: transparent;
        border-bottom: 1px dashed var(--border-4);
      }
    }
    </style>
    