import { axios as request } from './apiAdmin';
import { exportFile } from '../requestDownloadFile';

export default {
    login: data => {
        return request.post('auth/login', data);
    },
    signOut: data => {
        return request.post('auth/logout', data);
    },
    getPublisherList: data => {
        return request.get('publisher/list', {params: data});
    },
    modifyPublisher: data => {
        return request.put('publisher/note', data);
    },
    reviewPublisher: data => {
        return request.put('publisher/review', data);
    },
    getPublisherCount: () => {
        return request.get('publisher/count');
    },
    // 获取商家近 30 天的广告活动聚合报表
    getSellerReport: data => {
        return request.get('seller/report/campaign-agg-30d', { params: data });
    },
    getSellerList: () => {
        return request.get('seller/list');
    },
    // 管理员
    getUserList: data => {
        return request.get('users', { params: data });
    },
    addUser: data => {
        return request.post('users', data);
    },
    enableUser: data => {
        return request.post(`users/${data.id}/enable`);
    },
    updateUser: data => {
        return request.patch(`users/${data.id}`, data);
    },
    disableUser: data => {
        return request.post(`users/${data.id}/disable`);
    },
    getCommissionReports: data => {
        return request.get('commission/reports', { params: data }).then(res=>res.data);
    },
    getCommissionReportsAgg: data => {
        return request.get('commission/reports/agg', { params: data }).then(res=>res.data);
    },
    searchPublisher: data => {
        return request.get('publisher/search', { params: data }).then(res=>res.data);
    },
    searchSeller: data => {
        return request.get('seller/search', { params: data }).then(res=>res.data);
    },
    searchBrand: data => {
        return request.get('brand/search', { params: data }).then(res=>res.data);
    },
    searchAsin: data => {
        return request.get(`seller/searchAsin?asin=${data.asin}`, data).then(res=>res.data);
    },
    changePublisherNote: data => {
        return request.patch(`commission/reports/note/${data.id}`, data).then(res=>res.data);
    },
    commissionReportsExport:data => {
        return exportFile(`commission/reports/export`,  data.qs, data.fileName, data.platform);
    },
}