<template>
  <footer>
    <div class="img">
      <img src="../assets/images/admin/amazon.png" alt="Amazon ads" />
    </div>
    <div class="word">
      <div>Copyright 2024 ArtemisAds Inc. All rights reserved</div>
      <div class="txt">
        <a href="/privacy-policy" target="_blank">Privacy Policy | </a>
        <a href="/terms-of-service" target="_blank">Terms of Service</a>
      </div>
    </div>
  </footer>
</template>
<style lang="less" scoped>
footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
  width: 100%;
  padding: 16px 8px;
  .img {
    width: 185px;
    height: 32px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .word {
    opacity: 0.5;
    font-weight: 400;
    font-size: 12px;
    color: #0f0629;
    letter-spacing: 0.3px;
    text-align: right;
    line-height: 16px;
    text-align: right;
  }
  .txt {
    > a {
      font-weight: 500;
      font-size: 12px;
      color: #0f0629;
      letter-spacing: 0.3px;
      text-align: right;
      line-height: 16px;
      text-align: right;
    }
  }
}
</style>