<template>
  <div class="brand-detail">
    <breadcrumbs :breadcrumbsData="breadcrumbsData" #icon>
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#icon-pinpai"></use>
      </svg>
    </breadcrumbs>
    <a-spin :spinning="load">
      <div>
        <pulic-report-notice type="urgent" @click="onOpenModal({}, 'active')" v-show="brandDetail?.status == 'inactive'">
          <template #title>Brand is not active</template>
          <template #action>Activate Brand</template>
        </pulic-report-notice>
        <div class="brand-overview">
          <div class="card-header">
            <artemisads-page-title>Brand Overview</artemisads-page-title>
            <div class="card-header-btn">
              <artmisads-select-button
                @click="onOpenModal({}, 'updateProfile')"
                :disabled="isDisabled"
              >
                <svg
                  class="icon"
                  aria-hidden="true"
                  style="font-size: 20px; margin-right: 8px"
                >
                  <use xlink:href="#icon-gengxin"></use>
                </svg>
                Update Brand Profile
              </artmisads-select-button>
              <span
                v-show="!isDisabled"
                ref="updateBtn"
                style="position: relative"
              >
                <a-dropdown
                  v-model:open="visible"
                  :trigger="['click']"
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                >
                  <artmisads-select-button>
                    <svg class="icon" aria-hidden="true">
                      <use xlink:href="#icon-gengduo"></use>
                    </svg>
                  </artmisads-select-button>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item
                        @click="onOpenModal({}, 'deactive')"
                        style="white-space: nowrap;margin:4px;"
                      >
                        Deactivate Brand
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </span>
            </div>
          </div>
          <div style="padding: 0 16px; background-color: #fff">
            <a-divider />
          </div>
          <div class="brand-profile">
            <div class="brand-image" :style="brandDetail?.logoImageUrl?{'background-color':'#ffffff'}:''">
              <img
                :src="imgCache(brandDetail?.logoImageUrl)"
                v-if="brandDetail?.logoImageUrl"
              />
              <span v-else></span>
            </div>
            <div class="brand-content">
              <div class="brand-details">
                <div style="margin-bottom: 4px">
                  <a-space>
                    <div class="bio">{{ brandTitle }}</div>
                    <showStatus
                      :isActive="brandDetail.status == 'active'"
                    ></showStatus>
                  </a-space>
                </div>

                <div class="content">
                  {{ brandDetail.biography ? brandDetail.biography : "--" }}
                </div>
              </div>
              <div class="brand-stuff">
                <a-space>
                  <div class="countryIcon">
                    <svg
                      class="icon"
                      aria-hidden="true"
                      style="font-size: 24px"
                      v-show="toLower(userInfo.userInfo?.countryCode) == 'us'"
                    >
                      <use xlink:href="#icon-guoqi-us"></use>
                    </svg>
                    <svg
                      class="icon"
                      aria-hidden="true"
                      style="font-size: 24px"
                      v-show="toLower(userInfo.userInfo?.countryCode) == 'uk'"
                    >
                      <use xlink:href="#icon-guoqi-uk"></use>
                    </svg>
                    <svg
                      class="icon"
                      aria-hidden="true"
                      style="font-size: 24px"
                      v-show="toLower(userInfo.userInfo?.countryCode) == 'fr'"
                    >
                      <use xlink:href="#icon-guoqi-fr"></use>
                    </svg>
                    <svg
                      class="icon"
                      aria-hidden="true"
                      style="font-size: 24px"
                      v-show="toLower(userInfo.userInfo?.countryCode) == 'de'"
                    >
                      <use xlink:href="#icon-guoqi-de"></use>
                    </svg>
                    <svg
                      class="icon"
                      aria-hidden="true"
                      style="font-size: 24px"
                      v-show="toLower(userInfo.userInfo?.countryCode) == 'ca'"
                    >
                      <use xlink:href="#icon-guoqi-ca"></use>
                    </svg>
                    <div class="name">{{ userInfo.userInfo?.countryCode }}</div>
                  </div>
                  <a-space-compact class="brand-products">
                    <div style="padding-right: 4px">Products:</div>
                    <div>
                      {{
                        brandDetail?.activeProductCount +
                        brandDetail?.inactiveProductCount
                      }}
                    </div>
                  </a-space-compact>
                  <a-space-compact class="brand-items">
                    <div style="padding-right: 4px">Email:</div>
                    <div>{{ brandDetail?.contactEmail }}</div>
                  </a-space-compact>
                </a-space>
              </div>
              <div class="brand-terms">
                <a :href="brandDetail?.termsFileUrl"
                  target="_blank"
                  >
                  <span>Brand Terms and Conditions</span>
                  <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-tiaozhuan"></use>
                </svg>
                </a>                
              </div>
            </div>
          </div>
        </div>
        <div class="table-card">
          
          <div class="card-header">
            <artemisads-page-title>Products for this Brand</artemisads-page-title>
          </div>
          <div style="background-color: var(--color-white); padding: 0 16px">
            <a-divider />
          </div>
          <div class="table">
            <div class="table-head">
              <ButtonGroups
                :pagination="pagination"
                :tableData="dataSource"
                @pageChange="onPageChange"
              />
              <div style="display: flex">
                <a-input-search
                  v-model:value="keywords"
                  placeholder="Search..."
                  allow-clear
                  @search="onSearch"
                  @enter="onSearch"
                  class="search"
                >
                </a-input-search>
                <div v-show="!isDisabled" style="margin-left: 8px">
                  <FilterOptions @get-data="getPartData" />
                </div>
              </div>
            </div>
            <artmisads-table
              v-model:pagination="pagination"
              :columns="columns"
              :data-source="dataSource"
              :change="onTableChange"
              class="table-content"
            >
              <template #bodyCell="{ column, record }">
                <template v-if="column.key === 'title'">
                  <a class="product-name" target="_blank" :href="`/seller/product/${record.id}`">
                    <div class="product-img-show" :style="record.imageUrl?{'background-color':'#ffffff'}:''">
                      <img
                        :src="record.imageUrl"
                        alt=""
                        v-if="record.imageUrl"
                      />
                    </div>
                    <div class="product-title">
                      <div>
                        {{ record.title }}
                      </div>
                      <div class="asin">{{ record.asin }}</div>
                    </div>
                  </a>
                </template>
                <template v-if="column.key === 'status'">
                  <showStatus
                    :isActive="record.status == 'active'"
                    :noBorder="true"
                  ></showStatus>
                </template>
              </template>
            </artmisads-table>
          </div>
        </div>
        <TableModal
          :configs="modalConfigs"
          :item="brandDetail"
          @cancel="onModalCancel"
          @ok="onModalOK"
        />
      </div>
    </a-spin>
    <contextHolder />
  </div>
</template>
<script lang="jsx" setup>
import { ref, onMounted, reactive, toRefs } from "vue";
import {
  SearchOutlined,
  ExportOutlined,
  SyncOutlined,
  ExclamationCircleFilled,
  EllipsisOutlined,
} from "@ant-design/icons-vue";
import { imgCache } from '~/utils';
import { useRoute, useRouter } from "vue-router";
import TableModal from "./components/tableModal.vue";
import { brandApi } from "@/server/index";
import {CURRENCY_MAPPER} from '@/contants/common';
import { useUserInfoStore } from "@/storeData";
import { useSystemStore } from "@/storeData/system";
import ButtonGroups from "./components/buttonGroups.vue";
import FilterOptions from "./components/filters.vue";
import breadcrumbs from "../breadcrumbs.vue";
import showStatus from "./components/showStatus.vue";
import PulicReportNotice from "@/components/PulicReportNotice.vue";
import { toLower } from "lodash";
import { message, notification } from 'ant-design-vue';
const [api, contextHolder] = notification.useNotification();
const openNotification = description => {
  api.info({
    message: `Notice`,
    description: description,
    placement: 'topRight',
    duration: 3
  });
};
const breadcrumbsData = reactive({
  title: "Your Brands",
  des: "Review your Creator-facing brand detail - double check that you have uploaded a logo and added a short descrption.",
  color: "#FFD358",
  links: [
    {
      name: "Home",
      path: "/seller/welcome",
    },
    {
      name: "Brands",
      path: "/seller/brand",
    },
  ],
});
const brandTitle=ref();
const load = ref(true);
const updateBtn = ref();
const route = useRoute();
const router = useRouter();
const userInfo = useUserInfoStore();
const systemStore = useSystemStore();
const columns = [
  {
    title: "Product",
    key: "title",
    dataIndex: "title",
  },
  {
    title: "Price",
    dataIndex: "price",
    width: 100,
    customRender: ({ text, record }) => {
      return <div class="product-price">{record.currencySymbol}{text}</div>;
    },
  },
  {
    title: "Commission",
    key: "commission",
    dataIndex: "commission",
    width: 150,
    customRender: ({ text, record }) => (
      <div class="product-commission" style={{'opacity':record.status=='active'?'1':'0.3'}}>{text.toFixed(1)}%</div>
    ),
  },
  {
    title: "Status",
    key: "status",
    dataIndex: "status",
    width: 150,
  },
  {
    title: "ASIN",
    key: "asin",
    dataIndex: "asin",
    width: 150,
    customRender: ({ text, record }) => <div class="product-asin">{text}</div>,
  },
];
const dataSource = ref([]);
const pagination = reactive({
  total: 30,
  defaultPageSize: 10,
  size: 10,
  current: 1,
  showSizeChanger: true,
  pageSize:10
});
const brandDetail = ref({
  logoImageUrl: "",
});
let keywords = ref("");
let isDisabled = ref(false);
let visible = ref(false);
let modalConfigs = ref({
  open: false,
  modalType: "active",
});
let filters = {};

onMounted(() => {
  getData();
});

const getData = () => {
  let { id } = route.params;
  brandApi.getBrandDetail({ brandId: id }).then((res) => {
    if (res.success) {
      if (res.data?.status == "active") {
        isDisabled.value = false;
      } else {
        isDisabled.value = true;
      }
      brandTitle.value=res.data.amazonBrandName;
      breadcrumbsData.title = res.data.amazonBrandName;
      breadcrumbsData.links.push({
        name: res.data.amazonBrandName,
        path: `/seller/brand/detail/${res.data.id}`,
      });
      brandDetail.value = res.data;
    }
  });
  brandApi.getBrandProduct({ brandId: id }).then((res) => {
    load.value = false;
    if (res.success) {
      const { total, data } = res.data;
      dataSource.value = data;
      pagination.total = total;
    }
  });
};
/**
 * 分页获取列表
 * @param [keyword]
 * @param [page]
 * @param [size]
 * @param [status]
 */
const getPartData = (params) => {
  let _params = {
    brandId: route.params.id,
    availability: "",
    title: keywords.value,
    commission: "",
    maxPrice: "",
    minPrice: "",
    page: 1,
    size: pagination.size,
  };
  if (params.page == undefined) {
    pagination.current = 1;
  }
  if (Object.keys(params).length == 0) {
    filters = {};
  }
  let newparams;
  if (params?.availability) {
    filters = { ...params };
    newparams = { ..._params, ...filters };
  } else {
    newparams = { ..._params, ...filters, ...params };
  }

  newparams = Object.keys(newparams).reduce((acc, key) => {
    if (newparams[key] == "all") {
    } else if (
      newparams[key] !== null &&
      newparams[key] !== undefined &&
      newparams[key] !== ""
    ) {
      acc[key] = newparams[key];
    }
    return acc;
  }, {});
  brandApi.getBrandProduct(newparams).then((res) => {
    console.log("product", res);
    if (res.success) {
      const { total, data } = res.data;
      if (params.page || params.size) {
        document.querySelector(".table-card").scrollIntoView({
          behavior: "smooth",
        });
      }

      dataSource.value = data;
      pagination.total = total;
    }
  });
};
const onTableChange = ({ current, pageSize }) => {
  pagination.current = current;
  pagination.size = pageSize;
  pagination.pageSize=pageSize;
  getPartData({ page: current, size: pageSize });
};
const onSearch = () => {
  getPartData({ title: keywords.value });
};
const onPageChange = (current) => {
  pagination.current = current;
  getPartData({ page: current });
};
const onOpenModal = (record, modalType) => {
  switch (modalType) {
    case "active":
      modalConfigs.value.modalType = "active";
      break;
    case "deactive":
      visible.value = false;
      modalConfigs.value.modalType = "deactive";
      break;
    case "updateProfile":
      modalConfigs.value.modalType = "updateProfile";
      break;
  }
  modalConfigs.value.open = true;
};

const onModalOK = (item) => {
  switch (modalConfigs.value.modalType) {
    case "deactive":
      onDeactiveBrand(item);
      break;
    case "updateProfile":
      onUpdateProfile(item);
      break;
    case "active":
      onActiveBrand(item);
      break;
  }
};
const onModalCancel = () => {
  modalConfigs.value.open = false;
};
const onUpdateProfile = (item) => {
  modalConfigs.value.open = false;
  let { id } = route.params;
  brandApi.updateBrandDetail({ id, req: item }).then((res) => {
    if (res.success) {
      brandDetail.value.biography = item.biography;
      brandDetail.value.logoImageUrl = item.logoImageUrl;
      message.success("Success!", 1);
    }
  });
};
const onDeactiveBrand = (item) => {
  modalConfigs.value.open = false;
  console.log("modal-close", item);
  let { id } = route.params;
  brandApi.disableBrand({ id }).then((res) => {
    if (res.success) {
      brandDetail.value.status = "inactive";
      isDisabled.value = true;
      message.success("Success!", 1);
    }
  });
};
const onActiveBrand = (item) => {
  modalConfigs.value.open = false;
  let { id } = route.params;
  brandApi.activateBrand({ id }).then((res) => {
    if (res.success) {
      brandDetail.value.status = "active";
      isDisabled.value = false;
      message.success("Success!", 1);
    }else{
      openNotification(res?.error);
    }
  });
};
</script>
<style lang="less" scoped>
@font-family: Sora, sans-serif;
@space: 16px;
@border-color:var (--border-4);
@border-radius: 12px;
@second-font: {
  font-size: 14px;
  color: var(--dark-1);
  letter-spacing: 0.4px;
  line-height: 20px;
};
@third-font:var (--dark-3);
@flex: {
  display: flex;
  justify-content: space-between;
  align-items: center;
};
@card-header: {
  font-family: Sora, sans-serif;
  font-weight: 500;
  color: var(--dark-1);
  font-size: 20px;
  letter-spacing: 0.5px;
  line-height: 28px;
};
.brand-detail {
  font-family: @font-family;
  padding: 16px;
  padding-top: unset;
}
.card-header {
  @flex();
  @card-header();
  background: var(--color-white);
  border-radius: @border-radius @border-radius 0 0;
  padding: 0 16px;
}
.card-header-btn:deep{
  display: flex;
  > button {
    display: flex;
  }
  > span {
    margin-left: 12px;
    width: 32px;
    > button {
      padding: 0 !important;
      width: 32px;
      font-size: 20px;
      > svg {
        width: 20px;
        height: 20px;
        vertical-align: -5px;
      }
    }
    .ant-dropdown-menu-item{
      color: var(--dark-1);
    }
    .ant-btn:not(:disabled):focus-visible{
      outline: unset;
    }
  }
}
.brand-overview {
  border-radius: @border-radius;
  box-shadow: var(--section-box-shadow-6);
  .brand-profile {
    display: flex;
    padding: @space;
    background-color: var(--color-white);
    border-radius: 0 0 @border-radius @border-radius;
    // height: 100%;
    .brand-image {
      margin-right: 12px;
      width: 136px;
      height: 136px;
      background: var(--primary-image);
      border-radius: 8px;
      img {
        border-radius: 8px;
        width: 100%;
        height: 100%;
        // border: 1px solid var(--border-4);
        // object-fit: cover;
      }
      span {
        display: flex;
        width: 136px;
        height: 136px;
        border-radius: 8px;
        // border: 1px solid var(--border-1);
      }
    }
    .brand-content {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: space-between;
      .brand-details {
        padding-left: 4px;
        .bio {
          @card-header();
        }
        .content {
          font-size: 14px;
          color: var(--dark-2);
          letter-spacing: 0.3px;
          line-height: 18px;
        }
      }
      .brand-stuff {
        margin-top: 24px;
        display: flex;
        align-items: center;
        padding-left: 4px;
      }
      .brand-products {
        position: relative;
        padding: 0 5px;
        margin: 0 5px;
        ::before {
          position: absolute;
          content: "";
          display: block;
          top: 4px;
          left: -5px;
          width: 1px;
          height: 12px;
          background-color: var(--border-4);
        }
        ::after {
          position: absolute;
          content: "";
          display: block;
          top: 4px;
          right: -5px;
          width: 1px;
          height: 12px;
          background-color: var(--border-4);
        }
        div:first-of-type {
          color: var(--dark-3);
        }
        div:last-of-type {
          color: var(--dark-1);
          letter-spacing: 0.4px;
          line-height: 20px;
          font-weight: 500;
        }
      }
      .brand-items {
        div:first-of-type {
          color: var(--dark-3);
        }
        div:last-of-type {
          color: var(--dark-1);
          letter-spacing: 0.4px;
          line-height: 20px;
          font-weight: 500;
        }
      }
      .countryIcon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .name {
          margin-left: 3px;
          color: var(--dark-1);
          letter-spacing: 0.4px;
          line-height: 20px;
          font-weight: 500;
        }
      }
      .brand-terms {
        height: 28px;
        margin-top: 16px;
        padding: 0 8px;
        display: flex;
        align-items: center;
        >a:first-of-type{
          margin-left: -12px;
          padding: 4px 8px;
          border-radius: 4px;
          align-items: center;
          font-weight: 500;
          font-size: 14px;
          color: var(--dark-2);
          letter-spacing: 0.4px;
          line-height: 20px;
          text-decoration: underline;
          margin-right: 4px;
          margin-right: 8px;
          &:hover {
          background-color: var(--bg-gray-6);
          color: var(--dark-1);
        }
        &:active {
          background-color: var(--bg-gray-4);
          color: var(--dark-1);
        }
        }
        .icon{
          margin-left: 8px;
          color: var(--dark-2);
          font-size: 18px;
        }
       
      }
    }
  }
}

.table-card {
  margin-top: @space;
  // border: 2px solid @border-color;
  border-radius: @border-radius;
  box-shadow: var(--section-box-shadow-6);
  .table {
    padding: 0 @space;
    background-color: var(--color-white);
    border-radius: 0 0 @border-radius @border-radius;
    table-layout: auto;
  }
  .table-head {
    display: flex;
    height: 60px;
    justify-content: space-between;
    align-items: center;
    .search {
      .search-icon {
        font-size: 20px;
        opacity: 0.5;
      }
    }
  }
  .product-name {
    display: flex;
    align-items: center;
    .product-title {
      flex: 1;
      margin-left: 12px;
      div:first-of-type {
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        color: var(--dark-1);
        letter-spacing: 0.4px;
        line-height: 22px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        overflow: hidden;
        text-decoration: underline;
      }
      div:last-of-type {
        font-weight: 500;
        font-size: 12px;
        color: var(--dark-3);
        letter-spacing: 0.3px;
        line-height: 16px;
      }
      .asin{
        margin-top: 2px;
      }
    }
    .product-img-show {
      width: 56px;
      height: 56px;
      background: var(--bg-gray-2);
      border-radius: 8px;
      img {
        border-radius: 8px;
        width: 56px;
        height: 56px;
        object-fit: contain;
      }
    }
  }

  .table-content:deep(.ant-table) {
    .product-price {
      @second-font();
      font-weight: 400;
      line-height: 19px;
    }
    .product-commission {
      @second-font();
      font-weight: 400;
      line-height: 19px;
    }
    .product-asin {
      @second-font();
    }
  }
}
</style>
