<template>
  <div class="tag">
    <div class="sales item">
      <span class="num">{{props.currency}}{{props.totalParams.totalDiscountedSalesAmount}}</span>
      <span class="name">Sales</span>
      <span class="filter">Filtered</span>
    </div>
    <div class="right-cont">
      <div class="seller item">
        <span class="num">{{props.currency}}{{props.totalParams.totalCommissionAndFeeAmount}}</span>
        <span class="name">Commissions from Seller</span>
        <span class="filter">Filtered</span>
      </div>
      <div class="publishers item">
        <span class="num">{{props.currency}}{{props.totalParams.totalCommissionAmount}}</span>
        <span class="name">Commissions to Publishers</span>
        <span class="filter">Filtered</span>
      </div>
      <div class="fees item">
        <span class="num">{{props.currency}}{{props.totalParams.totalFeeAmount}}</span>
        <span class="name">AA Fees Amount</span>
        <span class="filter">Filtered</span>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { AMAZON_CURRENCY_MAPPER } from '~/contants/common';
  const props = defineProps(['totalParams', 'currency']);
</script>
<style lang="less" scoped>
  .tag {
    display: flex;
    padding-left: 16px;
    .item {
      background: #F8F7FC;
      display: flex;
      flex-direction: column;
      padding: 7px 12px;
      color: var(--dark-1);
      text-align: center;
      .num {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }
      .name {
        font-size: 12px;
        color: var(--dark-2);
        font-weight: 600;
      }
      .filter {
        font-size: 12px;
        color: var(--dark-3);
      }
    }
  }
  .right-cont {
    display: flex;
    .seller {
      border-radius: 8px 0 0 8px;
      width: 200px;
    }
    .publishers {
      margin-left: 6px;

    }
    .fees {
      margin-left: 6px;
      border-radius: 0 8px 8px 0;
      width: 180px;
    }
  }
  
  .sales {
    margin-right: 30px;
    border-radius: 8px;
    width: 150px;
  }
</style>